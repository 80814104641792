import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { CardElement } from '@recurly/react-recurly';
import InputRow, { InputType } from '../../Components/InputRow';

const useStyles = () => ({
  cardElementContainer: {
    '& .recurly-element-card': {
      height: '50px',
      border: 'rgba(0, 0, 0, 0.23) 1px solid',
      borderRadius: '4px',
    },
    '& .recurly-element-card-focus': {
      borderWidth: '2px',
      border: '#C9C9DB 2px solid !important',
    },
    '& .recurly-element-card:hover': {
      borderColor: 'rgba(0, 0, 0, 1)',
    },
  },
  title: {
    marginBottom: '8px',
    color: 'secondary.main',
  },
});

function PaymentInformation(props) {
  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    handleCardDataChange,
    validateForm,
    setFieldValue,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const InputRow1Items = [
    {
      inputtype: InputType.TEXT_FIELD,
      labelText: t('FIELD_BILLING_ADDRESS'),
      id: 'billingAddress',
      inputProps: {
        'data-recurly': 'address1',
        'data-cy': 'txtbillingAddress',
      },
      value: values.billingAddress,
      errorText: errors.billingAddress,
      onBlur: handleBlur,
      onChange: handleChange,
      validateForm,
      setFieldValue,
      fieldName: 'billingAddress',
    },
  ];

  const InputRow2Items = [
    {
      inputtype: InputType.TEXT_FIELD,
      labelText: t('FIELD_CITY'),
      id: 'city',
      inputProps: {
        'data-recurly': 'city',
        'data-cy': 'txtCity',
      },
      value: values.city,
      errorText: errors.city,
      onBlur: handleBlur,
      onChange: handleChange,
      validateForm,
      setFieldValue,
      fieldName: 'city',
    },
    {
      inputtype: InputType.TEXT_FIELD,
      labelText: t('FIELD_STATE'),
      id: 'state',
      inputProps: {
        'data-recurly': 'state',
        'data-cy': 'txtState',
      },
      value: values.state,
      errorText: errors.state,
      onBlur: handleBlur,
      onChange: handleChange,
      validateForm,
      setFieldValue,
      fieldName: 'state',
    },
    {
      inputtype: InputType.TEXT_FIELD,
      labelText: t('FIELD_ZIP_CODE'),
      id: 'zipCode',
      inputProps: {
        'data-recurly': 'postal_code',
        'data-cy': 'txtZipCode',
      },
      value: values.zipCode,
      errorText: errors.zipCode,
      onBlur: handleBlur,
      onChange: handleChange,
      validateForm,
      setFieldValue,
      fieldName: 'zipCode',
    },
  ];

  return (
    <>
      <Typography sx={classes.title} variant="subtitle1">
        {t('SECTION_TITLE_PAYMENT_INFORMATION')}
      </Typography>
      <InputRow rowInputs={InputRow1Items} />
      <InputRow rowInputs={InputRow2Items} />
      <div style={classes.cardElementContainer}>
        <CardElement
          onSubmit={handleSubmit}
          onChange={handleCardDataChange}
        />
      </div>
    </>
  );
}

export default PaymentInformation;
