import React, { useState, useEffect } from 'react';
import { Button, Avatar } from '@advisorycloud/react-ui-components';
import {
  Autocomplete,
  useMediaQuery,
  Paper,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox as MaterialCheckbox,
  Select,
  MenuItem,
  FormControl,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import Input from '../Input';
import { countries } from '../../services/utils/countryCode';
import { NullCountry, ResponseStatus } from '../../services/utils/types';
import { useAccount } from '../../contexts/Account';
import {
  updateCompanyContactEmailCall,
  updateCompanyProfileData,
} from '../../services/company_services';
import {
  updateAdvisorPlanCall,
  updateAdvisorProfileData,
  updateAdvisorContactEmailCall,
} from '../../services/advisor_services';

const useStyles = () => ({
  card: {
    backgroundColor: 'white',
    padding: '25px',
    position: 'relative',
  },

  companyWebsiteContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '175px',
    height: 'fit-content',
  },
  avatarContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 'fit-content',
    height: 'fit-content',
    marginRight: '20px',
  },
  continueButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: '15px',
  },
  dividerMargin: {
    marginTop: '10px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
  },
  autocomplete: {
    width: '100%',
    height: '25px',
  },
  errorSeparatorCode: {
    marginTop: '-50px',
  },
  errorSeparatorPhone: {
    marginTop: '-50px',
  },
  companyName: {
    marginTop: '-5px',
    color: 'secondary.dark',
  },
  subTitleStyle: {
    marginTop: '10px',
    color: 'secondary.dark',
  },
  closeIcon: {
    fontSize: '19px',
    color: 'secondary.dark',
  },
  closeButton: {
    backgroundColor: 'transparent',
    outline: 'none',
    width: '23px',
    height: '23px',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    top: '0px',
    right: '0px',
    cursor: 'pointer',
    '&:disabled': {
      cursor: 'default',
    },
    borderRadius: '50%',
    border: 'none',
  },
  root: {
    height: '30px',
    paddingTop: '0px',
  },
  inputRoot: {
    marginTop: '-5px',
    textTransform: 'capitalize',
  },
  inputRootCode: {
    marginTop: '-3px',
    textTransform: 'uppercase',
    fontSize: '0.75rem',
  },
});

const EditUsersModal = (props) => {
  const {
    user,
    avatarImage,
    closeModal,
    userId,
    showSnackbar,
    advisorFormat,
    planOptionsArray,
    freeTrialEndsOn,
  } = props;
  const { currentUserInfo } = useAccount();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const [userForm, setUserForm] = useState({
    ...user,
    freeB2BAccountStatus: user.freeB2BAccountStatus || '',
    freeTrialEndsOn: freeTrialEndsOn ? freeTrialEndsOn.split('T')[0] : null,
    phoneNumber:
      user.phoneNumber === null ? null : user.phoneNumber.phoneNumber,
    phoneCountryCode:
      user.phoneNumber === null ? null : user.phoneNumber.countryCode,
  });
  const [planOptions, setPlanOptions] = useState();
  const [loadingPlans, setLoadingPlans] = useState(true);
  const [errFetchPlanOptions, setErrFetchPlanOptions] = useState(false);
  const [isUnexistingPlan, setIsUnexistingPlan] = useState(false);

  function getPlans() {
    if (planOptionsArray && planOptionsArray.length > 0) {
      const plansArray = planOptionsArray;
      const plansArrayMap = plansArray.map((element) => {
        return {
          code: element.code,
          name: element.name,
        };
      });
      setPlanOptions(plansArrayMap);
      setLoadingPlans(false);
    } else {
      setLoadingPlans(false);
      setErrFetchPlanOptions(true);
    }
  }

  useEffect(() => {
    if (advisorFormat) {
      getPlans();
    }
  }, [advisorFormat, planOptionsArray]);

  async function updateAdvisorPlanCode() {
    await updateAdvisorPlanCall({
      ADVISOR_ID: user.id,
      PLAN_CODE: userForm.recurlyPlanCode,
    });
  }

  async function submitChangesAdvisor() {
    setIsLoading(true);

    try {
      if (user.recurlyPlanCode !== userForm.recurlyPlanCode) {
        await updateAdvisorPlanCode();
      }

      if (user.email !== userForm.email) {
        await updateAdvisorContactEmailCall({
          ACCESS_TOKEN: currentUserInfo.accessToken.jwtToken,
          ADVISOR_ID: user.id,
          NEW_EMAIL: userForm.email,
        });
      }

      const payload = {
        id: userForm.id,
        givenName: userForm.givenName,
        surName: userForm.surName,
        phoneNumber: userForm.phoneNumber,
        phoneCountryCode: userForm.phoneCountryCode,
        publicProfileUrl: userForm.publicProfileUrl,
        featuredAdvisor: userForm.featuredAdvisor,
        privateProfile: userForm.privateProfile,
        freeB2BAccountStatus: userForm.freeB2BAccountStatus || null,
      };

      if (userForm.freeTrialEndsOn) {
        payload.freeTrialEndsOn = new Date(userForm.freeTrialEndsOn);
      } else {
        payload.removeFreeTrial = true;
      }

      const res = await updateAdvisorProfileData(payload);
      console.log(res);
      setIsLoading(false);
      showSnackbar(ResponseStatus.SUCCESS);
      closeModal();
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      if (err.errors && err.errors.length) {
        showSnackbar(ResponseStatus.FAIL, err.errors[0].message);
      } else {
        showSnackbar(ResponseStatus.FAIL);
      }
    }
  }

  function submitChangesCompany() {
    setIsLoading(true);

    if (user.email !== userForm.email) {
      updateCompanyContactEmailCall({
        ACCESS_TOKEN: currentUserInfo.accessToken.jwtToken,
        COMPANY_CONTACT_ID: userId,
        NEW_EMAIL: userForm.email,
      });
    }
    updateCompanyProfileData({
      id: userForm.id,
      givenName: userForm.givenName,
      surName: userForm.surName,
      phoneNumber: userForm.phoneNumber,
      phoneCountryCode: userForm.phoneCountryCode,
    })
      .then((res) => {
        console.log(res);
        setIsLoading(false);
        showSnackbar(ResponseStatus.SUCCESS);
        closeModal();
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        if (err.errors && err.errors.length) {
          showSnackbar(ResponseStatus.FAIL, err.errors[0].message);
        } else {
          showSnackbar(ResponseStatus.FAIL);
        }
      });
  }

  function submitChanges() {
    if (advisorFormat) {
      submitChangesAdvisor();
    } else {
      submitChangesCompany();
    }
  }

  function countryToFlag(isoCode) {
    if (isoCode === null || isoCode === undefined) {
      return null;
    }
    return typeof String.fromCodePoint !== 'undefined'
      ? isoCode
          .toUpperCase()
          .replace(/./g, (char) =>
            String.fromCodePoint(char.charCodeAt(0) + 127397)
          )
      : isoCode;
  }

  function instantJoinEnabledCheckbox(values, setFieldValue) {
    return (
      <MaterialCheckbox
        sx={classes.formField}
        id="outlined-multiline-flexible"
        label="Instant Join Enabled"
        onChange={(event) => {
          setFieldValue('instantJoinEnabled', event.target.checked);
        }}
        checked={values.instantJoinEnabled}
      />
    );
  }

  function featuredAdvisorCheckbox(values, setFieldValue) {
    return (
      <MaterialCheckbox
        sx={classes.formField}
        id="outlined-multiline-flexible"
        label="Featured Advisor"
        onChange={(event) => {
          setFieldValue('featuredAdvisor', event.target.checked);
        }}
        checked={values.featuredAdvisor}
      />
    );
  }

  function privateAdvisorCheckbox(values, setFieldValue) {
    return (
      <MaterialCheckbox
        sx={classes.formField}
        id="outlined-multiline-flexible"
        label="Private"
        onChange={(event) => {
          setFieldValue('privateProfile', event.target.checked);
        }}
        checked={values.privateProfile}
      />
    );
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserForm({
      ...userForm,
      [name]: value,
    });
  };

  const setFieldValue = (name, value) => {
    setUserForm({
      ...userForm,
      [name]: value,
    });
  };

  return (
    <Paper elevation={3} sx={classes.card}>
      <button
        type="button"
        onClick={() => closeModal()}
        style={classes.closeButton}
      >
        <CloseIcon style={classes.closeIcon} />
      </button>
      <div style={classes.row}>
        <div style={classes.avatarContainer}>
          <Avatar image={avatarImage} size="adminUserCard" />
        </div>
        <div style={classes.companyWebsiteContainer}>
          <div>
            <Typography variant="caption" sx={classes.subTitleStyle}>
              {t('EDITMODAL-GIVENNAME')}
            </Typography>
            <Input
              height={27}
              fontSize="0.8rem"
              style={classes.fieldContainer}
              name="givenName"
              onChange={handleChange}
              size="small"
              value={userForm.givenName}
              error={!userForm.givenName}
              required
            />
            {!userForm.givenName && (
              <Typography
                variant="caption"
                color="error"
                sx={classes.errorSeparatorPhone}
              >
                {t('EDITMODAL-NUM-REQUIRED')}
              </Typography>
            )}
          </div>
          <div style={classes.dividerMargin}>
            <Typography variant="caption" sx={classes.subTitleStyle}>
              {t('EDITMODAL-SURNAME')}
            </Typography>
            <Input
              height={27}
              fontSize="0.8rem"
              style={classes.fieldContainer}
              name="surName"
              onChange={handleChange}
              size="small"
              value={userForm.surName}
              error={!userForm.surName}
              required
            />
            {!userForm.surName && (
              <Typography
                variant="caption"
                color="error"
                sx={classes.errorSeparatorPhone}
              >
                {t('EDITMODAL-NUM-REQUIRED')}
              </Typography>
            )}
          </div>
          {!advisorFormat && (
            <div style={classes.dividerMargin}>
              <Typography variant="caption" sx={classes.companyName}>
                {userForm.companyName}
              </Typography>
            </div>
          )}
        </div>
      </div>

      <div style={classes.dividerMargin}>
        <Typography variant="caption" sx={classes.subTitleStyle}>
          {t('EDITMODAL-EMAIL')}
        </Typography>
        <Input
          height={27}
          fontSize="0.8rem"
          style={classes.fieldContainer}
          name="email"
          onChange={handleChange}
          size="small"
          value={userForm.email}
          error={!userForm.email}
          required
        />
        {!userForm.email && (
          <Typography
            variant="caption"
            color="error"
            sx={classes.errorSeparatorPhone}
          >
            {t('EDITMODAL-NUM-REQUIRED')}
          </Typography>
        )}
      </div>
      {advisorFormat && (
        <div style={classes.dividerMargin}>
          <Typography variant="caption" sx={classes.subTitleStyle}>
            Public Profile
          </Typography>
          <Input
            height={27}
            fontSize="0.8rem"
            style={classes.fieldContainer}
            name="publicProfileUrl"
            onChange={handleChange}
            size="small"
            value={userForm.publicProfileUrl}
            error={!userForm.publicProfileUrl}
            required
          />
          {!userForm.publicProfileUrl && (
            <Typography
              variant="caption"
              color="error"
              sx={classes.errorSeparatorPhone}
            >
              {t('EDITMODAL-NUM-REQUIRED')}
            </Typography>
          )}
        </div>
      )}
      {advisorFormat && (
        <div>
          <Typography variant="caption" sx={classes.subTitleStyle}>
            {t('EDITMODAL-FREETRIAL')}
          </Typography>
          <Input
            height={27}
            fontSize="0.8rem"
            style={classes.fieldContainer}
            name="freeTrialEndsOn"
            onChange={handleChange}
            size="small"
            type="date"
            value={userForm.freeTrialEndsOn}
          />
        </div>
      )}

      {advisorFormat && (
        <FormControl sx={{ width: '100%', marginBottom: '0px' }}>
          <Typography variant="caption" sx={classes.subTitleStyle}>
            Free B2B Account Status
          </Typography>
          <Select
            name="freeB2BAccountStatus"
            onChange={handleChange}
            value={userForm.freeB2BAccountStatus}
            displayEmpty
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              zIndex: 120,
              height: '25px',
            }}
            size="small"
          >
            <MenuItem value="Inactive">Inactive</MenuItem>
            <MenuItem value="Active">Active</MenuItem>
            <MenuItem value="Paused">Paused</MenuItem>
            <MenuItem value="Cancelled">Cancelled</MenuItem>
            <MenuItem value="Shared">Shared</MenuItem>
            <MenuItem value="">None</MenuItem>
          </Select>
        </FormControl>
      )}

      <div style={classes.dividerMargin}>
        <Typography variant="caption" sx={classes.subTitleStyle}>
          {t('EDITMODAL-PHONE-NUMBER')}
        </Typography>
        <Input
          height={27}
          fontSize="0.8rem"
          style={classes.fieldContainer}
          name="phoneNumber"
          onChange={handleChange}
          size="small"
          value={userForm.phoneNumber}
        />
        {!userForm.phoneNumber && (
          <Typography
            variant="caption"
            color="error"
            sx={classes.errorSeparatorPhone}
          >
            {t('EDITMODAL-NUM-REQUIRED')}
          </Typography>
        )}
      </div>
      <div style={classes.dividerMargin}>
        <Autocomplete
          id="phoneCountryCode"
          onChange={(e, value) => {
            if (value === null) {
              setFieldValue('phoneCountryCode', NullCountry.NULL_COUNTRY);
            } else {
              setFieldValue('phoneCountryCode', value.code);
            }
          }}
          sx={classes.autocomplete}
          options={countries}
          classes={{
            option: classes.option,
            inputRoot: classes.root,
            input: classes.inputRoot,
          }}
          autoHighlight
          getOptionLabel={(option) => {
            if (option.code === NullCountry.NULL_COUNTRY) {
              return `${option.label}`;
            }
            return `${countryToFlag(option.code)} ${option.label}`;
            // eslint-disable-next-line react/jsx-curly-newline
          }}
          renderOption={(props, option) => (
            <li {...props}>
              <span>{countryToFlag(option.code)}</span>
              <span>{`${option.label}-(${option.code})`}</span>
            </li>
          )}
          defaultValue={countries.find(
            (country) => country.code === userForm.phoneCountryCode
          )}
          value={
            countries.find(
              (element) => element.code === userForm.phoneCountryCode
            ) || {}
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              size="small"
              inputProps={{
                ...params.inputProps,
              }}
            />
          )}
        />

        {userForm.phoneCountryCode === NullCountry.NULL_COUNTRY && (
          <Typography
            variant="caption"
            color="error"
            sx={classes.errorSeparatorCode}
          >
            {t('EDITMODAL-NUM-REQUIRED')}
          </Typography>
        )}
      </div>

      {advisorFormat && (
        <div style={classes.dividerMargin}>
          <Typography variant="caption" sx={classes.companyName}>
            Plancode
          </Typography>
          {loadingPlans && !errFetchPlanOptions && (
            <Button
              disabled
              style={{
                height: '25px',
                width: '100%',
                backgroundColor: 'transparent',
              }}
              loading={loadingPlans ? 1 : 0}
            />
          )}

          {!loadingPlans && errFetchPlanOptions && (
            <div style={classes.dividerMargin}>
              <div style={classes.planCodeErrorContainer}>
                <div>
                  <Typography
                    variant="caption"
                    color="error"
                    sx={classes.errorSeparatorCodePlans}
                  >
                    {t('EDITMODAL-COMPANY-ERROR-PLANS')}
                  </Typography>
                </div>
              </div>
            </div>
          )}
          {!loadingPlans && !errFetchPlanOptions && (
            <Autocomplete
              id="recurlyPlanCode"
              sx={classes.autocomplete}
              options={planOptions}
              classes={{
                option: classes.option,
                inputRoot: classes.root,
                input: classes.inputRootCode,
              }}
              autoHighlight
              getOptionLabel={(option) => {
                return option.name;
              }}
              renderOption={(props, option) => {
                return (
                  <span {...props} style={{ textTransform: 'uppercase' }}>
                    {option.name}
                  </span>
                );
              }}
              onChange={(e, value) => {
                if (value === null || value === undefined) {
                  setFieldValue('recurlyPlanCode', userForm.recurlyPlanCode);
                } else {
                  setFieldValue('recurlyPlanCode', value.code);
                }
              }}
              defaultValue={() => {
                if (!planOptions) {
                  return null;
                }
                const results = planOptions.find(
                  (element) => element.code === userForm.recurlyPlanCode
                );
                if (results === undefined) {
                  return setIsUnexistingPlan(true);
                }

                return results;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder={isUnexistingPlan ? 'Error. Select a plan' : null}
                  size="small"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />
          )}
        </div>
      )}

      <FormControlLabel
        control={instantJoinEnabledCheckbox(userForm, setFieldValue)}
        label="Instant Join Enabled"
      />

      <FormControlLabel
        control={featuredAdvisorCheckbox(userForm, setFieldValue)}
        label="Featured Advisor"
      />

      <FormControlLabel
        control={privateAdvisorCheckbox(userForm, setFieldValue)}
        label="Private"
      />

      <div style={classes.continueButtonContainer}>
        <Button
          color="primary"
          disabled={userForm.phoneCountryCode === NullCountry.NULL_COUNTRY}
          loading={isLoading ? 1 : 0}
          style={{ width: 80, fontSize: '.7rem' }}
          size="small"
          onClick={() => {
            submitChanges();
          }}
        >
          {t('EDITMODAL-SAVE')}
        </Button>
      </div>
    </Paper>
  );
};

export default EditUsersModal;
