import { Auth } from 'aws-amplify';

export const LoginResponses = {
  INCORRECT_USERNAME_PASSWORD_CODE: 'Incorrect username or password.',
  USER_NOT_FOUND_CODE: 'User does not exist.',
  PASSWORD_ATTEMPTS_EXCEEDED: 'Password attempts exceeded',
  RESOURCE_NOT_FOUND_CODE: 'ResourceNotFoundException',
  FIRST_TIME_LOGIN_MESSAGE:
    'PreAuthentication failed with error FIRST TIME LOGIN.',
  PASSWORD_RESET_REQUIRED_EXCEPTION: 'Password reset required for the user',
  NOT_AUTHORIZED_EXCEPTION: 'User is disabled.',
};

export async function signIn(username, password) {
  try {
    const authData = await Auth.signIn(username, password);

    return authData;
  } catch (error) {
    return error;
  }
}

export const LoginResponseTypes = {
  USER_MIGRATED: 'USER_MIGRATED',
  ERROR_IN_COGNITO_RESPONSE: 'ERROR_IN_COGNITO_RESPONSE',
  COGNITO_UNKNOW_ERROR: 'COGNITO_UNKNOW_ERROR',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  UKNOWN_ERROR: 'UKNOWN_ERROR',
  UKNOWN_ERROR_SUCCESS: 'UKNOWN_ERROR_SUCCESS',
  AUTHENTICATION_ERROR: 'AUTHENTICATION_ERROR',
  NO_ADMIN: 'USER_NOT_ADMIN',
};

/**
 * This function handle login, login is different to sign in.
 * Sign in only handle congnito sign in. login handle congnito sign
 * and retrieve the data necessary to make a login
 * @param {string} username -
 * @param {string} password -
 * @param {Function} authenticate - authenticate context
 * @returns { {response:Type, message:string } }
 */

const handledErrors = [
  LoginResponses.PASSWORD_ATTEMPTS_EXCEEDED,
  LoginResponses.INCORRECT_USERNAME_PASSWORD_CODE,
  LoginResponses.USER_NOT_FOUND_CODE,
  LoginResponses.NOT_AUTHORIZED_EXCEPTION,
  LoginResponses.PASSWORD_RESET_REQUIRED_EXCEPTION,
];

export async function logIn(username, password, authenticate) {
  let signInError = {};
  try {
    let isAuthenticated = true;
    const congnitoSignInData = await signIn(username, password).then(
      (response) => {
        if (response.code) {
          signInError = response;
        }
        return response;
      }
    );

    const accountType = JSON.parse(
      congnitoSignInData.signInUserSession.idToken.payload.accountType
    )[0];

    if (accountType !== 'admin') {
      return { response: LoginResponseTypes.NO_ADMIN, message: '' };
    }

    if (congnitoSignInData.signInUserSession) {
      await authenticate(() => {
        isAuthenticated = false;
      });
      if (isAuthenticated) {
        return { response: LoginResponseTypes.LOGIN_SUCCESS, message: '' };
      }
    }
    return { response: LoginResponseTypes.UKNOWN_ERROR_SUCCESS, message: '' };
  } catch (error) {
    if (signInError) {
      if (handledErrors.includes(signInError.message)) {
        return {
          response: LoginResponseTypes.ERROR_IN_COGNITO_RESPONSE,
          message: signInError.message,
        };
      }
      return {
        response: LoginResponseTypes.COGNITO_UNKNOW_ERROR,
        message: '',
      };
    }
    return { response: LoginResponseTypes.UKNOWN_ERROR, message: '' };
  }
}

export async function signOut() {
  return Auth.signOut({ global: true });
}

export const ForgotPasswordErrorResponses = {
  INVALID_PARAMETER: 'InvalidParameterException',
  EXPIRED_CODE: 'ExpiredCodeException',
  LIMIT_EXCEEDED: 'LimitExceededException',
  TOO_MANY_REQUESTS: 'TooManyRequestsException',
  NOT_AUTHORIZED_EXCEPTION: 'NotAuthorizedException',
  USER_NOT_FOUND: 'UserNotFoundException',
};

export const ForgotPasswordResponses = {
  SUCCESS: 'SUCCESS',
  CODE_MISMATCH_EXCEPTION: 'CodeMismatchException',
  EXPIRED_CODE_EXCEPTION: 'ExpiredCodeException',
};

export async function forgotPassword(email) {
  try {
    await Auth.forgotPassword(email, {
      workflow: 'admin-password-reset',
    });

    return ForgotPasswordResponses.SUCCESS;
  } catch (error) {
    console.log(error);
    return error.code;
  }
}

export async function resetUserPassword(email) {
  try {
    await Auth.forgotPassword(email);

    return ForgotPasswordResponses.SUCCESS;
  } catch (error) {
    console.log(error);
    return error.code;
  }
}

export async function forgotPasswordSubmit(email, code, newPassword) {
  try {
    await Auth.forgotPasswordSubmit(email, code, newPassword);

    return ForgotPasswordResponses.SUCCESS;
  } catch (error) {
    return error.code;
  }
}

export async function getCurrentUserInfo() {
  let error;
  let userInfo;
  let currentSession;

  try {
    currentSession = await Auth.currentSession();
    userInfo = await Auth.currentUserInfo();
    const accountType = JSON.parse(
      currentSession.idToken.payload.accountType
    )[0];

    currentSession.accountType = accountType;
  } catch (e) {
    error = e;
  }

  return error ? null : { ...currentSession, ...userInfo };
}

export function acceptCompanyTermsOfService() {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      try {
        resolve();
      } catch (error) {
        reject(error);
      }
    }, 1000);
  });
}

export function acceptAdvisorTermsOfService() {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      try {
        resolve();
      } catch (error) {
        reject(error);
      }
    }, 1000);
  });
}

export const FilePondInitialResponse = {
  HAS_FILE: 'HAS_FILE',
  NO_FILE: 'NO_FILE',
};

export function getRecurlyUrl(planCode) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (planCode === '12345') {
        resolve('https://www.google.com');
      } else {
        reject();
      }
    }, 1000);
  });
}

export const hasUrlParams = {
  PARAMS: 'WITH_PARAMS',
  NO_PARAMS: 'NO_PARAMS',
};
