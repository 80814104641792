import React, { useEffect } from 'react';
import { Typography, Select, MenuItem, FormHelperText } from '@mui/material';

const useStyles = () => ({
  label: {
    marginBottom: '8px',
  },
  dropdownRoot: {
    height: '20px',
    paddingTop: '15px',
    paddingBottom: '15px',
  },
  dropdown: {
    width: '100%',
  },
});

function Dropdown(props) {
  const {
    labelText,
    id,
    errorText,
    options,
    validateForm,
    inputProps,
    setFieldValue,
    fieldName,
    ...rest
  } = props;
  const classes = useStyles();

  useEffect(() => {
    if (validateForm) {
      validateForm();
    }

    return () => {
      if (setFieldValue) {
        setFieldValue(fieldName, '');
      }
    };
  }, []);

  return (
    <>
      <Typography sx={classes.label} variant="body1">
        {labelText}
      </Typography>
      <div>
        <Select
          // classes={{ root: classes.dropdownRoot }}
          style={classes.dropdownRoot}
          sx={classes.dropdown}
          variant="outlined"
          error={Boolean(errorText)}
          inputProps={{ ...inputProps, ...{ id } }}
          onChange={(event) => {
            setFieldValue(fieldName, event.target.value);
          }}
          {...rest}
        >
          <MenuItem value="">
            <em>-</em>
          </MenuItem>
          {options.map((option) => {
            return (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            );
          })}
        </Select>
      </div>
      {errorText ? <FormHelperText error>{errorText}</FormHelperText> : null}
    </>
  );
}

export default Dropdown;
