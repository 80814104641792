import React from 'react';

function MasterCheckout() {
  return (
    <div style={{ width: '100%' }}>
      <h1>Master Checkout</h1>
    </div>
  );
}

export default MasterCheckout;
