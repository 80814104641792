import { API, graphqlOperation } from 'aws-amplify';

const updateCompanyContactMutation = `
  mutation updateCompanyContact(
    $id: String!,
		$givenName: String!,
		$surName: String!,
    $phoneNumber: String!,
    $phoneCountryCode: String!
  ) {
    updateCompanyContact(
      id: $id,
      givenName: $givenName,
      surName: $surName,
      phoneNumber: $phoneNumber,
      phoneCountryCode: $phoneCountryCode,
    ) {
      id
    }
  }
`;

const updateCompanyContactEmail = `
  mutation($accessToken: String!, $companyContactId: String!, $email: String!) {
    updateCompanyContactEmail(
      accessToken: $accessToken,
      companyContactId: $companyContactId,
      email: $email,
    )
  }
`;

const updateCompanyName = `
  mutation ($contactId: String!, $companyName: String!) {
    updateCompany (
      contactId: $contactId,
      companyName: $companyName
    ){
      companyName
    }
  }
`;
const updateBoardType = `
  mutation ($contactId: String!, $boardType: String!) {
    updateCompany (
      contactId: $contactId,
      boardType: $boardType
    ){
      companyName
    }
  }
`;

const updateCompanyBoardSeats = `
  mutation ($companyId: String!, $boardSeatsAllowedCount: Int!) {
    updateCompanyBoardSeatsAllowedCount (
      companyId: $companyId,
      boardSeatsAllowedCount: $boardSeatsAllowedCount
    ){
      id
    }
  }
`;

const updateCompanyBoardType = `
  mutation ($id: String!, $isPrivate: Boolean!) {
    updateCompanyBoardType (
      id: $id,
      isPrivate: $isPrivate
    ){
      isPrivate
    }
  }
`;

const updateCompanyIsPublic = `
  mutation ($id: String!, $isPublic: Boolean!) {
    updateCompanyIsPublic (
      id: $id,
      isPublic: $isPublic
    ){
      isPublic
    }
  }
`;

const updateCompanyComplimentaryBoardPosition = `
  mutation ($companyId: String!, $complimentaryBoardPosition: Boolean!) {
    updateComplimentaryBoardPosition (
      companyId: $companyId,
      complimentaryBoardPosition: $complimentaryBoardPosition
    ){
      complimentaryBoardPosition
    }
  }
`;

const updateCompanyInstantJoinEnabled = `
  mutation ($companyId: String!, $instantJoinEnabled: Boolean!) {
    updateCompanyInstantJoinEnabled (
      companyId: $companyId,
      instantJoinEnabled: $instantJoinEnabled
    ){
      instantJoinEnabled
    }
  }
`;

const updateCompanyStage = `
  mutation ($companyId: String!, $opportunityStage: String!) {
    updateCompanyStage (
      companyId: $companyId,
      opportunityStage: $opportunityStage
    ){
      opportunityStage
    }
  }
`;

const updateCompanyPlan = `
  mutation ($companyId: String!, $recurlyPlanCode: String!) {
    updateCompanyPlanCode (
      companyId: $companyId,
      recurlyPlanCode: $recurlyPlanCode
    ){
      recurlyPlanCode
    }
  }
`;

// temporal
const updateCompanyOnboardingStatus = `
  mutation (
    $companyId: String!,
    $onboardingStatus: String!,
    $deleteOnboardingDate: Boolean!
  ) {
    updateCompanyOnboardingStatus(
      companyId: $companyId,
      onboardingStatus: $onboardingStatus,
      deleteOnboardingDate: $deleteOnboardingDate
    ) {
      id
    }
  }
`;

const updateCompanyUpcomingMeeting = `
  mutation ($contactId: String!, $upcomingBoardMeeting: AWSDateTime!) {
    updateCompany (
      contactId: $contactId,
      upcomingBoardMeeting: $upcomingBoardMeeting
    ){
      upcomingBoardMeeting
    }
  }
`;

const removeCompanyUpcomingMeeting = `
  mutation ($contactId: String!) {
    updateCompany (
      contactId: $contactId,
      removeUpcomingBoardMeeting: true
    ){
      upcomingBoardMeeting
    }
  }
`;

const updateUserSegmentMutation = `
  mutation ($companyId: String!, $userSegment: String!) {
    updateUserSegment(companyId: $companyId, userSegment: $userSegment)
  }
`;

// Company Contact form and image.

export function updateCompanyProfileData(profile) {
  return API.graphql(graphqlOperation(updateCompanyContactMutation, profile));
}

// updateEmail

export function updateCompanyContactEmailCall({
  ACCESS_TOKEN,
  COMPANY_CONTACT_ID,
  NEW_EMAIL,
}) {
  return API.graphql(
    graphqlOperation(updateCompanyContactEmail, {
      accessToken: ACCESS_TOKEN,
      companyContactId: COMPANY_CONTACT_ID,
      email: NEW_EMAIL,
    })
  );
}

export function updateCompanyNameCall({ CONTACT_ID, NEW_COMPANY_NAME }) {
  return API.graphql(
    graphqlOperation(updateCompanyName, {
      contactId: CONTACT_ID,
      companyName: NEW_COMPANY_NAME,
    })
  );
}

export function updateCompanyBoardSeatsCall({ COMPANY_ID, BOARD_SEATS }) {
  return API.graphql(
    graphqlOperation(updateCompanyBoardSeats, {
      companyId: COMPANY_ID,
      boardSeatsAllowedCount: BOARD_SEATS,
    })
  );
}

export function updateCompanyBoardTypeCall({ COMPANY_ID, BOARD_TYPE }) {
  return API.graphql(
    graphqlOperation(updateCompanyBoardType, {
      id: COMPANY_ID,
      isPrivate: BOARD_TYPE,
    })
  );
}
export function updateBoardTypeCall({ CONTACT_ID, BOARD_TYPE }) {
  return API.graphql(
    graphqlOperation(updateBoardType, {
      contactId: CONTACT_ID,
      boardType: BOARD_TYPE,
    })
  );
}

export function updateCompanyIsPublicCall({ COMPANY_ID, BOARD_TYPE }) {
  return API.graphql(
    graphqlOperation(updateCompanyIsPublic, {
      id: COMPANY_ID,
      isPublic: BOARD_TYPE,
    })
  );
}

export function updateCompanyPlanCall({ COMPANY_ID, PLAN_CODE }) {
  return API.graphql(
    graphqlOperation(updateCompanyPlan, {
      companyId: COMPANY_ID,
      recurlyPlanCode: PLAN_CODE,
    })
  );
}

// temporal
export function updateCompanyOnboardingStatusCall({
  COMPANY_ID,
  ONBOARDING_STATUS,
}) {
  return API.graphql(
    graphqlOperation(updateCompanyOnboardingStatus, {
      companyId: COMPANY_ID,
      onboardingStatus: ONBOARDING_STATUS,
      deleteOnboardingDate: false,
    })
  );
}

export function updateCompanyUpcomingMeetingCall({ CONTACT_ID, DATE }) {
  return API.graphql(
    graphqlOperation(updateCompanyUpcomingMeeting, {
      contactId: CONTACT_ID,
      upcomingBoardMeeting: DATE,
    })
  );
}

export function updateCompanyInstantJoinEnabledCall({
  COMPANY_ID,
  INSTANT_JOIN_ENABLED,
}) {
  return API.graphql(
    graphqlOperation(updateCompanyInstantJoinEnabled, {
      companyId: COMPANY_ID,
      instantJoinEnabled: INSTANT_JOIN_ENABLED,
    })
  );
}

export function updateCompanyStageCall({ COMPANY_ID, OPPORTUNITY_STAGE }) {
  return API.graphql(
    graphqlOperation(updateCompanyStage, {
      companyId: COMPANY_ID,
      opportunityStage: OPPORTUNITY_STAGE,
    })
  );
}

export function updateCompanyComplimentaryBoardPositionCall({
  COMPANY_ID,
  COMPLIMENTARY_BOARD_POSITION,
}) {
  return API.graphql(
    graphqlOperation(updateCompanyComplimentaryBoardPosition, {
      companyId: COMPANY_ID,
      complimentaryBoardPosition: COMPLIMENTARY_BOARD_POSITION,
    })
  );
}

export function removeCompanyUpcomingMeetingCall({ CONTACT_ID }) {
  return API.graphql(
    graphqlOperation(removeCompanyUpcomingMeeting, {
      contactId: CONTACT_ID,
    })
  );
}

export function updateUserSegment({ COMPANY_ID, USER_SEGMENT }) {
  return API.graphql(
    graphqlOperation(updateUserSegmentMutation, {
      companyId: COMPANY_ID,
      userSegment: USER_SEGMENT,
    })
  );
}
