import React, { useState } from 'react';
import {
  AdvisoryCloudLogo,
  LoadingIndicatorLogo,
  Snackbar,
} from '@advisorycloud/react-ui-components';
import { Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import OopsWindow from '../Oops';
import PasswordForm from '../../components/PasswordSet/PasswordForm';
import UnhandledError from '../../components/PasswordSet/UnhandledError';
import ExpiredLink from '../../components/PasswordSet/ExpiredError';
import LoginError from '../../components/PasswordSet/LoginError';
import {
  forgotPassword,
  forgotPasswordSubmit,
  ForgotPasswordErrorResponses,
  ForgotPasswordResponses,
  hasUrlParams,
  logIn,
  LoginResponseTypes,
} from '../../services/Authentication';
import { useAccount } from '../../contexts/Account';

const useStyles = (isSM) => ({
  container: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '40px',
    width: '100%',
    height: isSM ? '100vh' : 'auto',
    backgroundColor: isSM ? 'white' : 'grey',
  },
  indicatorText: {
    marginTop: '30px',
    color: 'grey',
    marginBottom: '40px',
  },
  loadingContainer: {
    alignItems: 'center',
    backgroundColor: 'transparent',
    bottom: '0px',
    display: 'flex',
    justifyContent: 'center',
    left: '0px',
    position: 'fixed',
    right: '0px',
    top: '6%',
  },
});

const SCENE_TITLES_KEYS = [
  'CREATE-PASSWORD-INDICATOR-TEXT',
  'LOGGING-ACCOUNT-INDICATOR-TEXT',
  'ERROR-INDICATOR-TEXT',
];

const SCENE_STATE_TYPES = {
  CREATE_PASSWORD: 'CREATE_PASSWORD',
  EXPIRED_LINK: 'EXPIRED_LINK',
  UNHANDLED_ERROR: 'UNHANDLED_ERROR',
  LOGIN_ERROR: 'LOGIN_ERROR',
  SIGN_IN_LOADING: 'SIGN_IN_LOADING',
};

function PasswordSet({ location, history }) {
  const { authenticate } = useAccount();
  const [sceneId, setSceneId] = useState(SCENE_STATE_TYPES.CREATE_PASSWORD);
  const [password, setPassword] = useState('');
  const [isErrorSnackbarVisible, setIsErrorSnackbarVisible] = useState(false);
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
  const [indicatorTranslationKey, setIndicatorTranslationKey] = useState(
    SCENE_TITLES_KEYS[0]
  );
  const [isPasswordFormLoading, setIsPasswordFormLoading] = useState(false);
  const [isAbleToRetry, setIsAbleToRetry] = useState(false);
  const { t } = useTranslation();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const queryParams = new URLSearchParams(location.search);
  const [email, setEmail] = useState();
  const [code, setCode] = useState();

  function convertSceneStateTypeToSceneTitleKeys(sceneIdParam) {
    switch (sceneIdParam) {
      case SCENE_STATE_TYPES.CREATE_PASSWORD:
        return SCENE_TITLES_KEYS[0];
      case SCENE_STATE_TYPES.EXPIRED_LINK:
        return SCENE_TITLES_KEYS[2];
      case SCENE_STATE_TYPES.UNHANDLED_ERROR:
        return SCENE_TITLES_KEYS[2];
      case SCENE_STATE_TYPES.LOGIN_ERROR:
        return SCENE_TITLES_KEYS[2];
      case SCENE_STATE_TYPES.SIGN_IN_LOADING:
        return SCENE_TITLES_KEYS[1];
      default:
        return SCENE_TITLES_KEYS[0];
    }
  }

  async function signInProcess(data = password) {
    setSceneId(SCENE_STATE_TYPES.SIGN_IN_LOADING);
    setIndicatorTranslationKey(
      convertSceneStateTypeToSceneTitleKeys(SCENE_STATE_TYPES.SIGN_IN_LOADING)
    );
    const response = await logIn(email, data, authenticate);
    if (response.response === LoginResponseTypes.LOGIN_SUCCESS) {
      history.push('/dashboard');
    }
    setSceneId(SCENE_STATE_TYPES.LOGIN_ERROR);
  }

  function handleSignIn(data = password) {
    signInProcess(data);
  }

  async function handleFailedCode() {
    await forgotPassword(email, { workflow: 'signup' });
  }

  function handleForgotPasswordSubmit(result, data = password) {
    if (result === ForgotPasswordResponses.SUCCESS) {
      signInProcess(data);
    } else if (result === ForgotPasswordErrorResponses.EXPIRED_CODE) {
      setSceneId(SCENE_STATE_TYPES.EXPIRED_LINK);
      setIndicatorTranslationKey(
        convertSceneStateTypeToSceneTitleKeys(SCENE_STATE_TYPES.EXPIRED_LINK)
      );
      handleFailedCode();
    } else if (result === ForgotPasswordResponses.CODE_MISMATCH_EXCEPTION) {
      setSceneId(SCENE_STATE_TYPES.EXPIRED_LINK);
      setIndicatorTranslationKey(
        convertSceneStateTypeToSceneTitleKeys(SCENE_STATE_TYPES.EXPIRED_LINK)
      );
      handleFailedCode();
    } else {
      if (
        result === ForgotPasswordErrorResponses.LIMIT_EXCEEDED ||
        result === ForgotPasswordErrorResponses.TOO_MANY_REQUESTS
      ) {
        setIsAbleToRetry(true);
      }
      setSceneId(SCENE_STATE_TYPES.UNHANDLED_ERROR);
      setIndicatorTranslationKey(
        convertSceneStateTypeToSceneTitleKeys(SCENE_STATE_TYPES.UNHANDLED_ERROR)
      );
    }
  }

  async function handleContinueButton(data) {
    setPassword(data);
    setIsPasswordFormLoading(true);
    const result = await forgotPasswordSubmit(email, code, data);
    handleForgotPasswordSubmit(result, data);
    setIsPasswordFormLoading(false);
    return result;
  }

  async function handleUnhandledErrorRetryButton() {
    const result = handleContinueButton();
    if (result !== ForgotPasswordResponses.SUCCESS) {
      setIsErrorSnackbarVisible(true);
    }
  }

  async function handleUnhandledErrorResendButton() {
    const result = await forgotPassword(email, {
      workflow: 'signup',
    });
    if (result) {
      setIsSnackbarVisible(true);
    } else {
      setIsErrorSnackbarVisible(true);
    }
  }

  function renderHelmetTranslationKey() {
    switch (sceneId) {
      case SCENE_STATE_TYPES.CREATE_PASSWORD:
        return 'PASSWORD-SET-HELMET-TITLE';
      case SCENE_STATE_TYPES.LOGIN_ERROR:
        return 'ACCOUNT-LOGIN-ERROR-HELMET-TITLE';
      default:
        return 'PASSWORD-SET-ERROR-HELMET-TITLE';
    }
  }

  const [displayedComponent] = useState(() => {
    const getEmail = queryParams.get('email');
    const getCode = queryParams.get('code');

    if (getEmail !== null && getCode !== null) {
      setEmail(queryParams.get('email').replace(/\s/g, '+'));
      setCode(getCode);
      return hasUrlParams.PARAMS;
    }

    return hasUrlParams.NO_PARAMS;
  });

  function renderScene() {
    switch (sceneId) {
      case SCENE_STATE_TYPES.CREATE_PASSWORD:
        return (
          <PasswordForm
            buttonText={t('CONTINUE-BUTTON-TEXT')}
            confirmPasswordLabel={t('CONFIRM-PASSWORD-LABEL')}
            isLoading={isPasswordFormLoading ? 1 : 0}
            isSM={isSM}
            onContinue={(pass) => handleContinueButton(pass)}
            passwordLabel={t('PASSWORD-LABEL')}
          />
        );
      case SCENE_STATE_TYPES.EXPIRED_LINK:
        return (
          <ExpiredLink
            isSM={isSM}
            onResend={handleUnhandledErrorResendButton}
            email={email}
          />
        );
      case SCENE_STATE_TYPES.UNHANDLED_ERROR:
        return (
          <UnhandledError
            isAbleToRetry={isAbleToRetry}
            onResend={handleUnhandledErrorResendButton}
            onRetry={handleUnhandledErrorRetryButton}
            isSM={isSM}
          />
        );
      case SCENE_STATE_TYPES.SIGN_IN_LOADING:
        return (
          <div style={classes.loadingContainer}>
            <LoadingIndicatorLogo iconFontSize={15} iconRight={13} />
          </div>
        );
      case SCENE_STATE_TYPES.LOGIN_ERROR:
        return <LoginError onRetry={handleSignIn} />;

      default:
        return null;
    }
  }

  const ReturnWindow = () => {
    if (displayedComponent === hasUrlParams.PARAMS) {
      return (
        <div style={classes.container}>
          <Helmet>
            <title>{t(renderHelmetTranslationKey())}</title>
          </Helmet>

          <Snackbar
            id="password-set-retry-snackbar"
            text={t('CONFIRMATION-REQUEST-RECEIVED-LABEL')}
            onClose={() => setIsSnackbarVisible(false)}
            open={isSnackbarVisible}
          />

          <Snackbar
            id="password-set-error-snackbar"
            text={t('ERROR-SNACKBAR-TEXT')}
            onClose={() => setIsErrorSnackbarVisible(false)}
            open={isErrorSnackbarVisible}
            severity="error"
          />

          <AdvisoryCloudLogo />

          <Typography
            sx={classes.indicatorText}
            variant="h2"
            color="textSecondary"
          >
            {t(indicatorTranslationKey)}
          </Typography>

          {renderScene()}
        </div>
      );
    }

    if (displayedComponent === hasUrlParams.NO_PARAMS) {
      // TODO in case is view after product lunch. Remove this and below comment
      // history.push('/');
      return <OopsWindow />;
    }

    return <></>;
  };

  return (
    <>
      <ReturnWindow />
    </>
  );
}

export default PasswordSet;
