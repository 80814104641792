import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecurly } from '@recurly/react-recurly';
import { Snackbar } from '@advisorycloud/react-ui-components';
import { useMediaQuery, Paper } from '@mui/material';
import EndpointCaller, { EndpointCallerLoadingIcon } from '../EndpointCaller';
import { getRecurlyPlans, adminSignUp } from '../../services/admin_services';

const useStyles = (styleProps) => ({
  mainContainer: {
    margin: '0px auto',
    width: styleProps.isXS ? '100%' : styleProps.formWidth,
    padding: '20px 30px',
  },
  addMargin: {
    marginBottom: '15px',
  },
  divider: {
    margin: '15px 0px',
  },
  remove: {
    display: 'none',
  },
});

function CheckoutForm(props) {
  const { FormTag, formInitialValues, accountType, purchasingChannel } = props;
  const formWidth = 500;
  const isXS = useMediaQuery((theme) => theme.breakpoints.down([formWidth]));
  const classes = useStyles({
    isXS,
    formWidth,
  });

  const [processingData, setProcessingData] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  // EndpointCaller
  const [isLoading, setIsLoading] = useState(true);
  const [boardSeatsAllowedCount, setBoardSeatsAllowedCount] = useState(0);
  const [recurlyPlanMap, setRecurlyPlanMap] = useState({});
  const [planRadioOptions, setPlanRadioOptions] = useState([]);
  const [freeTrialRadioOptions, setFreeTrialRadioOptions] = useState([]);
  const [betaRadioOptions, setBetaRadioOptions] = useState([]);
  const [formValues, setFormValues] = useState({});
  const history = useHistory();
  const recurly = useRecurly();
  const formRef = useRef();

  const GetRecurlyPlansCall = [
    {
      endPointCall: getRecurlyPlans,
      endPointCallParameters: {
        ACCOUNT_TYPE: accountType,
        PURCHASING_CHANNEL: purchasingChannel,
      },
    },
  ];

  function AdminSignUpCall(recurlyToken) {
    setProcessingData(true);

    adminSignUp({
      USER_SEGMENT: formValues.userSegment || '',
      BOARD_SEATS_ALLOWED_COUNT: boardSeatsAllowedCount,
      COMPANY_NAME: formValues.companyName,
      EMAIL: formValues.emailAddress,
      GIVEN_NAME: formValues.firstName,
      ONBOARDING_EXPERIENCE_UPSELL: formValues.purchasePOE,
      PHONE_COUNTRY_CODE: formValues.countryCode,
      PHONE_NUMBER: formValues.phone,
      POSTAL_CODE: formValues.zipCode,
      RECURLY_ACCOUNT_CODE: formValues.recurlyAccountID,
      RECURLY_BILLING_TOKEN: recurlyToken,
      RECURLY_PLAN_CODE: formValues.planCode,
      SURNAME: formValues.lastName,
      URL_OF_CHECKOUT_PAGE: window.location.hostname + window.location.pathname,
    })
      .then(() => {
        history.push('/dashboard', {
          userCreated: true,
        });
      })
      .catch((error) => {
        console.log(error);
        let jsonError;
        if (error.errors && error.errors[0] && error.errors[0].message) {
          jsonError = JSON.parse(error.errors[0].message);
        } else {
          setSnackbarMessage('Error');
        }

        if (
          jsonError.code === 'SignUpError' &&
          jsonError.error.code === 'RecurlySubscriptionError' &&
          jsonError.error.error.name === 'RecurlyTransactionError'
        ) {
          setSnackbarMessage(jsonError.error.error.transactionError.message);
        } else if (
          jsonError &&
          jsonError.error &&
          jsonError.error.error &&
          jsonError.error.error.message
        ) {
          setSnackbarMessage(jsonError.error.error.message);
        } else if (jsonError && jsonError.error && jsonError.error.error) {
          setSnackbarMessage(jsonError.error.error);
        } else {
          setSnackbarMessage('Error');
        }
        setShowSnackbar(true);
      })
      .finally(() => {
        setProcessingData(false);
      });
  }

  const handleSubmit = (event) => {
    setProcessingData(true);
    if (event.preventDefault) event.preventDefault();

    if (formValues.skipRecurlyCall) {
      AdminSignUpCall();
      return;
    }

    recurly.token(formRef.current, (err, token) => {
      if (err) {
        setSnackbarMessage(err.message);
        setShowSnackbar(true);
        setProcessingData(false);
      } else {
        const recurlyToken = token.id;

        AdminSignUpCall(recurlyToken);
      }
    });
  };

  function retrieveRecurlyPlans(array) {
    const sortObjectsByNumericalValue = (arrayToSort, key) => {
      return arrayToSort.sort((a, b) => a[key] - b[key]);
    };

    if (!array || !array[0]) {
      return;
    }

    const recurlyPlans = array[0].data.getRecurlyPlans;
    const newRecurlyPlanMap = {};
    let newPlanRadioOptions = [];
    let newFreeTrialRadioOptions = [];
    const newBetaRadioOptions = [];
    for (let i = 0; i < recurlyPlans.length; i += 1) {
      newRecurlyPlanMap[recurlyPlans[i].recurlyPlanCode] = recurlyPlans[i];

      const recurlyPlanOption = {
        label: recurlyPlans[i].recurlyPlanCodeLabel,
        value: recurlyPlans[i].recurlyPlanCode,
        displayOrder: recurlyPlans[i].displayOrder,
      };

      if (recurlyPlans[i].legacyPlanCode) {
        if (recurlyPlans[i].lengthOfTrialInDays > 0) {
          newFreeTrialRadioOptions.push(recurlyPlanOption);
        } else {
          newPlanRadioOptions.push(recurlyPlanOption);
        }
      } else {
        newBetaRadioOptions.push(recurlyPlanOption);
      }
    }
    newPlanRadioOptions = sortObjectsByNumericalValue(
      newPlanRadioOptions,
      'displayOrder'
    );
    newFreeTrialRadioOptions = sortObjectsByNumericalValue(
      newFreeTrialRadioOptions,
      'displayOrder'
    );
    setBetaRadioOptions(newBetaRadioOptions);
    setPlanRadioOptions(newPlanRadioOptions);
    setFreeTrialRadioOptions(newFreeTrialRadioOptions);
    setRecurlyPlanMap(newRecurlyPlanMap);
    setIsLoading(false);
  }

  return (
    <Paper sx={classes.mainContainer}>
      <Snackbar
        open={showSnackbar}
        text={snackbarMessage}
        severity="error"
        onClose={() => setShowSnackbar(false)}
        autoHideDuration={6000}
      />
      <EndpointCaller
        endPointCallObjectArray={GetRecurlyPlansCall}
        onEndpointCallsFinished={retrieveRecurlyPlans}
        executeCalls={isLoading}
        loadingIconType={EndpointCallerLoadingIcon.LOGO}
      />
      {isLoading ? null : (
        <FormTag
          classes={classes}
          recurlyPlanMap={recurlyPlanMap}
          planRadioOptions={planRadioOptions}
          freeTrialRadioOptions={freeTrialRadioOptions}
          betaRadioOptions={betaRadioOptions}
          handleSubmit={handleSubmit}
          formRef={formRef}
          formInitialValues={formInitialValues}
          setFormValues={setFormValues}
          processingData={processingData}
          setBoardSeatsAllowedCount={setBoardSeatsAllowedCount}
        />
      )}
    </Paper>
  );
}

export default CheckoutForm;
