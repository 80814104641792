import React from 'react';
import {
  InputLabel,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';

const useStyles = () => ({
  inputLabel: {
    color: 'black',
  },
  inputContainer: {
    width: '100%',
  },
  helperText: {
    color: 'grey.600',
  },
  helperErrorText: {
    color: 'error.main',
  },
  responsivePosition: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

function Input({
  id,
  label,
  className,
  errorTextInRed,
  helperText,
  FormHelperTextProps,
  height,
  fontSize,
  ...rest
}) {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles();

  function classSelector() {
    if (FormHelperTextProps) {
      return FormHelperTextProps;
    }
    if (errorTextInRed) {
      return classes.helperErrorText;
    }
    return classes.helperText;
  }

  return (
    <div style={{ ...classes.inputContainer, ...className }}>
      {isSM ? (
        <InputLabel sx={classes.inputLabel} htmlFor={id}>
          <Typography>{label}</Typography>
          <Typography variant="caption" sx={classSelector()}>
            {helperText}
          </Typography>
        </InputLabel>
      ) : (
        <InputLabel sx={classes.inputLabel} htmlFor={id}>
          <div style={classes.responsivePosition}>
            <Typography>{label}</Typography>
            <Typography variant="caption" sx={classSelector()}>
              {helperText}
            </Typography>
          </div>
        </InputLabel>
      )}

      <TextField
        {...rest}
        sx={classes.inputContainer}
        id={id}
        variant="outlined"
        InputProps={{
          style: { height, fontSize },
        }}
      />
    </div>
  );
}

export default Input;
