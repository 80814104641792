import React from 'react';
import {
  Container,
  Typography,
  Button,
  Box,
  TextField,
  Grid,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import {
  updateCloudsearchService,
  searchCloudsearch,
} from '../../services/admin_services';

function UpdateCloudsearch() {
  const [response, setResponse] = React.useState('');
  const [searchTerm, setSearchTerm] = React.useState('');
  const [responseSearch, setResponseSearch] = React.useState('');
  const [hits, setHits] = React.useState([]);

  async function handleClick() {
    console.log('Update Cloudsearch Advisors button clicked');
    const resp = await updateCloudsearchService();
    if (resp && resp.data && resp.data.updateCloudsearch) {
      setResponse(resp.data.updateCloudsearch);
    }
    console.log(resp);
  }

  const HighlightedText = ({ text }) => {
    return <div dangerouslySetInnerHTML={{ __html: text }} />;
  };

  async function searchCloudsearchService() {
    console.log('Search Cloudsearch Advisors button clicked');
    const resp = await searchCloudsearch({ searchTerm });
    if (resp && resp.data && resp.data.searchCloudsearch) {
      let responseCloudsearch = JSON.parse(resp.data.searchCloudsearch);
      console.log(responseCloudsearch);
      let hitsResp = responseCloudsearch.hits;
      console.log(hitsResp);
      if (hitsResp && hitsResp.hit) {
        setHits(hitsResp.hit);
      } else {
        setResponseSearch(resp.data.searchCloudsearch);
      }
    }
    console.log(resp);
  }

  return (
    <Container maxWidth="sm">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Cloudsearch Management
        </Typography>
        <Button variant="contained" color="primary" onClick={handleClick}>
          Update Cloudsearch Advisors
        </Button>
        <Typography variant="body1" component="p" gutterBottom>
          {response}
        </Typography>
        <Typography variant="h4" component="h1" gutterBottom>
          Search Cloudsearch
        </Typography>
        <TextField
          id="searchTerm"
          label="Search Term"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={searchCloudsearchService}
          style={{ marginBottom: '20px' }}
        >
          Search Cloudsearch Advisors
        </Button>
        <Typography variant="body1" component="p" gutterBottom>
          {responseSearch}
        </Typography>
        <Grid container spacing={2}>
          {hits.map((hit) => (
            <Grid item xs={12} key={hit.id}>
              <Card>
                <CardContent>
                  <Typography variant="h5" component="div" gutterBottom>
                    {hit.fields.givenname} {hit.fields.surname}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    gutterBottom
                  >
                    <HighlightedText text={hit.highlights.headline} />

                    {/* {hit.fields.headline} */}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    gutterBottom
                  >
                    Email: {hit.fields.email}
                  </Typography>
                  <Typography variant="body2" paragraph>
                    <HighlightedText text={hit.highlights.biography} />
                  </Typography>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <strong>Experience</strong>
                          </TableCell>
                          <TableCell>
                            <strong>Industry Tags</strong>
                          </TableCell>
                          <TableCell>
                            <strong>Skills</strong>
                          </TableCell>
                          <TableCell>
                            <strong>Favorite Skills</strong>
                          </TableCell>
                          <TableCell>
                            <strong>Created At</strong>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            {hit.fields.experience
                              ? JSON.parse(hit.fields.experience).join(', ')
                              : 'N/A'}
                          </TableCell>
                          <TableCell>
                            {hit.fields.industrytags
                              ? JSON.parse(hit.fields.industrytags).join(', ')
                              : 'N/A'}
                          </TableCell>
                          <TableCell>
                            {hit.fields.skilltags
                              ? JSON.parse(hit.fields.skilltags).join(', ')
                              : 'N/A'}
                          </TableCell>
                          <TableCell>
                            {hit.fields.favoriteskills
                              ? JSON.parse(hit.fields.favoriteskills).join(', ')
                              : 'N/A'}
                          </TableCell>
                          <TableCell>
                            {hit.fields.createdat
                              ? new Date(
                                  hit.fields.createdat
                                ).toLocaleDateString()
                              : 'N/A'}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
}

export default UpdateCloudsearch;
