import React from 'react';
import { Helmet } from 'react-helmet';
import { RecurlyProvider, Elements } from '@recurly/react-recurly';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import CheckoutForm from '../../components/CheckoutForm';
import AdvisorCheckoutForm from '../../components/CheckoutForm/Forms/AdvisorCheckoutForm';

function AdvisorCheckout() {
  const { t } = useTranslation();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const formInitialValues = {
    companyName: queryParams.get(`company_name`) || ``,
    emailAddress: queryParams.get(`email`) || ``,
    firstName: queryParams.get(`first_name`) || ``,
    lastName: queryParams.get(`last_name`) || ``,
    leadGenerationId: queryParams.get(`lead_generation_id`) || ``,
    phone: queryParams.get(`phone_number`) || ``,
  };

  return (
    <>
      <Helmet>
        <title>{t('ADVISOR_CHECKOUT_HELMET_TITLE')}</title>
      </Helmet>
      <RecurlyProvider publicKey={process.env.REACT_APP_RECURLY_PUBLIC_KEY}>
        <Elements>
          <div style={{ width: '100%' }}>
            <CheckoutForm
              FormTag={AdvisorCheckoutForm}
              formInitialValues={formInitialValues}
              accountType="B2C"
              purchasingChannel="COC"
            />
          </div>
        </Elements>
      </RecurlyProvider>
    </>
  );
}

export default AdvisorCheckout;
