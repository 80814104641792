import React, { useEffect } from 'react';
import {
  Typography,
  useMediaQuery,
  TextField as MaterialTextField,
} from '@mui/material';

const useStyles = () => ({
  label: {
    marginBottom: '8px',
  },
  textFieldContainer: {
    '& input': {
      padding: '18px 14px',
      height: '14px',
    },
  },
  textField: {
    width: '100%',
  },
});

function TextField(props) {
  const {
    labelText,
    placeholder,
    id,
    inputProps,
    errorText,
    validateForm,
    setFieldValue,
    fieldName,
    ...rest
  } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);

  useEffect(() => {
    if (validateForm) {
      validateForm();
    }

    return () => {
      if (setFieldValue) {
        setFieldValue(fieldName, '');
      }
    };
  }, []);

  return (
    <>
      <Typography sx={classes.label} variant="body1">
        {labelText}
      </Typography>
      <div style={classes.textFieldContainer}>
        <MaterialTextField
          id={id}
          inputProps={inputProps}
          sx={classes.textField}
          variant="outlined"
          color="secondary"
          placeholder={placeholder}
          helperText={errorText}
          error={Boolean(errorText)}
          {...rest}
        />
      </div>
    </>
  );
}

export default TextField;
