import React, { useState, useEffect } from 'react';
import {
  Typography,
  useMediaQuery,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  Button,
  LoadingIndicatorLogo,
} from '@advisorycloud/react-ui-components';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getAllRecurlyPlans } from '../../services/admin_services';

const useStyles = (isSM) => ({
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    backgroundColor: isSM && 'white',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
    width: '100%',
    paddingTop: '30px',
    backgroundColor: isSM && 'white',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '95%',
    alignItems: 'center',
    padding: '0px 25px',
  },
  title: {
    marginRight: '10px',
    fontSize: '20px',
    fontWeight: 600,
  },
  tableContainer: {
    overflow: 'scroll',
    maxHeight: '75vh',
    display: 'flex',
    width: '95%',
    padding: '0px 25px',
  },
  tableRow: {
    cursor: 'pointer',
  },
});

const PlanMapping = () => {
  const { t } = useTranslation();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const [plansData, setPlansData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [searchPlan, setSearchPlan] = useState([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  function editClick(planCode) {
    history.push(`/plan-mapping-table/edit/${planCode}`);
  }

  function newClick() {
    history.push('/plan-mapping-table/add');
  }

  useEffect(() => {
    async function fetchData() {
      const plansResponse = await getAllRecurlyPlans();
      if (plansResponse.data && plansResponse.data.getRecurlyPlans) {
        setPlansData(plansResponse.data.getRecurlyPlans);
        setDisplayData(plansResponse.data.getRecurlyPlans);
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  function filterPlanCodes(search) {
    if (search && search.length) {
      setDisplayData(
        plansData.filter((plan) => plan.recurlyPlanCode.includes(search))
      );
    } else {
      setDisplayData(plansData);
    }
  }

  if (loading) {
    return (
      <div style={classes.loadingContainer}>
        <LoadingIndicatorLogo iconFontSize={134} iconRight={162} />
      </div>
    );
  }

  return (
    <>
      <div style={classes.container}>
        <div style={classes.header}>
          <Typography color="textSecondary" variant="h1" sx={classes.title}>
            {t('PLAN-MAPPING-TITLE')}
          </Typography>
          <Button color="primary" onClick={newClick}>
            New Plan Code
          </Button>
        </div>
        <div style={classes.tableContainer}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TextField
                    label="Plan Code"
                    variant="outlined"
                    size="small"
                    value={searchPlan}
                    onChange={(evt) => {
                      setSearchPlan(evt.target.value);
                      filterPlanCodes(evt.target.value);
                    }}
                  />
                </TableCell>
                <TableCell>Label</TableCell>
                <TableCell>Account Type</TableCell>
                <TableCell>Display Name</TableCell>
                <TableCell>Trial (Days)</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Term</TableCell>
                <TableCell>Checkout Url</TableCell>
                <TableCell>Onboarding Url</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {displayData.map((row) => (
                <TableRow
                  key={row.recurlyPlanCode}
                  sx={{
                    ...classes.tableRow,
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                  hover
                  onClick={() => editClick(row.recurlyPlanCode)}
                >
                  <TableCell component="th" scope="row">
                    {row.recurlyPlanCode}
                  </TableCell>
                  <TableCell>{row.recurlyPlanCodeLabel}</TableCell>
                  <TableCell>{row.accountType}</TableCell>
                  <TableCell>{row.recurlyPlanCodeDisplayName}</TableCell>
                  <TableCell>{row.lengthOfTrialInDays}</TableCell>
                  <TableCell>{row.recurlyPlanCodePrice}</TableCell>
                  <TableCell>{row.recurlyPlanCodeTerm}</TableCell>
                  <TableCell>{row.checkoutRedirectUrl}</TableCell>
                  <TableCell>{row.onboardingRedirectUrl}</TableCell>
                  {/* <TableCell>
                    <Button
                      color="secondary"
                      onClick={() => editClick(row.recurlyPlanCode)}
                    >
                      Edit
                    </Button>
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default PlanMapping;
