import React from 'react';
import { useTranslation } from 'react-i18next';
import InputRow, { InputType } from '../../Components/InputRow';

function CompanyPlanInformation(props) {
  const {
    values,
    errors,
    handleBlur,
    handleChange,
    // boardTypeOptions,
    showRecurlyAccountIDTextField,
    showUserSegmentField,
    showTierDropdown,
    showNumberOfSeatsTextField,
    validateForm,
    setFieldValue,
  } = props;
  const { t } = useTranslation();

  const inputRow1Items = [];

  if (showTierDropdown) {
    inputRow1Items.push({
      inputType: InputType.DROPDOWN,
      labelText: t('FIELD_TIER'),
      options: [
        {
          label: 'Growth',
          value: 'Growth',
        },
        {
          label: 'Essentials',
          value: 'Essentials',
        },
        {
          label: 'Business',
          value: 'Business',
        },
      ],
      width: '60%',
      id: 'tier',
      inputProps: {
        'data-cy': 'txtTier',
      },
      value: values.tier,
      errorText: errors.tier,
      onBlur: handleBlur,
      validateForm,
      setFieldValue,
      fieldName: 'tier',
    });
  }

  if (showNumberOfSeatsTextField) {
    inputRow1Items.push({
      inputtype: InputType.TEXT_FIELD,
      labelText: t('FIELD_NUMBER_OF_SEATS'),
      width: '40%',
      id: 'numberOfSeats',
      inputProps: {
        'data-cy': 'txtNumberOfSeats',
      },
      value: values.numberOfSeats,
      errorText: errors.numberOfSeats,
      onBlur: handleBlur,
      onChange: handleChange,
      validateForm,
      setFieldValue,
      fieldName: 'numberOfSeats',
    });
  }

  const inputRow2Items = [];

  if (showRecurlyAccountIDTextField) {
    inputRow2Items.push({
      inputtype: InputType.TEXT_FIELD,
      labelText: t('FIELD_RECURLY_ACCOUNT_ID'),
      width: '50%',
      id: 'recurlyAccountID',
      inputProps: {
        'data-cy': 'txtRecurlyAccountID',
      },
      value: values.recurlyAccountID,
      errorText: errors.recurlyAccountID,
      onBlur: handleBlur,
      onChange: handleChange,
      validateForm,
      setFieldValue,
      fieldName: 'recurlyAccountID',
    });
  }

  if (showUserSegmentField) {
    inputRow2Items.push({
      inputType: InputType.DROPDOWN,
      labelText: t('FIELD_USER_SEGMENT'),
      options: [
        {
          label: 'start_your_advisorycloud',
          value: 'start_your_advisorycloud',
        },
        {
          label: 'managed_advisory_board',
          value: 'managed_advisory_board',
        },
        {
          label: 'insights',
          value: 'insights',
        },
        {
          label: 'bring_your_own',
          value: 'bring_your_own',
        },
        {
          label: 'None',
          value: 'None',
        },
      ],
      width: '50%',
      id: 'userSegment',
      inputProps: {
        'data-cy': 'txtUserSegment',
      },
      value: values.userSegment,
      errorText: errors.userSegment,
      onBlur: handleBlur,
      validateForm,
      setFieldValue,
      fieldName: 'userSegment',
    });
  }

  // inputRow2Items.push({
  //   inputType: InputType.DROPDOWN,
  //   labelText: t('FIELD_PRIVATE_BOARD'),
  //   options: boardTypeOptions,
  //   width: '40%',
  //   id: 'boardType',
  //   inputProps: {
  //     'data-cy': 'txtBoardType',
  //   },
  //   value: values.boardType,
  //   errorText: errors.boardType,
  //   onBlur: handleBlur,
  //   validateForm,
  //   setFieldValue,
  //   fieldName: 'boardType',
  // });

  return (
    <>
      <InputRow rowInputs={inputRow1Items} />
      <InputRow rowInputs={inputRow2Items} />
    </>
  );
}

export default CompanyPlanInformation;
