import React from 'react';
import { useMediaQuery } from '@mui/material';
import { isNil } from '../../../../services/utils';
import TextField from '../TextField';
import Dropdown from '../Dropdown';

export const InputType = {
  TEXT_FIELD: 'textField',
  DROPDOWN: 'dropdown',
};

const useStyles = (styleProps) => ({
  textFieldFlexContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: styleProps.isXS ? 'wrap' : 'unset',
    justifyContent: 'space-between',
  },
  leftEdgeElement: {
    marginRight: styleProps.isXS ? '0px' : styleProps.flexContainerSpacing,
    width: '100%',
    marginBottom: '15px',
  },
  centralElement: {
    marginRight: styleProps.isXS ? '0px' : styleProps.flexContainerSpacing,
    marginLeft: styleProps.isXS ? '0px' : styleProps.flexContainerSpacing,
    width: '100%',
    marginBottom: '15px',
  },
  rightEdgeElement: {
    marginLeft: styleProps.isXS ? '0px' : styleProps.flexContainerSpacing,
    width: '100%',
    marginBottom: '15px',
  },
  singleElement: {
    width: '100%',
    marginBottom: '15px',
  },
  hidden: {
    display: 'none',
  },
});

function InputRow(props) {
  const { rowInputs } = props;
  const formWidth = 500;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isXS = useMediaQuery((theme) => theme.breakpoints.down([formWidth]));
  const classes = useStyles({
    isSM,
    isXS,
    flexContainerSpacing: '5px',
    formWidth,
  });

  function AddDropdown(
    divClass,
    { width, labelText, placeholder, id, options, inputProps, hidden, ...rest }
  ) {
    return (
      <div
        style={{
          ...(hidden ? { ...divClass, ...classes.hidden } : divClass),
          ...(isNil(width) || isXS ? {} : { width }),
        }}
      >
        <Dropdown
          id={id}
          options={options}
          inputProps={inputProps}
          labelText={labelText}
          placeholder={placeholder}
          {...rest}
        />
      </div>
    );
  }

  function AddTextField(
    divClass,
    { width, labelText, placeholder, id, inputProps, hidden, ...rest }
  ) {
    return (
      <div
        key={id}
        style={{
          ...(hidden ? { ...divClass, ...classes.hidden } : divClass),
          ...(isNil(width) || isXS ? {} : { width }),
        }}
      >
        <TextField
          id={id}
          inputProps={inputProps}
          labelText={labelText}
          placeholder={placeholder}
          {...rest}
        />
      </div>
    );
  }

  function AddInput(divClass, rowElementInput) {
    const inputMap = {};
    inputMap[undefined] = () => AddTextField(divClass, rowElementInput);
    inputMap[InputType.TEXT_FIELD] = () =>
      AddTextField(divClass, rowElementInput);
    inputMap[InputType.DROPDOWN] = () => AddDropdown(divClass, rowElementInput);

    return inputMap[rowElementInput.inputType]();
  }

  if (!rowInputs || rowInputs.length === 0) {
    return null;
  }

  if (rowInputs.length === 1) {
    return (
      <div style={classes.textFieldFlexContainer}>
        {AddInput(classes.singleElement, rowInputs[0])}
      </div>
    );
  }

  if (rowInputs.length === 2) {
    return (
      <div style={classes.textFieldFlexContainer}>
        {AddInput(classes.leftEdgeElement, rowInputs[0])}
        {AddInput(classes.rightEdgeElement, rowInputs[1])}
      </div>
    );
  }

  return (
    <div style={classes.textFieldFlexContainer}>
      {AddInput(classes.leftEdgeElement, rowInputs[0])}
      {rowInputs.slice(1, rowInputs.length - 1).map((rowTextField) => {
        return AddInput(classes.centralElement, rowTextField);
      })}
      {AddInput(classes.rightEdgeElement, rowInputs[rowInputs.length - 1])}
    </div>
  );
}

export default InputRow;
