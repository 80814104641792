import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingIndicatorLogo } from '@advisorycloud/react-ui-components';
import { getInsights } from '../../services/admin_services';
import { ArrowDropUp, ArrowDropDown, Sort } from '@mui/icons-material';

const styles = {
  tableCellHeader: {
    fontWeight: '800',
    fontSize: '18px',
  },
  tableCellHeaderSort: {
    fontWeight: '800',
    fontSize: '18px',
    cursor: 'pointer',
  },
};

const Insights = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortPriority, setSortPriority] = useState(null); // [1, -1, null] = [asc, desc, none]
  const [sortStatus, setSortStatus] = useState(null); // [1, -1, null] = [asc, desc, none]
  const history = useHistory();

  function setSort(setFunction = () => {}) {
    setFunction((prev) => {
      if (prev === null) return 1;
      if (prev === 1) return -1;
      if (prev === -1) return null;
    });
  }

  function getIcon(sortValue) {
    if (sortValue === 1) return <ArrowDropUp />;
    if (sortValue === -1) return <ArrowDropDown />;
    return <Sort />;
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getInsights();
        const result = data.getInsightsV2;
        setData(result);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <Box width="100%" p="12px">
      <Box width="100%" display="flex" justifyContent="space-between" mb="8px">
        <Typography variant="h5" gutterBottom>
          Insights
        </Typography>
        <Button
          variant="contained"
          sx={{ borderRadius: '10px' }}
          color="primary"
          onClick={() => {
            history.push('/insight');
          }}
        >
          Create New Insight
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={styles.tableCellHeader}>
                <TextField
                  value={searchTerm}
                  onChange={(event) => setSearchTerm(event.target.value)}
                  placeholder="Search by title"
                />
              </TableCell>
              <TableCell
                sx={styles.tableCellHeaderSort}
                onClick={() => setSort(setSortStatus)}
              >
                <Box display="flex" alignItems="center">
                  Status
                  {getIcon(sortStatus)}
                </Box>
              </TableCell>
              <TableCell sx={styles.tableCellHeader}>Body</TableCell>
              <TableCell sx={styles.tableCellHeader}>KPIs</TableCell>
              <TableCell sx={styles.tableCellHeader}>Function</TableCell>
              <TableCell sx={styles.tableCellHeader}>Insight URL</TableCell>
              <TableCell
                sx={styles.tableCellHeaderSort}
                onClick={() => setSort(setSortPriority)}
              >
                <Box display="flex" alignItems="center">
                  Priority
                  {getIcon(sortPriority)}
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .filter((row) =>
                row.title.toLowerCase().includes(searchTerm.toLowerCase())
              )
              .sort((a, b) => {
                // Sort by status
                if (sortStatus) {
                  if (a.status < b.status) return -1 * sortStatus;
                  if (a.status > b.status) return 1 * sortStatus;
                }

                // Sort by priority
                if (sortPriority) {
                  if (a.priority < b.priority) return -1 * sortPriority;
                  if (a.priority > b.priority) return 1 * sortPriority;
                }
                return 0; // equal values
              })
              .map((row) => (
                <TableRow
                  hover
                  sx={{ cursor: 'pointer' }}
                  key={row.id}
                  onClick={() => {
                    history.push('/insight/' + row.id);
                  }}
                >
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      {process.env.REACT_APP_INSIGHTS_URL && row.icon && (
                        <img
                          src={`${process.env.REACT_APP_INSIGHTS_URL}${row.icon}`}
                          alt={row.title}
                          style={{
                            width: '20px',
                            height: '20px',
                            marginRight: '8px',
                          }}
                        />
                      )}
                      {row.title}
                    </Box>
                  </TableCell>
                  <TableCell>{row.status}</TableCell>
                  <TableCell>{row.body}</TableCell>
                  <TableCell>{row.kpis.join(', ')}</TableCell>
                  <TableCell>{row.function}</TableCell>
                  <TableCell>{row.url}</TableCell>
                  <TableCell>{row.priority}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {loading && (
        <Box width="100%" display="flex" justifyContent="center" p="16px">
          <LoadingIndicatorLogo size={100} iconFontSize={47} iconRight={49} />
        </Box>
      )}
    </Box>
  );
};

export default Insights;
