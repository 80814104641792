import React, { useState, useEffect, useMemo } from 'react';
import { Password, Button } from '@advisorycloud/react-ui-components';
import { Paper, Typography, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

const useStyles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '600px',
    alignItems: 'center',
    paddingTop: '40px',
    marginBottom: '20px',
  },
  buttonContainer: {
    width: '540px',
    marginTop: '24px',
    paddingLeft: '360px',
    marginBottom: '40px',
  },
  validationsContainer: {
    width: '540px',
    marginTop: '20px',
  },
  iconError: {
    color: '#7b1fa2',
    fontSize: '17px',
    marginTop: '6px',
  },
  iconSuccess: {
    color: '#0F78FD',
    fontSize: '17px',
    marginTop: '5px',
  },
  error: {
    color: 'secondary.dark',
    marginLeft: '5px',
  },
  success: {
    color: 'primary.main',
    marginLeft: '5px',
  },
  label: {
    color: 'text.primary',
  },
});

function PasswordForm({
  buttonText,
  confirmPasswordLabel,
  isLoading,
  isSM,
  onContinue,
  passwordLabel,
}) {
  const { t } = useTranslation();
  const classes = useStyles(isSM);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [hasError, setHasError] = useState(true);
  const [touched, setTouched] = useState(false);
  const validations = useMemo(
    () => [
      {
        criteria: (v1 = '') => v1.length >= 8,
        labelTranslationKey: 'PASSWORD-8-CHAR-MIN-ERROR-MESSAGE',
      },
      {
        criteria: (v1 = '') => v1.length <= 99,
        labelTranslationKey: 'PASSWORD-99-CHAR-MAX-ERROR-MESSAGE',
      },
      {
        criteria: (v1 = '') => v1.match(`[@,#,%,^,*,(,),!,=,+,?,~,$,&]`),
        labelTranslationKey:
          'PASSWORD-MUST-CONTAIN-SPECIAL-CHARACTER-ERROR-MESSAGE',
      },
      {
        criteria: (v1 = '') => v1.match(`[0-9]`),
        labelTranslationKey: 'PASSWORD-MUST-CONTAIN-NUMBER-ERROR-MESSAGE',
      },
      {
        criteria: (v1 = '') => v1.match(`[A-Z]`),
        labelTranslationKey:
          'PASSWORD-MUST-CONTAIN-UPPER-CASE-LETTER-ERROR-MESSAGE',
      },
      {
        criteria: (v1 = '') => v1.match(`[a-z]`),
        labelTranslationKey:
          'PASSWORD-MUST-CONTAIN-LOWER-CASE-LETTER-ERROR-MESSAGE',
      },
      {
        criteria: (v1 = '', v2 = '') => v1.length && v1 === v2,
        labelTranslationKey: 'PASSWORDS-DONT-MATCH-ERROR-MESSAGE',
      },
    ],
    []
  );

  function handleKeyUpData(data) {
    setTouched(true);
    if (data.id === 'password') {
      setPassword(data.content);
    } else if (data.id === 'confirmPassword') {
      setConfirmPassword(data.content);
    }
  }

  function handleButton() {
    onContinue(password);
  }

  useEffect(() => {
    const HasError =
      validations.filter(
        (validation) => !validation.criteria(password, confirmPassword)
      ).length > 0;

    setHasError(HasError);
  }, [password, confirmPassword]);

  function renderValidations() {
    if (!touched) {
      return null;
    }

    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        sx={classes.validationsContainer}
      >
        {validations.map((validation) => {
          const valid = validation.criteria(password, confirmPassword);

          return (
            <Grid
              key={validation.labelTranslationKey}
              container
              direction="row"
              alignItems="center"
            >
              <Grid item>
                {valid ? (
                  <CheckIcon style={classes.iconSuccess} />
                ) : (
                  <ClearIcon style={classes.iconError} />
                )}
              </Grid>
              <Grid item>
                <Typography sx={valid ? classes.success : classes.error}>
                  {t(validation.labelTranslationKey)}
                </Typography>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    );
  }

  function renderPasswordLabel() {
    return <Typography sx={classes.label}>{passwordLabel}</Typography>;
  }

  function renderConfirmPasswordLabel() {
    return <Typography sx={classes.label}>{confirmPasswordLabel}</Typography>;
  }

  return (
    <Paper sx={classes.container} elevation={isSM ? 0 : 3}>
      <Password
        confirmPasswordLabel={renderConfirmPasswordLabel()}
        onKeyUpData={handleKeyUpData}
        passwordLabel={renderPasswordLabel()}
        width={540}
      />
      {renderValidations()}
      <div style={classes.buttonContainer}>
        <Button
          data-cy="password-set-continue-button"
          color="primary"
          disabled={hasError}
          loading={isLoading}
          size="small"
          onClick={handleButton}
          style={{ width: 180 }}
          text={buttonText}
        />
      </div>
    </Paper>
  );
}

export default PasswordForm;
