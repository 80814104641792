import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { Button, Password } from '@advisorycloud/react-ui-components';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { withRouter } from 'react-router';
import {
  forgotPasswordSubmit,
  ForgotPasswordResponses,
  forgotPassword,
} from '../../services/Authentication';
import { Transitions } from '../../services/Transition';

const useStyles = () => ({
  contentContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  passwordContainer: {
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  buttonContainer: {
    marginTop: '25px',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  errorPosition: {
    marginTop: '20px',
  },
  error: {
    color: 'secondary.dark',
    marginLeft: '5px',
  },
  success: {
    color: 'primary.main',
    marginLeft: '5px',
  },
  iconError: {
    color: '#7b1fa2',
    fontSize: '17px',
    marginTop: '6px',
  },
  iconSuccess: {
    color: '#0F78FD',
    fontSize: '17px',
    marginTop: '5px',
  },
  label: {
    color: 'rgba(0,0,0,0.87)',
  },
  formContainer: {
    display: 'flex',
    width: '100%',
  },
});

const PasswordResetForm = (props) => {
  const { errorType } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email');
  const code = queryParams.get('code');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isValidAll, setIsValidAll] = useState(undefined);
  const [renderErrors, setRenderErrors] = useState(false);
  const [loading, setLoading] = useState(false);

  async function handleRequest() {
    setLoading(true);
    const newPasswordSubmit = await forgotPasswordSubmit(
      email,
      code,
      confirmPassword
    );
    setLoading(false);

    if (newPasswordSubmit === ForgotPasswordResponses.SUCCESS) {
      props.history.push({
        pathname: '/login',
        state: {
          passwordUpdated: Transitions.Types.PASSWORD_UPDATED,
        },
      });
    } else if (
      newPasswordSubmit === ForgotPasswordResponses.CODE_MISMATCH_EXCEPTION ||
      newPasswordSubmit === ForgotPasswordResponses.EXPIRED_CODE_EXCEPTION
    ) {
      forgotPassword(email);
      errorType(Transitions.Types.EXPIRED_CODE);
    } else {
      errorType(Transitions.Types.FAIL_CODE);
    }
  }

  const [validations] = useState([
    { message: `${t('PASSWORD_VALIDATION_MIN_CHARACTERS')}`, status: true },
    { message: `${t('PASSWORD_VALIDATION_MAX_CHARACTERS')}`, status: true },
    {
      message: `${t('PASSWORD_VALIDATION_SPECIAL_CHARACTERS')}`,
      status: true,
    },
    { message: `${t('PASSWORD_VALIDATION_NUMBER')}`, status: true },
    { message: `${t('PASSWORD_VALIDATION_UPPER_CASE')}`, status: true },
    { message: `${t('PASSWORD_VALIDATION_LOWER_CASE')}`, status: true },
    { message: `${t('PASSWORD_VALIDATION_MATCH')}`, status: true },
  ]);

  function validatePassword(value) {
    let error;

    // Password must be a minimum of 8 characters
    if (value.length < 8) {
      error = ' ';
      validations[0].status = true;
    } else {
      validations[0].status = false;
    }
    // Password cannot be more than 99 characters
    if (value.length <= 99) {
      validations[1].status = false;
    } else {
      error = ' ';
      validations[1].status = true;
    }
    // Password must contain a special character
    if (!value.match(`[@,#,%,^,*,(,),!,=,+,?,~,$,&]`)) {
      error = ' ';
      validations[2].status = true;
    } else {
      validations[2].status = false;
    }
    // Password must contain a number
    if (!value.match(`[0-9]`)) {
      error = ' ';
      validations[3].status = true;
    } else {
      validations[3].status = false;
    }
    // Password must contain an upper case letter
    if (!value.match(`[A-Z]`)) {
      error = ' ';
      validations[4].status = true;
    } else {
      validations[4].status = false;
    }
    // Password must contain a lower case letter
    if (!value.match(`[a-z]`)) {
      error = ' ';
      validations[5].status = true;
    } else {
      validations[5].status = false;
    }
    return error;
  }

  function validateConfirmPassword(value) {
    let error;

    // Password must be a minimum of 8 characters
    if (value !== password) {
      error = ' ';
      validations[6].status = true;
    } else {
      validations[6].status = false;
    }

    return error;
  }

  function displayErrors() {
    return (
      <>
        <Grid
          container
          direction="column"
          alignItems="center"
          sx={classes.errorPosition}
        >
          {validations.map((validation) => {
            return (
              <React.Fragment key={validation.message}>
                <Grid container direction="row" alignItems="center">
                  <Grid item>
                    {validation.status ? (
                      <ClearIcon style={classes.iconError} />
                    ) : (
                      <CheckIcon style={classes.iconSuccess} />
                    )}
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={validation.status ? classes.error : classes.success}
                    >
                      {validation.message}
                    </Typography>
                  </Grid>
                </Grid>
              </React.Fragment>
            );
          })}
        </Grid>
      </>
    );
  }

  useEffect(() => {
    const foundErrors = validations.filter(
      (element) => element.status === true
    );

    if (password.length === 0) {
      setRenderErrors(false);
    } else {
      setRenderErrors(true);
    }

    if (foundErrors.length > 0) {
      setIsValidAll(true);
    } else {
      setIsValidAll(false);
    }
  }, [password, confirmPassword]);

  const textWasEntered = (data) => {
    if (data.id === 'password') {
      setPassword(data.content);
      validatePassword(data.content);
    }

    if (data.id === 'confirmPassword') {
      setConfirmPassword(data.content);
      validateConfirmPassword(data.content);
    }
  };

  const catchMatch = (data) => {
    if (data) {
      validations[6].status = false;
    } else {
      validations[6].status = true;
    }
  };

  const catchError = (data) => {
    if (data) {
      validations[6].status = true;
    } else {
      validations[6].status = false;
    }
  };

  const passwordLabelText = () => {
    return <Typography sx={classes.label}>{t('NEW_PASSWORD')}</Typography>;
  };

  const confirmPasswordLabelText = () => {
    return (
      <Typography sx={classes.label}>{t('CONFIRM_NEW_PASSWORD')}</Typography>
    );
  };

  return (
    <form style={classes.formContainer}>
      <div style={classes.contentContainer}>
        <div style={classes.passwordContainer}>
          <Password
            onKeyUpData={textWasEntered}
            onError={catchError}
            onMatch={catchMatch}
            passwordLabel={passwordLabelText()}
            confirmPasswordLabel={confirmPasswordLabelText()}
            width="100%"
          />

          {renderErrors && displayErrors()}
          <div style={classes.buttonContainer}>
            <Button
              width="180px"
              size="small"
              type="submit"
              color="primary"
              onClick={handleRequest}
              disabled={isValidAll}
              loading={loading ? 1 : 0}
            >
              {t('RESET_BUTTON')}
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default withRouter(PasswordResetForm);
