import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Button,
  Grid,
  Typography,
  Paper,
  Box,
  Snackbar,
  Alert,
} from '@mui/material';
import { LoadingIndicatorLogo } from '@advisorycloud/react-ui-components';
import {
  addInsight,
  getInsight,
  updateInsight,
} from '../../services/admin_services';
import { uploadToS3 } from '../../services/Amplify/files-upload';

const statusOptions = ['Draft', 'Published', 'Deprecated'];
const kpiOptions = [
  'Cost Per Acquisition (CPA)',
  'Revenue',
  'Cost Per Lead (CPL)',
  'Conversion Rate',
  'Click Through Rate (CTR)',
  'Open Rate',
  'Close Rate',
  'MQL to SQL Conversion Rate',
  'Lead Conversion Rate',
  'Growth Rate',
  'Churn Rate',
  'Profitability',
  'Customer NPS',
  'Customer Lifetime Value (Customer LTV)',
  'Average Revenue Per User (ARPU)',
  'Funding Increase',
  'Investor Interest',
  'Marketing Spend',
  'Customer Satisfaction',
];
const functionOptions = ['Marketing', 'Sales', 'CX', 'General', 'Product'];

const InsightsForm = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [disabledSave, setDisabledSave] = useState(false);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [sbOpen, setSBOpen] = useState(false);
  const [sbMessage, setSBMessage] = useState('Something went wrong');
  const [formValues, setFormValues] = useState({
    status: '',
    kpis: [],
    function: '',
    url: '',
    priority: undefined,
    title: '',
    body: '',
    icon: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleKPIChange = (e) => {
    const { value } = e.target;
    // Limit to 4 selections
    if (value.length < 5) {
      setFormValues((prevValues) => ({
        ...prevValues,
        kpis: value,
      }));
    }
  };

  // console.log('process.env');
  // console.log(process.env);
  // console.log('process.env.REACT_APP_S3_BUCKET_INSIGHTS_ID');
  // console.log(process.env.REACT_APP_S3_BUCKET_INSIGHTS_ID);
  // console.log('process.env.REACT_APP_INSIGHTS_URL');
  // console.log(process.env.REACT_APP_INSIGHTS_URL);
  // console.log('process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID');
  // console.log(process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID);

  const handleIconUpload = async (e) => {
    try {
      if (!process.env.REACT_APP_S3_BUCKET_INSIGHTS_ID) {
        setSBMessage('S3 bucket not configured');
        setSBOpen(true);
        return;
      }
      setDisabledSave(true);
      setUploadingFile(true);
      const fileId = `insights-${Date.now()}.${
        e.target.files[0].type.split('/')[1]
      }`;
      await uploadToS3({
        BLOB_LOAD: e.target.files[0],
        CONTENT_TYPE: e.target.files[0].type,
        S3_BUCKET: process.env.REACT_APP_S3_BUCKET_INSIGHTS_ID,
        FILE_ID: fileId,
      });

      setFormValues((prevValues) => ({
        ...prevValues,
        icon: `public/${fileId}`,
      }));
      setDisabledSave(false);
    } catch (error) {
      console.log(error);
      setSBMessage('Error uploading icon');
      setSBOpen(true);
    }
    setUploadingFile(false);
    setDisabledSave(false);
  };

  const createInsight = async () => {
    try {
      await addInsight(formValues);
      history.replace('/insights');
    } catch (error) {
      console.log(error);
      if (error.errors && error.errors[0] && error.errors[0].message) {
        let fullError = JSON.parse(error.errors[0].message);
        if (fullError && fullError.message) {
          setSBMessage(fullError.message);
        }
      }
      setSBOpen(true);
    }
    setButtonLoading(false);
  };

  const update = async () => {
    try {
      await updateInsight(formValues);
      history.replace('/insights');
    } catch (error) {
      console.log(error);
      if (error.errors && error.errors[0] && error.errors[0].message) {
        let fullError = JSON.parse(error.errors[0].message);
        if (fullError && fullError.message) {
          setSBMessage(fullError.message);
        }
      }
      setSBOpen(true);
    }
    setButtonLoading(false);
  };

  useEffect(() => {
    const pathname = window.location.pathname.split('/');
    if (pathname[2]) {
      setIsEdit(true);
      setLoading(true);
      const fetchInsight = async () => {
        try {
          const { data } = await getInsight(pathname[2]);
          if (!data.getInsight) {
            setSBMessage('Insight not found');
            setSBOpen(true);
            setDisabledSave(true);
          } else {
            setFormValues({ ...data.getInsight });
          }
        } catch (error) {
          console.error(error);
          setSBMessage('Error fetching insight');
          setSBOpen(true);
          setDisabledSave(true);
        }
        setLoading(false);
      };
      fetchInsight();
    }
  }, []);

  if (loading) {
    return (
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <LoadingIndicatorLogo size={150} iconFontSize={65} iconRight={77} />
      </Box>
    );
  }

  return (
    <Paper elevation={0} sx={{ padding: '20px', width: '100%' }}>
      <Typography variant="h5" gutterBottom>
        {isEdit ? 'Editing ' + formValues.title : 'New Insight'}
      </Typography>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          setButtonLoading(true);
          if (isEdit) {
            update();
          } else {
            createInsight();
          }
        }}
      >
        <Grid container spacing={3}>
          {/* First Column */}
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Title"
              name="title"
              value={formValues.title}
              onChange={handleInputChange}
            />
            <TextField
              multiline
              rows={2}
              fullWidth
              label="Body"
              name="body"
              value={formValues.body}
              onChange={handleInputChange}
            />

            <TextField
              fullWidth
              type="number"
              label="Priority"
              name="priority"
              value={formValues.priority}
              onChange={handleInputChange}
              InputProps={{
                inputProps: { min: 1, max: 20, pattern: '[0-9]*' },
              }}
            />
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="icon-upload"
              type="file"
              onChange={handleIconUpload}
            />
            <Box
              display="flex"
              justifyContent={uploadingFile ? 'flex-start' : 'space-between'}
              sx={{ width: { sm: '100%', md: '50%' } }}
            >
              <label htmlFor="icon-upload">
                <Button
                  variant="contained"
                  component="span"
                  disabled={disabledSave || uploadingFile}
                  sx={{ marginRight: '16px' }}
                >
                  {uploadingFile ? 'Uploading' : 'Upload File'}
                </Button>
              </label>
              {uploadingFile && (
                <LoadingIndicatorLogo
                  size={40}
                  iconFontSize={15}
                  iconRight={13}
                />
              )}
              {formValues.icon && !uploadingFile && (
                <Box width="100px">
                  <img
                    src={`${process.env.REACT_APP_INSIGHTS_URL}${formValues.icon}`}
                    alt="Icon Preview"
                    style={{ maxWidth: '100%' }}
                  />
                </Box>
              )}
            </Box>
          </Grid>

          {/* Second Column */}
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                name="status"
                value={formValues.status}
                onChange={handleInputChange}
              >
                {statusOptions.map((status) => (
                  <MenuItem key={status} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel>KPIs</InputLabel>
              <Select
                name="kpis"
                multiple
                value={formValues.kpis}
                onChange={handleKPIChange}
                renderValue={(selected) => selected.join(', ')}
              >
                {kpiOptions.map((kpi) => (
                  <MenuItem key={kpi} value={kpi}>
                    {kpi}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel>Function</InputLabel>
              <Select
                name="function"
                value={formValues.function}
                onChange={handleInputChange}
              >
                {functionOptions.map((func) => (
                  <MenuItem key={func} value={func}>
                    {func}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              label="URL"
              name="url"
              value={formValues.url}
              onChange={handleInputChange}
            />

            <Box display="flex">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={buttonLoading || disabledSave}
                sx={{ marginRight: '16px' }}
              >
                Submit
              </Button>
              {buttonLoading && (
                <LoadingIndicatorLogo
                  size={40}
                  iconFontSize={15}
                  iconRight={13}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </form>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={sbOpen}
        onClose={() => setSBOpen(false)}
      >
        <Alert
          severity="warning"
          sx={{ width: '100%' }}
          onClose={() => setSBOpen(false)}
        >
          {sbMessage}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default InsightsForm;
