import React, { useState } from 'react';
import { Typography, Grid, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  IconUnderlay,
  ACIcon,
  Snackbar,
} from '@advisorycloud/react-ui-components';
import { Helmet } from 'react-helmet';
import CardScene from '../../components/CardScene';
import { Transitions } from '../../services/Transition';
import { forgotPassword } from '../../services/Authentication/index';
import CardFooter from '../../components/CardFooter';

const useStyles = (isSM) => ({
  container: {
    padding: '20px',
    backgroundColor: isSM && 'white',
  },
  sentEmail: {
    marginTop: '10px',
    textAlign: 'center',
    color: 'secondary.dark',
  },
  changePassword: {
    marginTop: '10px',
    maxWidth: '450px',
    textAlign: 'center',
    color: 'secondary.dark',
  },
  textClickEmail: {
    marginTop: '30px',
    marginBottom: '60px',
    maxWidth: '400px',
    textAlign: 'center',
    color: 'secondary.dark',
  },
});

function RequestReset(props) {
  const { email, flowType } = props;
  const { t } = useTranslation();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const [open, setOpen] = useState(false);
  const [text, setText] = useState('');
  const [severityHandler, setSeverityHandler] = useState('');

  function handleClose() {
    setOpen(false);
  }

  const FirstTimeWindow = () => {
    return (
      <Typography
        variant="h2"
        color="textSecondary"
        sx={classes.changePassword}
      >
        {t('FIRST_TIME_CHANGE', { email })}
      </Typography>
    );
  };

  const UserRequestWindow = () => {
    return (
      <Typography variant="h2" color="textSecondary" sx={classes.sentEmail}>
        {t('REQUEST_RESET_SENT_EMAIL', { email })}
      </Typography>
    );
  };

  function flowWindowSelection() {
    if (flowType === Transitions.Types.COMPONENT_TRANSITION) {
      return <UserRequestWindow />;
    }
    if (flowType === Transitions.Types.FIRST_TIME) {
      return <FirstTimeWindow />;
    }
    return undefined;
  }

  async function handleRequest() {
    const isRequestOk = await forgotPassword(email);

    if (isRequestOk) {
      setText(t('REQUEST_RESET_SNACKBAR'));
      setSeverityHandler('success');
      setOpen(true);
    } else {
      setText(t('FORGOT_PASSWORD_API_ERROR'));
      setSeverityHandler('error');
      setOpen(true);
    }
  }

  const RequestResetWindow = () => {
    return (
      <>
        <Helmet>
          <title>{t('PASSWORD_RESET_HEADER_TITLE')}</title>
        </Helmet>
        <CardScene>
          <div style={classes.container}>
            <Grid
              container
              justify="center"
              alignItems="center"
              direction="column"
              spacing={2}
            >
              <Grid item>
                <IconUnderlay>
                  <ACIcon
                    iconname="lock-outlined"
                    fontSize="large"
                    color="primary"
                  />
                </IconUnderlay>
              </Grid>
              <Grid item>
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  direction="column"
                >
                  {flowWindowSelection()}
                  <Typography
                    variant="h2"
                    color="textSecondary"
                    sx={classes.textClickEmail}
                  >
                    {t('REQUEST_RESET_CLICK_EMAIL')}
                  </Typography>

                  <CardFooter onClickFooter={handleRequest} mailto />

                  <Snackbar
                    open={open}
                    text={text}
                    onClose={handleClose}
                    severity={severityHandler}
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </CardScene>
      </>
    );
  };

  return (
    <>
      <RequestResetWindow />
    </>
  );
}

export default RequestReset;
