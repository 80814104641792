import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { Divider } from '@mui/material';
import { Button } from '@advisorycloud/react-ui-components';
import RadioGroup from '../../Components/RadioGroup';
import Checkbox from '../../Components/Checkbox';
import PaymentInformation from '../../Sections/PaymentInformation';
import UserInformation from '../../Sections/UserInformation';

function AdvisorCheckoutForm(props) {
  const {
    classes,
    recurlyPlanMap,
    planRadioOptions,
    betaRadioOptions,
    freeTrialRadioOptions,
    handleSubmit,
    formRef,
    setFormValues,
    formInitialValues,
    processingData,
  } = props;
  const [showPurchasePOECheckbox, setShowPurchasePOECheckbox] = useState(false);
  const [cardDataReady, setCardDataReady] = useState(false);
  const [purchasePOE, setPurchasePOE] = useState(false);
  const { t } = useTranslation();

  function changeSelectedPlanCode(newRecurlyPlanCode) {
    const { isPoeEligible } = recurlyPlanMap[newRecurlyPlanCode];

    setShowPurchasePOECheckbox(isPoeEligible);

    if (!isPoeEligible) {
      setPurchasePOE(false);
    }
  }

  function handleCardDataChange(e) {
    setCardDataReady(e.valid);
  }

  const CheckoutFormSchema = Yup.object().shape({
    planCode: Yup.string().required(t('VALIDATION_SELECT_PLAN_OR_FREE_TRIAL')),
    purchasePOE: Yup.boolean().default(false),
    firstName: Yup.string().required(t('VALIDATION_REQUIRED')),
    lastName: Yup.string().required(t('VALIDATION_REQUIRED')),
    emailAddress: Yup.string()
      .required(t('VALIDATION_REQUIRED'))
      .matches(
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
        t('VALIDATION_INVALID_EMAIL')
      ),
    phone: Yup.string()
      .required(t('VALIDATION_REQUIRED'))
      .matches(/^[0-9]+$/, t('VALIDATION_INVALID_NUMBER'))
      .test('len', t('VALIDATION_MUST_BE_AT_LEAST_7_DIGITS'), (val) => {
        if (val) return !(val.toString().length < 7);
        return false;
      }),
    companyName: Yup.string().required(t('VALIDATION_REQUIRED')),
    countryCode: Yup.string().required(t('VALIDATION_REQUIRED')),
    billingAddress: Yup.string().required(t('VALIDATION_REQUIRED')),
    city: Yup.string().required(t('VALIDATION_REQUIRED')),
    state: Yup.string().required(t('VALIDATION_REQUIRED')),
    zipCode: Yup.string().required(t('VALIDATION_REQUIRED')),
  });

  const defaultInitialValues = {
    planCode: '',
    purchasePOE: false,
    firstName: '',
    lastName: '',
    emailAddress: '',
    phone: '',
    companyName: '',
    countryCode: 'US',
    billingAddress: '',
    city: '',
    state: '',
    zipCode: '',
  };

  const initialFormValues = { ...defaultInitialValues, ...formInitialValues };

  return (
    <>
      <Formik
        initialValues={initialFormValues}
        validationSchema={CheckoutFormSchema}
        validateOnMount
        validateOnChange
        validateOnBlur
      >
        {(formik) => {
          const {
            handleChange,
            handleBlur,
            values,
            isValid,
            setFieldValue,
            errors,
          } = formik;

          if (!showPurchasePOECheckbox) {
            values.purchasePOE = false;
          }

          return (
            <Form
              ref={formRef}
              onChange={() => {
                setFormValues({
                  ...values,
                });
              }}
            >
              <RadioGroup
                className={classes.addMargin}
                id="planCode1"
                labelText={t('FIELD_PLAN')}
                radioOptions={planRadioOptions}
                value={values.planCode}
                onChange={(event) => {
                  changeSelectedPlanCode(event.target.value);
                  setFieldValue('planCode', event.target.value);
                }}
                errorText={errors.planCode}
              />
              {freeTrialRadioOptions.length > 0 && (
                <RadioGroup
                  className={classes.addMargin}
                  id="planCode2"
                  labelText={t('FIELD_FREE_TRIAL')}
                  radioOptions={freeTrialRadioOptions}
                  value={values.planCode}
                  onChange={(event) => {
                    changeSelectedPlanCode(event.target.value);
                    setFieldValue('planCode', event.target.value);
                  }}
                  errorText={errors.planCode}
                />
              )}
              {betaRadioOptions.length > 0 && (
                <RadioGroup
                  className={classes.addMargin}
                  id="planCode3"
                  labelText={t('FIELD_BETA_OPTIONS')}
                  radioOptions={betaRadioOptions}
                  value={values.planCode}
                  onChange={(event) => {
                    changeSelectedPlanCode(event.target.value);
                    setFieldValue('planCode', event.target.value);
                  }}
                  errorText={errors.planCode}
                />
              )}
              <Divider sx={classes.divider} />
              {showPurchasePOECheckbox ? (
                <>
                  <Checkbox
                    id="purchasePOE"
                    labelText={t('FIELD_PURCHASE_POE')}
                    checked={purchasePOE}
                    onChange={(event) => {
                      setFieldValue('purchasePOE', event.target.checked);
                      setPurchasePOE(event.target.checked);
                      // eslint-disable-next-line react/jsx-curly-newline
                    }}
                  />
                  <Divider sx={classes.divider} />
                </>
              ) : null}
              <UserInformation
                values={values}
                errors={errors}
                handleBlur={handleBlur}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
              />
              <PaymentInformation
                values={values}
                errors={errors}
                handleBlur={handleBlur}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                handleCardDataChange={handleCardDataChange}
              />
              <Divider sx={classes.divider} />
              <Button
                type="submit"
                color="primary"
                disabled={!isValid || !cardDataReady || processingData}
                onClick={(event) => {
                  handleSubmit(event);
                }}
                loading={processingData ? 1 : 0}
              >
                {t('CHECKOUT_BUTTON_TEXT')}
              </Button>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default AdvisorCheckoutForm;
