import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Button,
  Grid,
  Typography,
  Paper,
  Box,
  Snackbar,
  Alert,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { LoadingIndicatorLogo } from '@advisorycloud/react-ui-components';
import {
  addAdmin,
  getAdmin,
  updateAdmin,
  updateAdminAccountStatus,
} from '../../services/admin_services';
import CountryDropdown from '../../components/CheckoutForm/Components/CountryDropdown';
import { UserStatus } from '../../services/utils/types';

const accountTypes = ['super_admin', 'system_admin'];

const AdminsForm = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [disabledSave, setDisabledSave] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [sbOpen, setSBOpen] = useState(false);
  const [sbMessage, setSBMessage] = useState('Something went wrong');
  const [sbSeverity, setSBSeverity] = useState('warning');
  const [formValues, setFormValues] = useState({
    givenName: '',
    surName: '',
    accountType: 'super_admin',
    companyName: 'Vendor',
    title: 'SDR',
    phoneCountryCode: 'US',
    phoneNumber: '4152897115',
    salesforceContactId: '',
  });
  const [accountStatus, setAccountStatus] = useState(null);
  const userIsInactive = [UserStatus.INACTIVE, UserStatus.PAUSED].includes(
    accountStatus
  );
  console.log({ accountStatus, userIsInactive });

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const createAdmin = async () => {
    try {
      await addAdmin(formValues);
      history.replace('/admins-managment');
    } catch (error) {
      console.log(error);
      if (error.errors && error.errors[0] && error.errors[0].message) {
        let fullError = JSON.parse(error.errors[0].message);
        if (fullError && fullError.message) {
          setSBMessage(fullError.message);
        }
      }
      setSBOpen(true);
    }
    setButtonLoading(false);
  };

  const update = async () => {
    try {
      await updateAdmin(formValues);
      history.replace('/admins-managment');
    } catch (error) {
      console.log(error);
      if (error.errors && error.errors[0] && error.errors[0].message) {
        let fullError = JSON.parse(error.errors[0].message);
        if (fullError && fullError.message) {
          setSBMessage(fullError.message);
        }
      }
      setSBOpen(true);
    }
    setButtonLoading(false);
  };

  const updateAccountStatus = async () => {
    setDialogOpen(false);
    setButtonLoading(true);
    try {
      const finalStatus =
        accountStatus === UserStatus.ACTIVE || accountStatus === UserStatus.NEW
          ? UserStatus.INACTIVE
          : UserStatus.ACTIVE;
      await updateAdminAccountStatus({
        id: formValues.id,
        accountStatus: finalStatus,
      });
      setAccountStatus(finalStatus);
      setSBSeverity('success');
      setSBMessage(
        `Account ${finalStatus === UserStatus.ACTIVE ? 'enabled' : 'disabled'}`
      );
      setSBOpen(true);
    } catch (error) {
      console.log(error);
      if (error.errors && error.errors[0] && error.errors[0].message) {
        let fullError = JSON.parse(error.errors[0].message);
        if (fullError && fullError.message) {
          setSBMessage(fullError.message);
        }
      }
      setSBOpen(true);
    }
    setButtonLoading(false);
  };

  useEffect(() => {
    const pathname = window.location.pathname.split('/');
    if (pathname[2]) {
      setIsEdit(true);
      setLoading(true);
      const fetchInsight = async () => {
        try {
          const { data } = await getAdmin(pathname[2]);
          if (!data.getAdmin) {
            setSBMessage('Admin not found');
            setSBOpen(true);
            setDisabledSave(true);
          } else {
            console.log('data.getAdmin');
            console.log(data.getAdmin);
            setFormValues({
              ...data.getAdmin,
              phoneNumber: data.getAdmin.phoneNumber?.phoneNumber || '',
              phoneCountryCode: data.getAdmin.phoneNumber?.countryCode || '',
            });
            setAccountStatus(data.getAdmin.accountStatus);
          }
        } catch (error) {
          console.error(error);
          setSBMessage('Error fetching admin');
          setSBOpen(true);
          setDisabledSave(true);
        }
        setLoading(false);
      };
      fetchInsight();
    }
  }, []);

  if (loading) {
    return (
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <LoadingIndicatorLogo size={150} iconFontSize={65} iconRight={77} />
      </Box>
    );
  }

  return (
    <Paper elevation={0} sx={{ padding: '20px', width: '100%' }}>
      <Typography variant="h5" gutterBottom>
        {isEdit
          ? `Editing ${formValues.givenName} ${formValues.surName}`
          : 'New Admin'}
      </Typography>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          setButtonLoading(true);
          if (isEdit) {
            update();
          } else {
            createAdmin();
          }
        }}
      >
        <Grid container spacing={3}>
          {/* First Column */}
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="First Name"
              name="givenName"
              value={formValues.givenName}
              onChange={handleInputChange}
            />
            <TextField
              fullWidth
              label="Last Name"
              name="surName"
              value={formValues.surName}
              onChange={handleInputChange}
            />
            <TextField
              fullWidth
              disabled={isEdit}
              type="email"
              label="Email"
              name="email"
              value={formValues.email}
              onChange={handleInputChange}
            />
            <TextField
              fullWidth
              label="Title"
              name="title"
              value={formValues.title}
              onChange={handleInputChange}
            />
            <TextField
              fullWidth
              label="Company Name"
              name="companyName"
              value={formValues.companyName}
              onChange={handleInputChange}
            />
          </Grid>

          {/* Second Column */}
          <Grid item xs={6}>
            <FormControl fullWidth disabled={isEdit}>
              <InputLabel>Account Type</InputLabel>
              <Select
                name="accountType"
                value={formValues.accountType}
                onChange={handleInputChange}
              >
                {accountTypes.map((accountType) => (
                  <MenuItem key={accountType} value={accountType}>
                    {accountType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              label="Salesforce ID"
              name="salesforceContactId"
              value={formValues.salesforceContactId}
              onChange={handleInputChange}
            />
            <TextField
              fullWidth
              label="Phone Number"
              name="phoneNumber"
              value={formValues.phoneNumber}
              onChange={handleInputChange}
            />

            <CountryDropdown
              labelText="Country"
              value={formValues.phoneCountryCode}
              onChange={(_, value) => {
                if (value === null) {
                  handleInputChange({
                    target: { name: 'phoneCountryCode', value: 'US' },
                  });
                } else {
                  handleInputChange({
                    target: { name: 'phoneCountryCode', value: value.code },
                  });
                }
              }}
              fullWidth
              excludeNullCountryOption
            />

            <Box display="flex" justifyContent="space-between">
              <Box display="flex">
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={buttonLoading || disabledSave}
                  sx={{ marginRight: '16px', textTransform: 'none' }}
                >
                  Submit
                </Button>
                {buttonLoading && (
                  <LoadingIndicatorLogo
                    size={40}
                    iconFontSize={15}
                    iconRight={13}
                  />
                )}
              </Box>
              {isEdit && (
                <Button
                  onClick={() => {
                    setDialogOpen(true);
                  }}
                  variant="contained"
                  color={userIsInactive ? 'success' : 'error'}
                  type="button"
                  disabled={buttonLoading || disabledSave}
                  sx={{ marginRight: '16px', textTransform: 'none' }}
                >
                  {userIsInactive ? 'Enable Account' : 'Kill it!'}
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </form>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={sbOpen}
        onClose={() => setSBOpen(false)}
      >
        <Alert
          severity={sbSeverity}
          sx={{ width: '100%' }}
          onClose={() => {
            setSBOpen(false);
            setSBSeverity('warning');
          }}
        >
          {sbMessage}
        </Alert>
      </Snackbar>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogContent>
          <DialogContentText sx={{ fontSize: '24px', fontWeight: 'bold' }}>
            Warning!
          </DialogContentText>
          <DialogContentText sx={{ fontSize: '20px' }}>
            {userIsInactive
              ? `This account is ${accountStatus} and you are about to enable it. Are you sure?`
              : `This account is ${accountStatus} and you are about to disable it. Are you sure?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDialogOpen(false)}
            color="primary"
            disabled={buttonLoading}
          >
            Cancel
          </Button>
          <Button
            onClick={updateAccountStatus}
            color={userIsInactive ? 'success' : 'error'}
            disabled={buttonLoading}
          >
            {userIsInactive ? 'Enable Account' : 'Kill it!'}
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default AdminsForm;
