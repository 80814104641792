import React, { useState } from 'react';
import { useMediaQuery, Divider, Typography, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AdvisoryCloudLogo, Button } from '@advisorycloud/react-ui-components';
import { useHistory } from 'react-router-dom';
import AvatarMenu from './menu';
import { useAccount } from '../../contexts/Account';
import { cognitoGroupType } from '../../services/utils/cognitoGroupsTypes';

const useStyles = (isSM) => ({
  container: {
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: isSM ? '0px' : '98px',
    paddingTop: '6px',
    paddingBottom: '6px',
    border: '.5px solid rgb(230,237,254)',
    height: '73px',
  },
  logoDividerContainer: {
    display: 'flex',
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: isSM ? 'center' : 'flex-start',
    alignItems: 'center',
  },
  logoImage: {
    width: isSM ? '75px' : '150px',
    display: 'flex',
  },
  divider: {
    height: '60px',
    paddingTop: '40px',
    marginRight: isSM ? '5px' : '15px',
  },
  consoleText: {
    fontWeight: 600,
    color: 'primary.main',
    fontSize: isSM ? '.6rem' : '.8rem',
  },
  input: {
    height: '45px',
    fontSize: isSM ? '.7rem' : '1rem',
  },
  searchBar: {
    marginLeft: isSM ? '5px' : '30px',
    width: '100%',
  },
  noSearchBar: {
    marginLeft: isSM ? '5px' : '30px',
    display: 'flex',
    flexGrow: 1,
  },
  searchButton: {
    marginLeft: isSM ? '5px' : '15px',
  },
  avatarButton: {
    marginLeft: isSM ? '5px' : '15px',
    marginRight: isSM ? '5px' : '130px',
  },
});

function UpperBar() {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { signOut, cognitoGroups } = useAccount();
  const [inputValue, setInputValue] = useState('');
  const classes = useStyles(isSM);
  const { t } = useTranslation();
  const history = useHistory();

  async function handleSignOut() {
    await signOut();
    history.push('/login');
  }
  async function search() {
    const urlEncodedInput = encodeURIComponent(inputValue);
    let searchValue = `?query=${urlEncodedInput}`
    if (inputValue.startsWith('id:')) {
      searchValue = `?id=${inputValue.split('id:')[1]}`
    }
    history.push({
      pathname: '/search',
      search: searchValue,
    });
  }

  const showSearchBar =
    cognitoGroups &&
    (cognitoGroups.includes(cognitoGroupType.MEMBER_SERVICES) ||
      cognitoGroups.includes(cognitoGroupType.MANAGER) ||
      cognitoGroups.includes(cognitoGroupType.SUPER_ADMIN));

  function environmentUrl() {
    if (process.env.NODE_ENV === 'development') {
      return 'https://console-dev.advisorycloud.com';
    }
    if (process.env.NODE_ENV === 'production') {
      return 'https://console.advisorycloud.com';
    }
    if (process.env.NODE_ENV === 'test') {
      return 'https://console-test.advisorycloud.com';
    }
    return null;
  }

  return (
    <div style={classes.container}>
      <div style={classes.logoDividerContainer}>
        <Divider orientation="vertical" sx={classes.divider} />
        <div style={classes.logoContainer}>
          <div style={classes.logoImage}>
            <AdvisoryCloudLogo width="100%" link={environmentUrl()} />
          </div>
          <Typography
            sx={{
              ...classes.consoleText,
              position: 'relative',
              bottom: '50px',
            }}
          >
            {t('CONSOLE_UPPER_BAR_CONSOLE')}
          </Typography>
        </div>
      </div>
      {showSearchBar ? (
        <>
          <div style={classes.searchBar}>
            <TextField
              fullWidth
              onChange={(evt) => setInputValue(evt.target.value)}
              value={inputValue}
              placeholder={t('CONSOLE_UPPER_BAR_SEARCH_COMP')}
              InputProps={{
                classes: {
                  root: classes.input,
                },
              }}
              variant="outlined"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  search();
                }
              }}
            />
          </div>
          <div style={classes.searchButton}>
            <Button
              type="submit"
              color="primary"
              size={isSM ? 'small' : 'medium'}
              disabled={!inputValue.length > 0}
              onClick={() => search()}
            >
              {t('CONSOLE_UPPER_BAR_SEARCH_BUTTON')}
            </Button>
          </div>
        </>
      ) : (
        <div style={classes.noSearchBar} />
      )}

      <div style={classes.avatarButton}>
        <AvatarMenu logoutClick={() => handleSignOut()} />
      </div>
    </div>
  );
}

export default UpperBar;
