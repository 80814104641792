import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { Snackbar } from '@advisorycloud/react-ui-components';
import { RecurlyProvider, Elements, useRecurly } from '@recurly/react-recurly';
import { CardElement } from '@recurly/react-recurly';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button } from '@advisorycloud/react-ui-components';
import RadioGroup from '../../components/CheckoutForm/Components/RadioGroup';
import CountryDropdown from '../../components/CheckoutForm/Components/CountryDropdown';
import {
  useMediaQuery,
  Paper,
  TextField,
  MenuItem,
  Divider,
  Grid,
  Typography,
  InputLabel,
  Select,
} from '@mui/material';
import * as Yup from 'yup';
import { getRecurlyPlans, adminSignUp } from '../../services/admin_services';

const useStyles = (styleProps) => ({
  mainContainer: {
    margin: '0px auto',
    width: styleProps.isXS ? '100%' : styleProps.formWidth,
    padding: '20px 30px',
  },
  addMargin: {
    marginBottom: '15px',
  },
  divider: {
    margin: '15px 0px',
  },
});

function CompanyCheckout() {
  const { t } = useTranslation();
  const formWidth = 500;
  const isXS = useMediaQuery((theme) => theme.breakpoints.down([formWidth]));
  const classes = useStyles({
    isXS,
    formWidth,
  });

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [processingData, setProcessingData] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isValid, setIsValid] = useState(false);
  const [boardSeatsAllowedCount, setBoardSeatsAllowedCount] = useState(0);
  const [recurlyPlanMap, setRecurlyPlanMap] = useState({});
  const [planRadioOptions, setPlanRadioOptions] = useState([]);
  const [freeTrialRadioOptions, setFreeTrialRadioOptions] = useState([]);
  const [betaRadioOptions, setBetaRadioOptions] = useState([]);
  const [selectedPlanCode, setSelectedPlanCode] = useState('');
  const [cardDataReady, setCardDataReady] = useState(false);
  const [cardDataNeeded, setCardDataNeeded] = useState(false);
  const [showRecurlyAccountIDTextField, setShowRecurlyAccountIDTextField] =
    useState(false);
  const [showUserSegmentField, setShowUserSegmentField] = useState(false);
  const [showPaymentInformationSection, setShowPaymentInformationSection] =
    useState(false);
  const [values, setValues] = useState({
    companyName: queryParams.get('company') || '',
    emailAddress: queryParams.get('email') || '',
    firstName: queryParams.get('first_name') || '',
    lastName: queryParams.get('last_name') || '',
    recurlyAccountID: queryParams.get('recurly_account_id') || '',
    phone: queryParams.get('phone') || '',
    userSegment: '',
    countryCode: 'US',
  });
  const history = useHistory();
  const recurly = useRecurly();
  const formRef = useRef();

  const baseFieldsSchema = Yup.object().shape({
    firstName: Yup.string().required(t('VALIDATION_REQUIRED')),
    lastName: Yup.string().required(t('VALIDATION_REQUIRED')),
    emailAddress: Yup.string()
      .required(t('VALIDATION_REQUIRED'))
      .matches(
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
        t('VALIDATION_INVALID_EMAIL')
      ),
    phone: Yup.string()
      .required(t('VALIDATION_REQUIRED'))
      .matches(/^[0-9]+$/, t('VALIDATION_INVALID_NUMBER'))
      .test('len', t('VALIDATION_MUST_BE_AT_LEAST_7_DIGITS'), (val) => {
        if (val) return !(val.toString().length < 7);
        return false;
      }),
    companyName: Yup.string().required(t('VALIDATION_REQUIRED')),
    countryCode: Yup.string().required(t('VALIDATION_REQUIRED')),
    planCode: Yup.string().required(t('VALIDATION_REQUIRED')),
  });
  console.log(values)

  function isValidEmail(email) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }

  function getEmailHelperText(email) {
    if (!email) {
      return 'Email is required';
    } else if (!isValidEmail(email)) {
      return t('VALIDATION_INVALID_EMAIL');
    }
    return '';
  }

  function isValidPhoneNumber(phone) {
    const phoneRegex = /^[0-9]+$/;
    return phoneRegex.test(phone) && phone.length >= 7;
  }

  function getPhoneNumberHelperText(phone) {
    const phoneRegex = /^[0-9]+$/;
    if (!phone) {
      return 'Phone is required';
    } else if (!phoneRegex.test(phone)) {
      return t('VALIDATION_INVALID_NUMBER');
    } else if (phone.length < 7) {
      return t('VALIDATION_MUST_BE_AT_LEAST_7_DIGITS');
    }
    return '';
  }

  const paymentInformationFieldsSchema = Yup.object().shape({
    billingAddress: Yup.string().required(t('VALIDATION_REQUIRED')),
    city: Yup.string().required(t('VALIDATION_REQUIRED')),
    state: Yup.string().required(t('VALIDATION_REQUIRED')),
    zipCode: Yup.string().required(t('VALIDATION_REQUIRED')),
  });

  useEffect(() => {
    getRecurlyPlans({
      ACCOUNT_TYPE: 'B2B',
      PURCHASING_CHANNEL: 'COC',
    })
      .then((response) => {
        retrieveRecurlyPlans(response);
      })
      .catch((error) => {
        console.error('Error fetching Recurly plans:', error);
      });
  }, []);

  useEffect(() => {
    const validateFields = async () => {
      try {
        await baseFieldsSchema.validate(values, { abortEarly: false });
        if (showPaymentInformationSection) {
          await paymentInformationFieldsSchema.validate(values, {
            abortEarly: false,
          });
        }
        if (showRecurlyAccountIDTextField) {
          if (!values.recurlyAccountID) {
            throw new Error('Recurly Account ID is required');
          }
        }
        if (showUserSegmentField) {
          if (!values.userSegment) {
            throw new Error('User Segment is required');
          }
        }
        setIsValid(true);
      } catch (err) {
        setIsValid(false);
      }
    };

    validateFields();
  }, [values, showPaymentInformationSection]);

  function retrieveRecurlyPlans(array) {
    const sortObjectsByNumericalValue = (arrayToSort, key) => {
      return arrayToSort.sort((a, b) => a[key] - b[key]);
    };

    if (!array || !array.data || !array.data.getRecurlyPlans) {
      return;
    }

    const recurlyPlans = array.data.getRecurlyPlans;
    const newRecurlyPlanMap = {};
    let newPlanRadioOptions = [];
    let newFreeTrialRadioOptions = [];
    const newBetaRadioOptions = [];
    for (let i = 0; i < recurlyPlans.length; i += 1) {
      newRecurlyPlanMap[recurlyPlans[i].recurlyPlanCode] = recurlyPlans[i];

      const recurlyPlanOption = {
        label: recurlyPlans[i].recurlyPlanCodeLabel,
        value: recurlyPlans[i].recurlyPlanCode,
        displayOrder: recurlyPlans[i].displayOrder,
      };

      if (recurlyPlans[i].legacyPlanCode) {
        if (recurlyPlans[i].lengthOfTrialInDays > 0) {
          newFreeTrialRadioOptions.push(recurlyPlanOption);
        } else {
          newPlanRadioOptions.push(recurlyPlanOption);
        }
      } else {
        newBetaRadioOptions.push(recurlyPlanOption);
      }
    }
    newPlanRadioOptions = sortObjectsByNumericalValue(
      newPlanRadioOptions,
      'displayOrder'
    );
    newFreeTrialRadioOptions = sortObjectsByNumericalValue(
      newFreeTrialRadioOptions,
      'displayOrder'
    );
    setBetaRadioOptions(newBetaRadioOptions);
    setPlanRadioOptions(newPlanRadioOptions);
    setFreeTrialRadioOptions(newFreeTrialRadioOptions);
    setRecurlyPlanMap(newRecurlyPlanMap);
    setIsLoading(false);
  }

  function changeSelectedPlanCode(newRecurlyPlanCode) {
    const { recurlyAction, isPrivate, boardSeatsAllowedCount } =
      recurlyPlanMap[newRecurlyPlanCode];
    setSelectedPlanCode(newRecurlyPlanCode);

    if (recurlyAction === 'DO_NOTHING' || recurlyAction === 'NOTHING') {
      setShowRecurlyAccountIDTextField(true);
      setShowUserSegmentField(true);
      setShowPaymentInformationSection(false);
      setCardDataNeeded(false);
      // setInitialRecurlyAccountID(values.recurlyAccountID || '');
    } else {
      setShowRecurlyAccountIDTextField(false);
      setShowUserSegmentField(false);
      setShowPaymentInformationSection(true);
      setCardDataNeeded(true);
      // setInitialRecurlyAccountID('');
    }

    if (Number(boardSeatsAllowedCount)) {
      setBoardSeatsAllowedCount(boardSeatsAllowedCount);
    }

    const newBoardTypeOptions = [{ label: 'Public', value: 'public' }];

    if (isPrivate) {
      newBoardTypeOptions.push({ label: 'Private', value: 'private' });
    }
  }

  function AdminSignUpCall(recurlyToken) {
    setProcessingData(true);

    adminSignUp({
      USER_SEGMENT: values.userSegment || '',
      BOARD_SEATS_ALLOWED_COUNT: boardSeatsAllowedCount,
      COMPANY_NAME: values.companyName,
      EMAIL: values.emailAddress,
      GIVEN_NAME: values.firstName,
      ONBOARDING_EXPERIENCE_UPSELL: values.purchasePOE,
      PHONE_COUNTRY_CODE: values.countryCode,
      PHONE_NUMBER: values.phone,
      POSTAL_CODE: values.zipCode,
      RECURLY_ACCOUNT_CODE: values.recurlyAccountID,
      RECURLY_BILLING_TOKEN: recurlyToken,
      RECURLY_PLAN_CODE: values.planCode,
      SURNAME: values.lastName,
      URL_OF_CHECKOUT_PAGE: window.location.hostname + window.location.pathname,
    })
      .then(() => {
        history.push('/dashboard', {
          userCreated: true,
        });
      })
      .catch((error) => {
        let jsonError;
        if (error.errors && error.errors[0] && error.errors[0].message) {
          jsonError = JSON.parse(error.errors[0].message);
        } else {
          setSnackbarMessage('Error');
        }

        if (
          jsonError.code === 'SignUpError' &&
          jsonError.error.code === 'RecurlySubscriptionError' &&
          jsonError.error.error.name === 'RecurlyTransactionError'
        ) {
          setSnackbarMessage(jsonError.error.error.transactionError.message);
        } else if (
          jsonError &&
          jsonError.error &&
          jsonError.error.error &&
          jsonError.error.error.message
        ) {
          setSnackbarMessage(jsonError.error.error.message);
        } else if (jsonError && jsonError.error && jsonError.error.error) {
          setSnackbarMessage(jsonError.error.error);
        } else {
          setSnackbarMessage('Error');
        }
        setShowSnackbar(true);
      })
      .finally(() => {
        setProcessingData(false);
      });
  }

  function handleCardDataChange(e) {
    setCardDataReady(e.valid);
  }

  const handleSubmit = (event) => {
    setProcessingData(true);
    if (event.preventDefault) event.preventDefault();

    if (!showPaymentInformationSection) {
      AdminSignUpCall();
      return;
    }

    recurly.token(formRef.current, (err, token) => {
      if (err) {
        setSnackbarMessage(err.message);
        setShowSnackbar(true);
        setProcessingData(false);
      } else {
        const recurlyToken = token.id;

        AdminSignUpCall(recurlyToken);
      }
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  return (
    <>
      <Helmet>
        <title>{t('COMPANY_CHECKOUT_HELMET_TITLE')}</title>
      </Helmet>
      <div style={{ width: '100%' }}>
        <Snackbar
          open={showSnackbar}
          text={snackbarMessage}
          severity="error"
          onClose={() => setShowSnackbar(false)}
          autoHideDuration={6000}
        />
        <form ref={formRef}>
          <Paper sx={classes.mainContainer}>
            <RadioGroup
              className={classes.addMargin}
              id="planCode1"
              labelText={t('FIELD_PLAN')}
              radioOptions={planRadioOptions}
              value={selectedPlanCode}
              onChange={(event) => {
                changeSelectedPlanCode(event.target.value);
                location.state = { formSchemaChange: true };
                setValues({
                  ...values,
                  planCode: event.target.value,
                });
              }}
              errorText={selectedPlanCode ? '' : t('VALIDATION_SELECT_PLAN')}
            />
            {freeTrialRadioOptions.length > 0 && (
              <RadioGroup
                className={classes.addMargin}
                id="planCode2"
                labelText={t('FIELD_FREE_TRIAL')}
                radioOptions={freeTrialRadioOptions}
                value={selectedPlanCode}
                onChange={(event) => {
                  changeSelectedPlanCode(event.target.value);
                  location.state = { formSchemaChange: true };
                  setValues({
                    ...values,
                    planCode: event.target.value,
                  });
                }}
                errorText={selectedPlanCode ? '' : t('VALIDATION_SELECT_PLAN')}
              />
            )}
            {betaRadioOptions.length > 0 && (
              <RadioGroup
                className={classes.addMargin}
                id="planCode3"
                labelText={t('FIELD_BETA_OPTIONS')}
                radioOptions={betaRadioOptions}
                value={selectedPlanCode}
                onChange={(event) => {
                  changeSelectedPlanCode(event.target.value);
                  setValues({
                    ...values,
                    planCode: event.target.value,
                  });
                }}
                errorText={selectedPlanCode ? '' : t('VALIDATION_SELECT_PLAN')}
              />
            )}
            <Divider sx={classes.divider} />

            <Grid container spacing={1}>
              {showRecurlyAccountIDTextField && (
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Recurly Account ID"
                    name="recurlyAccountID"
                    value={values.recurlyAccountID}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    inputProps={{
                      'data-cy': 'txtRecurlyAccountID',
                    }}
                    error={
                      showRecurlyAccountIDTextField && !values.recurlyAccountID
                    }
                    helperText={
                      showRecurlyAccountIDTextField &&
                      !values.recurlyAccountID &&
                      'Recurly Account ID is required'
                    }
                  />
                </Grid>
              )}
              {showUserSegmentField && (
                <Grid item xs={12} sm={6}>
                  <InputLabel id="userSegment-label">User Segment</InputLabel>
                  <Select
                    labelId="userSegment-label"
                    id="userSegment"
                    name="userSegment"
                    value={values.userSegment}
                    onChange={handleInputChange}
                    label="User Segment"
                    fullWidth
                    margin="normal"
                    error={!values.userSegment}
                    helperText={!values.userSegment && 'User Segment is required'}
                  >
                    <MenuItem value="start_your_advisorycloud">
                      start_your_advisorycloud
                    </MenuItem>
                    <MenuItem value="managed_advisory_board">
                      managed_advisory_board
                    </MenuItem>
                    <MenuItem value="insights">insights</MenuItem>
                    <MenuItem value="bring_your_own">bring_your_own</MenuItem>
                    <MenuItem value="None">None</MenuItem>
                  </Select>
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="First Name"
                  name="firstName"
                  value={values.firstName}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                  error={!values.firstName}
                  helperText={!values.firstName && 'First name is required'}
                  inputProps={{
                    'data-recurly': 'first_name',
                    'data-cy': 'txtFirstName',
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Last Name"
                  name="lastName"
                  value={values.lastName}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                  error={!values.lastName}
                  helperText={!values.lastName && 'Last name is required'}
                  inputProps={{
                    'data-recurly': 'last_name',
                    'data-cy': 'txtLastName',
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  label="Email Address"
                  name="emailAddress"
                  value={values.emailAddress}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                  error={!values.emailAddress || !isValidEmail(values.emailAddress)}
                  helperText={getEmailHelperText(values.emailAddress)}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  label="Phone"
                  name="phone"
                  value={values.phone}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                  error={!values.phone || !isValidPhoneNumber(values.phone)}
                  helperText={getPhoneNumberHelperText(values.phone)}
                  inputProps={{
                    'data-recurly': 'phone',
                    'data-cy': 'txtPhone',
                    maxLength: '12',
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  label="Company Name"
                  name="companyName"
                  value={values.companyName}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                  error={!values.companyName}
                  helperText={!values.companyName && 'Company name is required'}
                />
              </Grid>
            </Grid>
            <CountryDropdown
              labelText="Country"
              value={values.countryCode}
              // onChange={handleInputChange}
              onChange={(_, value) => {
                if (value === null) {
                  handleInputChange({
                    target: { name: 'countryCode', value: 'US' },
                  });
                } else {
                  handleInputChange({
                    target: { name: 'countryCode', value: value.code },
                  });
                }
              }}
              fullWidth
              excludeNullCountryOption
            />

            {showPaymentInformationSection && (
              <>
                <Divider sx={classes.divider} />
                <Typography sx={classes.title} variant="subtitle1">
                  {t('SECTION_TITLE_PAYMENT_INFORMATION')}
                </Typography>
                <div style={classes.cardElementContainer}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <TextField
                        label={t('FIELD_BILLING_ADDRESS')}
                        name="billingAddress"
                        id="billingAddress"
                        inputProps={{
                          'data-recurly': 'address1',
                          'data-cy': 'txtbillingAddress',
                        }}
                        value={values.billingAddress}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                        error={!values.billingAddress}
                        helperText={
                          !values.billingAddress && 'Billing address is required'
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        label={t('FIELD_CITY')}
                        name="city"
                        id="city"
                        inputProps={{
                          'data-recurly': 'city',
                          'data-cy': 'txtCity',
                        }}
                        value={values.city}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                        error={!values.city}
                        helperText={!values.city && 'City is required'}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        label={t('FIELD_STATE')}
                        name="state"
                        id="state"
                        inputProps={{
                          'data-recurly': 'state',
                          'data-cy': 'txtState',
                        }}
                        value={values.state}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                        error={!values.state}
                        helperText={!values.state && 'State is required'}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        label={t('FIELD_ZIP_CODE')}
                        name="zipCode"
                        id="zipCode"
                        inputProps={{
                          'data-recurly': 'postal_code',
                          'data-cy': 'txtZipCode',
                        }}
                        value={values.zipCode}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                        error={!values.zipCode}
                        helperText={!values.zipCode && 'Zip code is required'}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CardElement
                        onSubmit={handleSubmit}
                        onChange={handleCardDataChange}
                      />
                    </Grid>
                  </Grid>
                  <Divider sx={classes.divider} />
                </div>
              </>
            )}
            <Button
              color="primary"
              disabled={
                !isValid || (!cardDataReady && cardDataNeeded) || processingData
              }
              onClick={(event) => {
                handleSubmit(event);
              }}
              loading={processingData ? 1 : 0}
            >
              {t('CHECKOUT_BUTTON_TEXT')}
            </Button>
          </Paper>
        </form>
      </div>
    </>
  );
}

function CompanyCheckoutWithRecurly() {
  return (
    <RecurlyProvider publicKey={process.env.REACT_APP_RECURLY_PUBLIC_KEY}>
      <Elements>
        <CompanyCheckout />
      </Elements>
    </RecurlyProvider>
  );
}

export default CompanyCheckoutWithRecurly;
