import React from 'react';
import { useTranslation } from 'react-i18next';
import InputRow, { InputType } from '../../Components/InputRow';
import CountryDropdown from '../../Components/CountryDropdown';

function UserInformation(props) {
  const { values, errors, handleBlur, handleChange, setFieldValue } = props;
  const { t } = useTranslation();

  const inputRow1Items = [
    {
      inputtype: InputType.TEXT_FIELD,
      labelText: t('FIELD_FIRST_NAME'),
      id: 'firstName',
      inputProps: {
        'data-recurly': 'first_name',
        'data-cy': 'txtFirstName',
      },
      value: values.firstName,
      errorText: errors.firstName,
      onBlur: handleBlur,
      onChange: handleChange,
    },
    {
      inputtype: InputType.TEXT_FIELD,
      labelText: t('FIELD_LAST_NAME'),
      id: 'lastName',
      inputProps: {
        'data-recurly': 'last_name',
        'data-cy': 'txtLastName',
      },
      value: values.lastName,
      errorText: errors.lastName,
      onBlur: handleBlur,
      onChange: handleChange,
    },
  ];

  const inputRow2Items = [
    {
      inputtype: InputType.TEXT_FIELD,
      labelText: t('FIELD_EMAIL_ADDRESS'),
      id: 'emailAddress',
      inputProps: {
        'data-cy': 'txtEmailAddress',
      },
      value: values.emailAddress,
      errorText: errors.emailAddress,
      onBlur: handleBlur,
      onChange: handleChange,
    },
  ];

  const inputRow3Items = [
    {
      inputtype: InputType.TEXT_FIELD,
      labelText: t('FIELD_PHONE'),
      id: 'phone',
      inputProps: {
        'data-recurly': 'phone',
        'data-cy': 'txtPhone',
        maxLength: '12',
      },
      value: values.phone,
      errorText: errors.phone,
      onBlur: handleBlur,
      onChange: handleChange,
    },
  ];

  const inputRow4Items = [
    {
      inputtype: InputType.TEXT_FIELD,
      labelText: t('FIELD_COMPANY_NAME'),
      id: 'companyName',
      inputProps: {
        'data-cy': 'txtCompanyName',
      },
      value: values.companyName,
      errorText: errors.companyName,
      onBlur: handleBlur,
      onChange: handleChange,
    },
  ];

  return (
    <>
      <InputRow rowInputs={inputRow1Items} />
      <InputRow rowInputs={inputRow2Items} />
      <InputRow rowInputs={inputRow3Items} />
      <InputRow rowInputs={inputRow4Items} />
      <CountryDropdown
        labelText={t('FIELD_COUNTRY')}
        onBlur={handleBlur}
        onChange={(_, value) => {
          if (value === null) {
            setFieldValue('countryCode', 'US');
          } else {
            setFieldValue('countryCode', value.code);
          }
        }}
        value={values.countryCode}
        excludeNullCountryOption
      />
    </>
  );
}

export default UserInformation;
