import React, { useState } from 'react';
import { Menu, MenuItem, Fade, Backdrop, Typography } from '@mui/material';
import { LetterAvatar } from '@advisorycloud/react-ui-components';

const useStyles = () => ({
  backDrop: {
    zIndex: 999,
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  menuItemText: {
    fontWeight: 400,
  },
  menu: {
    marginTop: '7px',
  },
});

function VerticalElipsiesMenu(props) {
  const { logoutClick } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const Default = () => {
    return (
      <>
        <MenuItem onClick={() => logoutClick()}>
          <div style={classes.menuItem}>
            <Typography
              variant="caption"
              color="error"
              sx={classes.menuItemText}
            >
              Logout
            </Typography>
          </div>
        </MenuItem>
      </>
    );
  };

  function renderContentMenu() {
    return <Default />;
  }

  return (
    <div>
      <LetterAvatar
        firstName="Advisory"
        lastName="Cloud"
        size="small"
        onClick={(evt) => handleClick(evt)}
      />
      <Backdrop sx={classes.backDrop} open={open} onClick={handleClose}>
        <Menu
          sx={classes.menu}
          id="fade-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
          // getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <div>{renderContentMenu()}</div>
        </Menu>
      </Backdrop>
    </div>
  );
}

export default VerticalElipsiesMenu;
