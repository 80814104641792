import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Snackbar, AdminConsoleTile } from '@advisorycloud/react-ui-components';
import { useAccount } from '../../contexts/Account';
import { cognitoGroupType } from '../../services/utils/cognitoGroupsTypes';

const useStyles = () => ({
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingLeft: '180px',
    paddingRight: '180px',
    paddingTop: '25px',
  },
  subContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '25px',
  },
});

function Dashboard() {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const { cognitoGroups } = useAccount();
  console.log({ cognitoGroups });

  useEffect(() => {
    if (location.state && location.state.userCreated) {
      setShowSnackbar(true);
    }
  }, []);

  function companyCheckoutClick() {
    history.replace();
    history.push('/checkout/company');
  }
  function advisorCheckoutClick() {
    history.replace();
    history.push('/checkout/advisor');
  }
  function masterCheckoutClick() {
    history.replace();
    history.push('/checkout/master');
  }
  function planMappingClick() {
    history.replace();
    history.push('/plan-mapping-table');
  }
  function insightsClick() {
    history.push('/insights');
  }
  function adminsClick() {
    history.push('/admins-managment');
  }

  return (
    <>
      <Helmet>
        <title>{t('DASHBOARD-HELMET-TITLE')}</title>
      </Helmet>
      <Snackbar
        open={showSnackbar}
        text={t('USER_CREATED_SUCCESSFULLY')}
        severity="success"
        onClose={() => {
          history.replace();
          setShowSnackbar(false);
        }}
        autoHideDuration={6000}
      />
      <div style={classes.container}>
        <div style={classes.subContainer}>
          {(cognitoGroups.includes(cognitoGroupType.SUPER_ADMIN) ||
            cognitoGroups.includes(cognitoGroupType.BUSINESS_CONSULTANT) ||
            cognitoGroups.includes(cognitoGroupType.MANAGER)) && (
            <AdminConsoleTile
              type="companyCheckout"
              title={t('COMPANY-CHECKOUT-TILE')}
              onClick={() => companyCheckoutClick()}
            />
          )}

          {(cognitoGroups.includes(cognitoGroupType.SUPER_ADMIN) ||
            cognitoGroups.includes(cognitoGroupType.ACCOUNT_EXECUTIVE) ||
            cognitoGroups.includes(cognitoGroupType.MANAGER)) && (
            <AdminConsoleTile
              type="advisorCheckout"
              title={t('ADVISOR-CHECKOUT-TILE')}
              onClick={() => advisorCheckoutClick()}
            />
          )}

          {/* {cognitoGroups.includes(cognitoGroupType.SUPER_ADMIN) && (
            <AdminConsoleTile
              type="masterCheckout"
              title={t('MASTER-CHECKOUT-TILE')}
              onClick={() => masterCheckoutClick()}
            />
          )}

          {(cognitoGroups.includes(cognitoGroupType.SUPER_ADMIN) ||
            cognitoGroups.includes(cognitoGroupType.IT)) && (
            <AdminConsoleTile
              type="manageEmployees"
              title={t('MANAGE-EMPLOYEES-TILE')}
              disabled
            />
          )} */}

          {cognitoGroups.includes(cognitoGroupType.SYSTEM_ADMIN) && (
            <AdminConsoleTile
              type="planMapping"
              title={t('PLAN-MAPPING-TILE')}
              onClick={() => planMappingClick()}
            />
          )}

          {cognitoGroups.includes(cognitoGroupType.SYSTEM_ADMIN) && (
            <AdminConsoleTile
              type="advisoryUpdates"
              title="Insights"
              onClick={insightsClick}
            />
          )}
        </div>
        <div style={classes.subContainer}>
          {cognitoGroups.includes(cognitoGroupType.SYSTEM_ADMIN) && (
            <AdminConsoleTile
              type="manageEmployees"
              title="Admin Managment"
              onClick={adminsClick}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default Dashboard;
