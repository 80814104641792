import React from 'react';
import { ComponentsProvider } from '@advisorycloud/react-ui-components';
import { useAccount } from '../../contexts/Account';
import { cognitoGroupType } from '../../services/utils/cognitoGroupsTypes';

function AccountValidator(props) {
  const {
    theme,
    component,
    isPublic,
    isAdvisorCheckout,
    isCompanyCheckout,
    onlySystemAdmin,
  } = props;
  const { isAuthenticated, cognitoGroups, authenticate } = useAccount();

  function environmentUrl() {
    if (process.env.NODE_ENV === 'development') {
      return 'https://app-dev.advisorycloud.com';
    }
    if (process.env.NODE_ENV === 'test') {
      return 'https://app-test.advisorycloud.com';
    }
    if (process.env.NODE_ENV === 'production') {
      return 'https://app.advisorycloud.com';
    }
    return null;
  }

  const Component = component;

  async function authenticateFromContext() {
    await authenticate();
  }

  if (!isPublic && !isAuthenticated) {
    authenticateFromContext();
    if (!isAuthenticated) {
      props.history.push('/login');
    }
  }

  if (cognitoGroups) {
    if (!cognitoGroups.includes('admin')) {
      const redirectUrl = environmentUrl();
      window.location.href = redirectUrl;
    }
  }

  if (
    isAdvisorCheckout &&
    cognitoGroups &&
    !cognitoGroups.includes(cognitoGroupType.SUPER_ADMIN) &&
    !cognitoGroups.includes(cognitoGroupType.ACCOUNT_EXECUTIVE)
  ) {
    props.history.push('/dashboard');
  }

  if (
    isCompanyCheckout &&
    cognitoGroups &&
    !cognitoGroups.includes(cognitoGroupType.SUPER_ADMIN) &&
    !cognitoGroups.includes(cognitoGroupType.BUSINESS_CONSULTANT)
  ) {
    props.history.push('/dashboard');
  }

  if (
    onlySystemAdmin &&
    cognitoGroups &&
    !cognitoGroups.includes(cognitoGroupType.SYSTEM_ADMIN)
  ) {
    props.history.push('/dashboard');
  }

  return (
    <ComponentsProvider theme={theme}>
      <Component {...props} />
    </ComponentsProvider>
  );
}

export default AccountValidator;
