import React, { useRef } from 'react';
import { Card, Grid, Link, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AdvisoryCloudLogo } from '@advisorycloud/react-ui-components';
import { withRouter } from 'react-router';
import FormLogin from '../../../components/LoginForm';
import ModalResetPassword from '../../../components/ModalForgotPassword';

const useStyles = () => ({
  container: {
    display: 'flex',
    height: 'fit-content',
    justifyContent: 'center',
    alignItems: 'center',
  },
  gridContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'fit-content',
  },
  loginText: {
    color: 'primary.dark',
    fontSize: '1.825rem',
  },
  legendText: {
    color: 'secondary.dark',
    marginTop: '1.5vh',
  },
  linkContainer: {
    marginTop: '.5vh',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
});

function Clogin() {
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const triggerButton = useRef(null);
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email');

  function handleClick(value) {
    if (value === undefined) {
      setOpen(!open);
    } else {
      setOpen(value);
    }
  }

  return (
    <div style={classes.container}>
      <Grid
        container
        spacing={0}
        justifyContent="flex-start"
        sx={classes.gridContainer}
      >
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '50%',
            backgroundColor: 'transparent',
            boxShadow: 'none',
          }}
        >
          <Grid container justify="flex-start" direction="column">
            <Grid item>
              <AdvisoryCloudLogo width="66%" />
            </Grid>
            <Grid item>
              <Typography sx={classes.loginText} variant="h1">
                {t('LOGIN_TITLE')}
              </Typography>
              <Typography variant="h3" sx={classes.legendText}>
                {t('LOGIN_TEXT')}
              </Typography>
              <ModalResetPassword
                prePopulatedEmail={email}
                open={open}
                onChange={handleClick}
              />
            </Grid>
            <Grid item>
              <FormLogin />
            </Grid>
            <Grid item>
              <Typography variant="h3" sx={classes.linkContainer}>
                <Link
                  ref={triggerButton}
                  component="button"
                  color="primary"
                  underline="always"
                  variant="h3"
                  onClick={() => handleClick(true)}
                >
                  {t('LOGIN_LOST_PASWORD')}
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </div>
  );
}

export default withRouter(Clogin);
