function getCookie(cname) {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

class Storage {
  constructor() {
    this.syncPromise = null;
  }

  static setItem(key, value) {
    localStorage.setItem(key, value);
    return localStorage.getItem(key);
  }

  static getItem(key) {
    return localStorage.getItem(key);
  }

  static removeItem(key) {
    return localStorage.removeItem(key);
  }

  static clear() {
    localStorage.clear();
    return null;
  }

  static sync() {
    if (!Storage.syncPromise) {
      Storage.syncPromise = new Promise((res, rej) => {
        try {
          res();
        } catch (error) {
          rej(error);
        }
      });
    }
    return Storage.syncPromise;
  }
}

export default Storage;
