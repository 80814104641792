import React, { useState, useEffect } from 'react';
import { Button, Avatar, Snackbar } from '@advisorycloud/react-ui-components';
import {
  Autocomplete,
  useMediaQuery,
  Paper,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox as MaterialCheckbox,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import CloseIcon from '@mui/icons-material/Close';
import { useAccount } from '../../contexts/Account';
import {
  BoardType,
  ResponseStatus,
  opportunityStageTypes,
} from '../../services/utils/types';
import Input from '../Input';
import {
  updateCompanyNameCall,
  updateCompanyBoardSeatsCall,
  updateCompanyIsPublicCall,
  updateBoardTypeCall,
  updateCompanyPlanCall,
  updateCompanyOnboardingStatusCall,
  updateCompanyUpcomingMeetingCall,
  updateCompanyInstantJoinEnabledCall,
  updateCompanyComplimentaryBoardPositionCall,
  removeCompanyUpcomingMeetingCall,
  updateCompanyStageCall,
} from '../../services/company_services';
import { updateAdvisorProfileData } from '../../services/advisor_services';
import { updateCompanyFreeTrialEndsOn } from '../../services/admin_services';

const useStyles = () => ({
  card: {
    backgroundColor: 'white',
    padding: '25px',
    position: 'relative',
  },
  companyWebsiteContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '175px',
    height: 'fit-content',
  },
  avatarContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 'fit-content',
    height: 'fit-content',
    marginRight: '20px',
  },
  continueButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: '15px',
  },
  dividerMargin: {
    marginTop: '5px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
  },
  calendarRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  phoneContainer: {
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: (isXS) => (isXS ? '0' : '5%'),
  },
  phoneInfoContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: (isXS) => (isXS ? 'column' : 'row'),
  },
  phoneCountryCodeContainer: {
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
  },
  labelSeparator: {
    marginBottom: '10px',
  },
  autocomplete: {
    width: '100%',
    height: '30px',
  },
  errorSeparatorCode: {
    marginTop: '-50px',
  },
  errorSeparatorPhone: {
    marginTop: '-50px',
  },
  errorSeparatorCodePlans: {
    fontSize: '0.8rem',
  },
  root: {
    height: '30px',
    paddingTop: '0px',
  },
  inputRoot: {
    marginTop: '-5px',
    textTransform: 'capitalize',
  },
  inputRootCode: {
    marginTop: '-3px',
    textTransform: 'uppercase',
    fontSize: '0.75rem',
  },
  companyName: {
    color: 'secondary.dark',
  },
  subTitleStyle: {
    marginTop: '10px',
    color: 'secondary.dark',
  },
  closeIcon: {
    fontSize: '19px',
    color: 'secondary.dark',
  },
  closeButton: {
    backgroundColor: 'transparent',
    outline: 'none',
    width: '23px',
    height: '23px',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    top: '0px',
    right: '0px',
    cursor: 'pointer',
    '&:disabled': {
      cursor: 'default',
    },
    borderRadius: '50%',
    border: 'none',
  },
  planCodeErrorContainer: {
    border: '1px solid rgba(0, 0, 0, 0.26)',
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '5px',
  },
});

const EditCompanyModal = (props) => {
  const {
    companyName,
    boardType,
    companyBoardType,
    recurlyPlanCode,
    boardSeats,
    opportunityStage,
    closeModal,
    avatarImage,
    showSnackbar,
    boardOwnerInfo,
    companyId,
    planOptionsArray,
    // temporal value
    onboardingStatus,
    upcomingBoardMeeting,
    instantJoinEnabled,
    complimentaryBoardPosition,
    freeTrialEndsOn,
  } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [planOptions, setPlanOptions] = useState();
  const [loadingPlans, setLoadingPlans] = useState(true);
  const classes = useStyles(isSM);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const [errFetchPlanOptions, setErrFetchPlanOptions] = useState(false);
  const [isUnexistingPlan, setIsUnexistingPlan] = useState(false);
  const [userForm, setUserForm] = useState({
    freeTrialEndsOn: freeTrialEndsOn ? freeTrialEndsOn.split('T')[0] : null,
  });
  const boardTypeArray = [
    { Type: BoardType.PUBLIC },
    { Type: BoardType.PRIVATE },
  ];
  const boardStageArray = [
    { Type: opportunityStageTypes.DISCOVERY, label: 'Discovery' },
    { Type: opportunityStageTypes.SEEKING_ADVISORS, label: 'Seeking Advisors' },
    { Type: opportunityStageTypes.POSITION_FILLED, label: 'Position Filled' },
    { Type: opportunityStageTypes.DORMANT, label: 'Dormant' },
    {
      Type: opportunityStageTypes.PENDING_CANCELLATION,
      label: 'Pending Cancellation',
    },
  ];
  const changeMonitor = [];
  if (companyBoardType) {
    console.log(companyBoardType);
  }
  // temporal from here
  const { cognitoGroups } = useAccount();
  const onboardingStatusArray = [
    { Type: 'Not Scheduled' },
    { Type: 'Complete' },
    { Type: 'Scheduled' },
    { Type: 'Not Requisite' },
    { Type: 'Missed' },
    { Type: 'Canceled' },
    { Type: 'Requested to Reschedule' },
  ];
  const isSuperAdmin = cognitoGroups.includes('super_admin');
  // to here

  function submitChanges(data) {
    setIsLoading(true);
    async function changeCompanyName() {
      try {
        await updateCompanyNameCall({
          CONTACT_ID: boardOwnerInfo.id,
          NEW_COMPANY_NAME: data.yourCompanyName,
        });
      } catch (err) {
        throw new Error(err);
      }
    }
    async function changeBoardTypeName() {
      try {
        await updateBoardTypeCall({
          CONTACT_ID: boardOwnerInfo.id,
          BOARD_TYPE: data.yourCompanyBoardType,
        });
      } catch (err) {
        throw new Error(err);
      }
    }

    async function changeBoardroomSeats() {
      try {
        await updateCompanyBoardSeatsCall({
          COMPANY_ID: companyId,
          BOARD_SEATS: data.yourBoardSeats,
        });
      } catch (err) {
        throw new Error(err);
      }
    }

    async function updateCompanyBoardType() {
      try {
        await updateCompanyIsPublicCall({
          COMPANY_ID: companyId,
          BOARD_TYPE: data.yourBoardType === BoardType.PUBLIC,
        });
      } catch (err) {
        throw new Error(err);
      }
    }

    async function updateCompanyPlanCode() {
      try {
        await updateCompanyPlanCall({
          COMPANY_ID: companyId,
          PLAN_CODE: data.yourPlanCode,
        });
      } catch (err) {
        throw new Error(err);
      }
    }

    // temporal function
    async function updateCompanyOnboardingStatus() {
      try {
        await updateCompanyOnboardingStatusCall({
          COMPANY_ID: companyId,
          ONBOARDING_STATUS: data.yourOnboardingStatus,
        });
      } catch (err) {
        throw new Error(err);
      }
    }

    async function updateCompanyUpcomingMeeting() {
      // modify
      try {
        await updateCompanyUpcomingMeetingCall({
          CONTACT_ID: boardOwnerInfo.id,
          DATE: data.yourUpcomingMeeting,
        });
      } catch (err) {
        throw new Error(err);
      }
    }

    async function updateCompanyInstantJoinEnabled() {
      try {
        await updateCompanyInstantJoinEnabledCall({
          COMPANY_ID: companyId,
          INSTANT_JOIN_ENABLED: data.yourInstantJoinEnabled,
        });
      } catch (err) {
        throw new Error(err);
      }
    }

    async function updateCompanyComplimentaryBoardPosition() {
      try {
        await updateCompanyComplimentaryBoardPositionCall({
          COMPANY_ID: companyId,
          COMPLIMENTARY_BOARD_POSITION: data.yourComplimentaryBoardPosition,
        });
      } catch (err) {
        throw new Error(err);
      }
    }

    async function removeCompanyUpcomingMeeting() {
      // modify
      try {
        await removeCompanyUpcomingMeetingCall({
          CONTACT_ID: boardOwnerInfo.id,
        });
      } catch (err) {
        throw new Error(err);
      }
    }

    async function updateOpportunityStage() {
      try {
        await updateCompanyStageCall({
          COMPANY_ID: companyId,
          OPPORTUNITY_STAGE: data.yourStage,
        });
      } catch (err) {
        throw new Error(err);
      }
    }
    async function UpdatefreeTrialEndsOn() {
      const payload = {
        companyId: companyId,
      };

      if (userForm.freeTrialEndsOn === '') {
        payload.freeTrialEndsOn = null;
      } else if (userForm.freeTrialEndsOn) {
        payload.freeTrialEndsOn = new Date(
          userForm.freeTrialEndsOn
        ).toISOString();
      }

      try {
        await updateCompanyFreeTrialEndsOn(payload);
      } catch (error) {
        console.error('Error updating free trial end date:', error);
        throw new Error(error);
      }
    }

    if (data.yourCompanyName !== companyName) {
      changeMonitor.push(changeCompanyName);
    }
    if (data.yourBoardSeats !== boardSeats) {
      changeMonitor.push(changeBoardroomSeats);
    }
    if (data.yourBoardType !== boardType) {
      changeMonitor.push(updateCompanyBoardType);
    }
    if (data.yourCompanyBoardType !== companyBoardType) {
      changeMonitor.push(changeBoardTypeName);
    }
    if (data.yourPlanCode !== recurlyPlanCode) {
      changeMonitor.push(updateCompanyPlanCode);
    }
    // temporal if
    if (data.yourOnboardingStatus !== onboardingStatus && isSuperAdmin) {
      changeMonitor.push(updateCompanyOnboardingStatus);
    }
    if (data.upcomingBoardMeeting !== upcomingBoardMeeting) {
      if (data.yourUpcomingMeeting) {
        changeMonitor.push(updateCompanyUpcomingMeeting);
      } else {
        changeMonitor.push(removeCompanyUpcomingMeeting);
      }
    }

    if (data.yourInstantJoinEnabled !== instantJoinEnabled) {
      changeMonitor.push(updateCompanyInstantJoinEnabled);
    }

    if (data.yourComplimentaryBoardPosition !== complimentaryBoardPosition) {
      changeMonitor.push(updateCompanyComplimentaryBoardPosition);
    }

    if (data.yourStage !== opportunityStage) {
      changeMonitor.push(updateOpportunityStage);
    }
    //Update Free Trial End Ons
    changeMonitor.push(UpdatefreeTrialEndsOn);

    const uniqueArray = [...new Set(changeMonitor)];
    const promiseArray = uniqueArray.map((element) => {
      return element();
    });

    if (uniqueArray.length === 0) {
      closeModal();
    } else {
      changeMonitor.length = 0;
      Promise.all(promiseArray)
        .then(() => {
          setIsLoading(false);
          showSnackbar(ResponseStatus.SUCCESS);
          closeModal();
        })
        .catch(() => {
          setIsLoading(false);
          showSnackbar(ResponseStatus.FAIL);
        });
    }
  }

  const Step2Schema = Yup.object().shape({
    yourCompanyName: Yup.string()
      .typeError(t('EDITMODAL-NUM-REQUIRED'))
      .required(t('EDITMODAL-NUM-REQUIRED')),
    yourBoardType: Yup.string()
      .typeError(t('EDITMODAL-NUM-REQUIRED'))
      .required(t('EDITMODAL-NUM-REQUIRED')),
    yourPlanCode: Yup.string()
      .typeError(t('EDITMODAL-NUM-REQUIRED'))
      .required(t('EDITMODAL-NUM-REQUIRED')),
    yourStage: Yup.string(),
    yourBoardSeats: Yup.number()
      .typeError(t('EDITMODAL-NUM-TYPE'))
      .required(t('EDITMODAL-NUM-REQUIRED')),
  });

  const formInitialValues = {
    yourCompanyName: companyName,
    yourBoardType: boardType,
    yourPlanCode: recurlyPlanCode,
    yourBoardSeats: boardSeats,
    yourStage: opportunityStage,
    yourCompanyBoardType: companyBoardType,
    // temporal value
    yourOnboardingStatus: onboardingStatus,
    yourUpcomingMeeting: upcomingBoardMeeting,
    yourInstantJoinEnabled: instantJoinEnabled,
    yourComplimentaryBoardPosition: complimentaryBoardPosition,
  };

  function getPlans() {
    if (planOptionsArray && planOptionsArray.length > 0) {
      const plansArray = planOptionsArray;
      const plansArrayMap = plansArray.map((element) => {
        return {
          code: element.code,
          name: element.name,
        };
      });
      setPlanOptions(plansArrayMap);
      setLoadingPlans(false);
    } else {
      setLoadingPlans(false);
      setErrFetchPlanOptions(true);
    }
  }

  useEffect(() => {
    getPlans();
  }, [planOptionsArray]);

  function instantJoinEnabledCheckbox(values, setFieldValue) {
    return (
      <MaterialCheckbox
        sx={classes.formField}
        id="outlined-multiline-flexible"
        label="Instant Join Enabled"
        onChange={(event) => {
          setFieldValue('yourInstantJoinEnabled', event.target.checked);
        }}
        checked={values.yourInstantJoinEnabled}
      />
    );
  }
  function P2PCheckbox(values, setFieldValue) {
    const { yourCompanyBoardType } = values;
    return (
      <MaterialCheckbox
        sx={classes.formField}
        id="outlined-multiline-flexible"
        label="P2P"
        onChange={() => {
          setFieldValue('yourCompanyBoardType', 'P2P');
        }}
        checked={yourCompanyBoardType === 'P2P'}
      />
    );
  }
  function companyCheckbox(values, setFieldValue) {
    return (
      <MaterialCheckbox
        sx={classes.formField}
        id="outlined-multiline-flexible"
        label="Company"
        onChange={() => {
          setFieldValue('yourCompanyBoardType', 'Company');
        }}
        checked={values.yourCompanyBoardType === 'Company'}
      />
    );
  }

  function complimentaryBoardPositionCheckbox(values, setFieldValue) {
    return (
      <MaterialCheckbox
        sx={classes.formField}
        id="outlined-multiline-flexible"
        label="Complimentary Board Position"
        onChange={(event) => {
          setFieldValue('yourComplimentaryBoardPosition', event.target.checked);
        }}
        checked={values.yourComplimentaryBoardPosition}
      />
    );
  }

  const handleChangeFreeTrial = (event) => {
    const { name, value } = event.target;
    setUserForm({
      ...userForm,
      [name]: value,
    });
  };

  return (
    <>
      <Snackbar
        open={errFetchPlanOptions}
        text={t('EDITMODAL-COMPANY-ERROR-PLANS')}
        severity="error"
        onClose={() => setErrFetchPlanOptions(false)}
        autoHideDuration={6000}
      />
      <Formik
        initialValues={formInitialValues}
        validationSchema={Step2Schema}
        validateOnMount
        validateOnChange
        validateOnBlur
      >
        {(formik) => {
          const {
            handleChange,
            handleBlur,
            values,
            isValid,
            setFieldValue,
            errors,
          } = formik;

          return (
            <Paper elevation={3} sx={classes.card}>
              <button
                type="button"
                onClick={() => closeModal()}
                style={classes.closeButton}
              >
                <CloseIcon style={classes.closeIcon} />
              </button>
              <Form>
                <div style={classes.row}>
                  <div style={classes.avatarContainer}>
                    <Avatar
                      image={avatarImage}
                      size="adminUserCard"
                      borderColor="lightGrey"
                    />
                  </div>
                  <div style={classes.companyWebsiteContainer}>
                    <Typography variant="caption" sx={classes.companyName}>
                      {t('EDITMODAL-COMPANY-NAME')}
                    </Typography>

                    <div>
                      <Input
                        height={30}
                        fontSize="0.8rem"
                        style={classes.fieldContainer}
                        id="yourCompanyName"
                        inputProps={{
                          'data-cy': 'txtYourCompanyName',
                        }}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        size="small"
                        value={values.yourCompanyName}
                      />
                      {errors.yourCompanyName && (
                        <Typography
                          variant="caption"
                          color="error"
                          sx={classes.errorSeparatorPhone}
                        >
                          {errors.yourCompanyName}
                        </Typography>
                      )}
                    </div>
                  </div>
                </div>
                <Typography variant="caption" sx={classes.companyName}>
                  {t('EDITMODAL-NUM-BOARD-SEATS')}
                </Typography>
                <div>
                  <Input
                    height={30}
                    fontSize="0.8rem"
                    style={classes.fieldContainer}
                    id="yourBoardSeats"
                    inputProps={{
                      'data-cy': 'txtYourBoardSeats',
                    }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    size="small"
                    value={values.yourBoardSeats}
                  />
                  {errors.yourBoardSeats && (
                    <Typography
                      variant="caption"
                      color="error"
                      sx={classes.errorSeparatorPhone}
                    >
                      {errors.yourBoardSeats}
                    </Typography>
                  )}
                </div>
                {/* temporal from here */}
                {isSuperAdmin ? (
                  <div style={classes.dividerMargin}>
                    <Typography variant="caption" sx={classes.companyName}>
                      Onboarding Status
                    </Typography>
                    <Autocomplete
                      id="yourOnboardingStatus"
                      onBlur={handleBlur}
                      sx={classes.autocomplete}
                      options={onboardingStatusArray}
                      classes={{
                        option: classes.option,
                        inputRoot: classes.root,
                        input: classes.inputRoot,
                      }}
                      autoHighlight
                      disableClearable
                      getOptionLabel={(option) => {
                        return option.Type;
                      }}
                      renderOption={(props, option) => (
                        <span
                          {...props}
                          style={{ textTransform: 'capitalize' }}
                        >
                          {option.Type}
                        </span>
                      )}
                      defaultValue={onboardingStatusArray.find(
                        (element) =>
                          element.Type === values.yourOnboardingStatus
                      )}
                      value={
                        onboardingStatusArray.find(
                          (element) =>
                            element.Type === values.yourOnboardingStatus
                        ) || {}
                      }
                      onChange={(e, value) => {
                        setFieldValue('yourOnboardingStatus', value.Type);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          size="small"
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                      )}
                    />
                    {values.yourOnboardingStatus === null && (
                      <Typography
                        variant="caption"
                        color="error"
                        sx={classes.errorSeparatorCode}
                      >
                        {t('EDITMODAL-NUM-REQUIRED')}
                      </Typography>
                    )}
                  </div>
                ) : null}
                <div style={classes.dividerMargin}>
                  <Typography variant="caption" sx={classes.companyName}>
                    Stage
                  </Typography>
                  <Autocomplete
                    id="yourBoardStage"
                    onBlur={handleBlur}
                    sx={classes.autocomplete}
                    options={boardStageArray}
                    classes={{
                      option: classes.option,
                      inputRoot: classes.root,
                      input: classes.inputRoot,
                    }}
                    autoHighlight
                    disableClearable
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option) => (
                      <span {...props} style={{ textTransform: 'capitalize' }}>
                        {option.label}
                      </span>
                    )}
                    defaultValue={boardStageArray.find(
                      (element) => element.Type === values.yourStage
                    )}
                    value={
                      boardStageArray.find(
                        (element) => element.Type === values.yourStage
                      ) || {}
                    }
                    onChange={(e, value) => {
                      setFieldValue('yourStage', value.Type);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                </div>
                <div style={classes.dividerMargin}>
                  <Typography variant="caption" sx={classes.companyName}>
                    {t('EDITMODAL-BOARD-DYRECTORY-STATUS')}
                  </Typography>
                  <Autocomplete
                    id="yourBoardType"
                    onBlur={handleBlur}
                    sx={classes.autocomplete}
                    options={boardTypeArray}
                    classes={{
                      option: classes.option,
                      inputRoot: classes.root,
                      input: classes.inputRoot,
                    }}
                    autoHighlight
                    disableClearable
                    getOptionLabel={(option) => option.Type}
                    renderOption={(props, option) => (
                      <span {...props} style={{ textTransform: 'capitalize' }}>
                        {option.Type}
                      </span>
                    )}
                    defaultValue={boardTypeArray.find(
                      (element) => element.Type === values.yourBoardType
                    )}
                    value={
                      boardTypeArray.find(
                        (element) => element.Type === values.yourBoardType
                      ) || {}
                    }
                    onChange={(e, value) => {
                      setFieldValue('yourBoardType', value.Type);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                  {values.yourBoardType === null && (
                    <Typography
                      variant="caption"
                      color="error"
                      sx={classes.errorSeparatorCode}
                    >
                      {t('EDITMODAL-NUM-REQUIRED')}
                    </Typography>
                  )}
                </div>
                <div style={classes.dividerMargin}>
                  <Typography variant="caption" sx={classes.companyName}>
                    Plancode
                  </Typography>
                  {loadingPlans && !errFetchPlanOptions && (
                    <Button
                      disabled
                      style={{
                        height: '25px',
                        width: '100%',
                        backgroundColor: 'transparent',
                      }}
                      loading={loadingPlans ? 1 : 0}
                    />
                  )}

                  {!loadingPlans && errFetchPlanOptions && (
                    <div style={classes.dividerMargin}>
                      <div style={classes.planCodeErrorContainer}>
                        <div>
                          <Typography
                            variant="caption"
                            color="error"
                            sx={classes.errorSeparatorCodePlans}
                          >
                            {t('EDITMODAL-COMPANY-ERROR-PLANS')}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  )}

                  {!loadingPlans && !errFetchPlanOptions && (
                    <Autocomplete
                      id="yourPlanCode"
                      onBlur={handleBlur}
                      sx={classes.autocomplete}
                      options={planOptions}
                      classes={{
                        option: classes.option,
                        inputRoot: classes.root,
                        input: classes.inputRootCode,
                      }}
                      autoHighlight
                      getOptionLabel={(option) => {
                        return option.name;
                      }}
                      renderOption={(props, option) => {
                        return (
                          <span
                            {...props}
                            style={{ textTransform: 'uppercase' }}
                          >
                            {option.name}
                          </span>
                        );
                      }}
                      onChange={(e, value) => {
                        if (value === null || value === undefined) {
                          setFieldValue('yourPlanCode', recurlyPlanCode);
                        } else {
                          setFieldValue('yourPlanCode', value.code);
                        }
                      }}
                      defaultValue={() => {
                        if (!planOptions) {
                          return null;
                        }
                        const results = planOptions.find(
                          (element) => element.code === values.yourPlanCode
                        );
                        if (results === undefined) {
                          return setIsUnexistingPlan(true);
                        }
                        return results;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          size="small"
                          placeholder={
                            isUnexistingPlan ? 'Error. Select a plan' : null
                          }
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                      )}
                    />
                  )}

                  {values.yourPlanCode === null && (
                    <Typography
                      variant="caption"
                      color="error"
                      sx={classes.errorSeparatorCode}
                    >
                      {t('EDITMODAL-NUM-REQUIRED')}
                    </Typography>
                  )}
                </div>
                {values.yourPlanCode === undefined && (
                  <Typography
                    variant="caption"
                    color="error"
                    sx={classes.errorSeparatorCode}
                  >
                    {t('EDITMODAL-NUM-REQUIRED')}
                  </Typography>
                )}
                <div style={classes.dividerMargin}>
                  <Typography variant="caption" sx={classes.companyName}>
                    {t('EDITMODAL-UPCOMING-BOARD-MEETING')}
                  </Typography>
                  <div style={classes.calendarRow}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateTimePicker
                        value={values.yourUpcomingMeeting}
                        disablePast
                        onAccept={(aceptValue) => {
                          setFieldValue(
                            'yourUpcomingMeeting',
                            aceptValue.toISOString()
                          );
                        }}
                        onChange={(newValue) => {
                          setFieldValue(
                            'yourUpcomingMeeting',
                            newValue.toISOString()
                          );
                        }}
                        onClose={() => {
                          setFieldValue(
                            'yourUpcomingMeeting',
                            upcomingBoardMeeting
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            size="small"
                            style={{ width: '100%' }}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: 'Start',
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                    <Button
                      color="primary"
                      variant="outlined"
                      disabled={!values.yourUpcomingMeeting}
                      style={{ marginLeft: '4px', fontSize: '.7rem' }}
                      size="small"
                      onClick={() => {
                        setFieldValue('yourUpcomingMeeting', null);
                      }}
                    >
                      Clear
                    </Button>
                  </div>
                </div>

                <div>
                  <Typography variant="caption" sx={classes.subTitleStyle}>
                    {t('EDITMODAL-FREETRIAL')}
                  </Typography>
                  <Input
                    height={27}
                    fontSize="0.8rem"
                    style={classes.fieldContainer}
                    name="freeTrialEndsOn"
                    onChange={handleChangeFreeTrial}
                    size="small"
                    type="date"
                    value={userForm.freeTrialEndsOn}
                  />
                </div>
                <FormControlLabel
                  control={instantJoinEnabledCheckbox(values, setFieldValue)}
                  label="Instant Join Enabled"
                />
                <FormControlLabel
                  control={P2PCheckbox(values, setFieldValue)}
                  label="P2P"
                />
                <FormControlLabel
                  control={companyCheckbox(values, setFieldValue)}
                  label="Company"
                />
                <FormControlLabel
                  control={complimentaryBoardPositionCheckbox(
                    values,
                    setFieldValue
                  )}
                  label="Complimentary Board Position"
                />
                <div style={classes.continueButtonContainer}>
                  <Button
                    color="primary"
                    data-cy="updateContactContinue"
                    disabled={
                      (!isValid && values.yourBoardType === null) ||
                      values.yourPlanCode === null
                    }
                    loading={isLoading ? 1 : 0}
                    style={{ width: 80, fontSize: '.7rem' }}
                    size="small"
                    onClick={() => {
                      submitChanges(values);
                    }}
                  >
                    {t('EDITMODAL-SAVE')}
                  </Button>
                </div>
              </Form>
            </Paper>
          );
        }}
      </Formik>
    </>
  );
};

export default EditCompanyModal;
