import React, { useState, useRef } from 'react';
import {
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { useTranslation, Trans } from 'react-i18next';
import { ACIcon, Button, Snackbar } from '@advisorycloud/react-ui-components';
import { TextField } from 'formik-mui';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { withRouter } from 'react-router';
import {
  forgotPassword,
  ForgotPasswordResponses,
  ForgotPasswordErrorResponses,
} from '../../services/Authentication';
import { Transitions } from '../../services/Transition';

const useStyles = () => ({
  dialogTitle: {
    padding: '15px',
  },
  dialogTitleText: {
    color: 'secondary.main',
  },
  dialogLabel: {
    color: 'common.black',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    padding: '15px',
    marginTop: '-4%',
  },
  dialogActions: {
    padding: '15px',
  },
  iconButton: {
    color: 'secondary.main',
    position: 'absolute',
    right: '0px',
    padding: '3px',
    '&:hover': {
      backgroundColor: 'transparent',
      padding: '3px',
    },
  },
  supportLink: {
    color: 'primary.main',
    textDecoration: 'underline',
  },
});

const FieldWrapper = withStyles((theme) => ({
  root: {
    '& .MuiInputBase-input': {
      color: theme.palette.common.black,
      height: 5,
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.secondary.main,
    },
    '& .MuiInputBase-input:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 100px #fafafa inset',
      // #fafafa is CSS-Baseline 'white' default background
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: theme.palette.primary.main,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.primary.main,
    },
    '& .Mui-error:after': {
      borderBottomColor: theme.palette.error.main,
    },
  },
}))(Field);

Yup.addMethod(
  Yup.string,
  'userNotFound',
  function method(errorMessage, message) {
    return this.test('error-code', errorMessage, function tested() {
      const { path, createError } = this;
      if (errorMessage === ForgotPasswordErrorResponses.USER_NOT_FOUND) {
        return createError({
          path,
          message,
        });
      }

      return true;
    });
  }
);

function ModalResetPassword(props) {
  const { open, onChange, prePopulatedEmail } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [text, setText] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [autoHide, setAutoHide] = useState(6000);
  const [errorMessage, setErrorMessage] = useState(null);
  const formikRef = useRef();

  const SignupSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('USERNAME_REQUIRED'))
      .required(t('USERNAME_REQUIRED'))
      .userNotFound(errorMessage, t('MODAL-ERROR-USER-NOT-FOUND', { email })),
  });

  async function handleRequest() {
    const clientMetadata = { workflow: 'admin-password-reset' };
    setLoading(true);
    const isRequestOk = await forgotPassword(email, clientMetadata);
    setLoading(false);

    if (isRequestOk === ForgotPasswordResponses.SUCCESS) {
      props.history.push({
        pathname: '/password/reset',
        state: {
          modalTransition: Transitions.Types.COMPONENT_TRANSITION,
          modalEmail: email,
        },
      });
    } else if (isRequestOk === ForgotPasswordErrorResponses.USER_NOT_FOUND) {
      setErrorMessage(isRequestOk);
    } else if (
      isRequestOk === ForgotPasswordErrorResponses.NOT_AUTHORIZED_EXCEPTION
    ) {
      setText(
        <Trans
          t={t}
          i18nKey="MODAL-ERROR-SNACKBAR"
          components={{
            d: <div />,
            ln: (
              <Link
                sx={classes.signInLink}
                href="https://www.advisorycloud.com"
              />
            ),
            nth: <Typography variant="body2" display="inline" />,
            th1: (
              <Typography
                variant="body2"
                display="inline"
                sx={classes.supportLink}
              />
            ),
          }}
        />
      );
      setAutoHide(30000);
      setShowSnackbar(true);
      onChange(false);
    } else {
      setText(t('FORGOT_PASSWORD_API_ERROR'));
      setShowSnackbar(true);
      onChange(false);
    }

    if (formikRef.current) {
      formikRef.current.validateForm();
      setErrorMessage(null);
    }
  }

  return (
    <>
      <Snackbar
        open={showSnackbar}
        text={text}
        severity="error"
        onClose={() => setShowSnackbar(false)}
        autoHideDuration={autoHide}
      />
      <Formik
        initialValues={{
          email: prePopulatedEmail || '',
        }}
        innerRef={formikRef}
        validationSchema={SignupSchema}
        onSubmit={handleRequest}
        validateOnChange
        validateOnBlur={false}
      >
        {(formik) => {
          const {
            isValid,
            touched,
            handleSubmit,
            errors,
            values,
            handleChange,
            setFieldTouched,
            handleReset,
          } = formik;
          return (
            <Form style={classes.form} onSubmit={handleSubmit}>
              <Dialog
                open={open}
                onEnter={handleReset}
                onClose={() => {
                  onChange(false);
                }}
                aria-labelledby="forgot-password-dialog"
              >
                <DialogTitle
                  id="forgot-password-dialog-title"
                  sx={classes.dialogTitle}
                >
                  <Typography
                    variant="h4"
                    component="div"
                    sx={classes.dialogTitleText}
                  >
                    {t('MODAL-FORGOT-PASSWORD-TITLE')}
                  </Typography>
                </DialogTitle>
                <IconButton
                  sx={classes.iconButton}
                  onClick={() => {
                    onChange(false);
                    setErrorMessage(null);
                  }}
                  aria-label={t('MODAL-FORGOT-PASSWORD-CLOSE-BUTTON')}
                  component="span"
                  disableRipple
                >
                  <ACIcon iconname="cancel-outlined" fontSize="small" />
                </IconButton>
                <DialogContent sx={classes.dialogContent}>
                  <DialogContentText>
                    <Typography sx={classes.dialogLabel} component="span">
                      {t('MODAL-FORGOT-PASSWORD-LABEL')}
                    </Typography>
                  </DialogContentText>
                  <FieldWrapper
                    component={TextField}
                    fullWidth
                    sx={{ paddingTop: '5px' }}
                    autoComplete="off"
                    autoFocus
                    placeholder={t('MODAL-FORGOT-PASSWORD-PLACEHOLDER')}
                    type="email"
                    name="email"
                    variant="outlined"
                    value={values.email}
                    onChange={(e) => {
                      setFieldTouched('email');
                      handleChange(e);
                      setEmail(e.target.value);
                    }}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    inputProps={{
                      'aria-label': `${t('ARIA_LABEL_LOGIN_USERNAME')}`,
                      'aria-required': 'true',
                    }}
                  />
                </DialogContent>
                <DialogActions sx={classes.dialogActions}>
                  <Button
                    onClick={handleSubmit}
                    width="250px"
                    size="small"
                    type="submit"
                    color="primary"
                    loading={loading ? 1 : 0}
                    disabled={
                      !(
                        isValid &&
                        Object.keys(touched).length >= 1 &&
                        Object.keys(values.email).length > 1
                      )
                    }
                  >
                    {t('MODAL-FORGOT-PASSWORD-BUTTON')}
                  </Button>
                </DialogActions>
              </Dialog>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default withRouter(ModalResetPassword);
