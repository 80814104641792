import React, { useEffect } from 'react';

function SharedCredentials() {
  useEffect(() => {
    if (window !== null && window.parent) {
      try {
        window.parent.postMessage(
          Object.entries(localStorage),
          `${process.env.REACT_APP_CUSTOMER_APPLICATION_URL}`
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    }
  }, []);

  return <span>SharedCredentials</span>;
}

export default SharedCredentials;
