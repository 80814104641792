import React from 'react';
import { Helmet } from 'react-helmet';
import WebFont from 'webfontloader';
import { Amplify, Auth } from 'aws-amplify';
// import CssBaseline from '@material-ui/core/CssBaseline';
import awsconfig from './services/Amplify/aws-exports';
import { AccountProvider } from './contexts/Account';
import Router from './Router';
import storage from './storage';

WebFont.load({
  google: {
    families: ['Roboto:300, 400, 500, 700'],
  },
});
Amplify.configure(awsconfig);
Auth.configure({
  storage,
});

function App() {
  return (
    <AccountProvider>
      <Helmet
        defaultTitle={process.env.REACT_APP_APPLICATION_NAME}
        titleTemplate={process.env.REACT_APP_APPLICATION_TITLE_TEMPLATE}
      />

      {/* <CssBaseline /> */}
      <Router />
    </AccountProvider>
  );
}

export default App;
