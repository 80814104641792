import React from 'react';
import { Autocomplete } from '@mui/material';
import {
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { countries } from '../../../../services/utils/countryCode';
import { NullCountry } from '../../../../services/utils/types';
import TextField from '../TextField';

const useStyles = () => ({
  label: {
    marginBottom: '8px',
  },
  countryDropdownContainer: {
    '& input': {
      height: '29px',
    },
    marginBottom: '15px',
  },
});

function countryToFlag(isoCode) {
  if (isoCode === null || isoCode === undefined) {
    return null;
  }
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}

function CountryDropdown(props) {
  const { labelText, onBlur, onChange, value, excludeNullCountryOption } =
    props;
  const theme = useTheme();
  const isSM = useMediaQuery(() => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);

  return (
    <>
      <Typography sx={classes.label} variant="body1">
        {labelText}
      </Typography>
      <div style={classes.countryDropdownContainer}>
        <Autocomplete
          id="countryCode"
          onBlur={onBlur}
          onChange={onChange}
          options={excludeNullCountryOption ? countries.slice(1) : countries}
          autoHighlight
          getOptionLabel={(option) => {
            if (option.code === NullCountry.NULL_COUNTRY) {
              return `${option.label}`;
            }
            return `${countryToFlag(option.code)} ${option.label}`;
          }}
          renderOption={(props, option) => (
            <li {...props}>
              <span>{countryToFlag(option.code)}</span>
              <span>{`${option.label}-(${option.code})`}</span>
            </li>
          )}
          defaultValue="US"
          value={countries.find((element) => element.code === value) || {}}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              size="small"
              inputProps={{
                ...params.inputProps,
                value,
                'data-recurly': 'country',
              }}
            />
          )}
        />
      </div>
    </>
  );
}

export default CountryDropdown;
