import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  ClickAwayListener,
  Dialog,
  Typography,
  DialogTitle,
  DialogActions,
  LinearProgress,
  CircularProgress,
} from '@mui/material';
import {
  LoadingIndicatorLogo,
  Snackbar,
  EmptyStateContent,
  Button,
} from '@advisorycloud/react-ui-components';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import DefaultAdvisorAvatar from '@advisorycloud/react-ui-components/dist/images/dashboard-advisors.svg';
import EmptyAdvisorManSvg from '@advisorycloud/react-ui-components/dist/images/empty-advisor-man.svg';
import EmptyAdvisorWomenSvg from '@advisorycloud/react-ui-components/dist/images/empty-advisor-women.svg';
import {
  adminSearchAllAccounts,
  updateAdvisorAccountStatus,
  updateCompanyContactAccountStatus,
  getAllRecurlyPlans,
  getNewAdvisors,
  inviteToJoin,
  pauseCompanyNotice,
  bulkInviteFinished,
} from '../../services/admin_services';

import UserCard from '../../components/AdminUserCard';
import { useAccount } from '../../contexts/Account';
import EditUsersModal from '../../components/EditUsersModal';
import EditCompanyModal from '../../components/EditCompanyModal';
import {
  AccountType,
  BoardType,
  ResponseStatus,
  TypeName,
  UserStatus,
} from '../../services/utils/types';
import {
  resetUserPassword,
  ForgotPasswordResponses,
} from '../../services/Authentication';

const useStyles = () => ({
  container: {
    width: '100%',
    paddingLeft: '170px',
    paddingRight: '170px',
    paddingTop: '25px',
  },
  subContainer: {
    width: '100%',
    margin: '15px',
  },
  loadingContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  backdrop: {
    width: 'calc(100% - 210px)',
    height: '100%',
    zIndex: 99,
    position: 'fixed',
    top: '0px',
    left: '100px',
    backgroundColor: 'rgba(0,0,0,0.1)',
  },
  modalPosition: {
    position: 'absolute',
    top: '-40px',
    left: '100px',
    zIndex: 120,
  },
  updatingEffect: {
    position: 'absolute',
    top: '0px',
    rigth: '0px',
    left: '0px',
    zIndex: 100,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(237,237,237,0.5)',
  },
  numberOfBoardsSpan: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalTitle: {
    textAlign: 'center',
    padding: '20px',
    marginBottom: '-5px',
  },
  modalActions: {
    marginTop: '-5px',
    marginBottom: '10px',
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center',
    justifyContent: 'center',
  },
  loadingInvite: {
    marginTop: '-5px',
    padding: '0px 20px 20px 20px',
  },
});

function Dashboard() {
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const classes = useStyles();
  const [searchResults, setSearchResults] = useState([]);
  const [openEditUser, setOpenEditUser] = useState(false);
  const [openEditCompany, setOpenEditCompany] = useState(false);
  const [openEditContact, setOpenEditContact] = useState(false);
  const [selectIndex, setSelectIndex] = useState(null);
  const [selectValue, setSelectValue] = useState(null);
  const [complete, setComplete] = useState(false);
  const query = queryParams.get('query');
  const [snackMessage, setSnackMessage] = useState('');
  const [isSuccessSnackbar, setIsSuccessSnackbar] = useState(false);
  const [isErrorSnackbar, setIsErrorSnackbar] = useState(false);
  const [isFetchErrorSnackbar, setIsFetchErrorSnackbar] = useState(false);
  const [updatingValues, setUpdatingValues] = useState(false);
  const [statusChangeSnackbar, setStatusChangeSnackbar] = useState(false);
  const [statusChangeText, setStatusChangeText] = useState();
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false);
  const [planOptionsB2C, setPlanOptionsB2C] = useState();
  const [planOptionsB2B, setPlanOptionsB2B] = useState();
  const [loadingPlanOptions, setLoadingPlanOptions] = useState(true);
  const [statusErrorChangeSnackbar, setStatusErrorChangeSnackbar] =
    useState(false);
  const [companyContacts, setCompanyContacts] = useState([]);
  const emptyStateProps = {
    imageArray: [
      { src: EmptyAdvisorManSvg, alt: 'empty-advisor-man.svg', width: '200' },
      {
        src: EmptyAdvisorWomenSvg,
        alt: 'empty-advisor-women.svg',
        width: '200',
      },
    ],
    titleText: t('SEARCH_EMPTY_STATE_TITLE'),
    contentText: t('SEARCH_EMPTY_STATE_CONTENT'),
  };
  const [inviteCompanyId, setInviteCompanyId] = useState();
  const [openConfirmInviteModal, setOpenConfirmInviteModal] = useState(false);
  const [openCXLModal, setOpenCXLModal] = useState(false);
  const [loadingCXL, setLoadingCXL] = useState(false);
  const [inviteLoading, setInviteLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [advisorsToInvite, setAdvisorsToInvite] = useState(0);
  const [advisorsInvited, setAdvisorsInvited] = useState(0);
  const [advisorsInvitedSucess, setAdvisorsInvitedSuccess] = useState(0);
  const [inviteDisabled, setInviteDisabled] = useState(false);
  const { cognitoGroups } = useAccount();
  const isSystemAdmin = cognitoGroups.includes('system_admin');
  const isSuperAdmin = cognitoGroups.includes('super_admin');

  async function loadData() {
    setComplete(false);
    setIsSuccessSnackbar(false);
    setIsFetchErrorSnackbar(false);
    try {
      const windowUrl = window.location.search;
      const params = new URLSearchParams(windowUrl);
      let results;
      if (params.has('id')) {
        const cognitoId = params.get('id');
        results = await adminSearchAllAccounts({ cognitoId });
      } else {
        results = await adminSearchAllAccounts({ searchQuery: query });
      }
      const contacts = results.data.adminSearchAllAccounts.filter(
        (element) => element.__typename === TypeName.COMPANY_CONTACT
      );
      const allResults = results.data.adminSearchAllAccounts.filter(
        (element) => element.__typename !== TypeName.COMPANY_CONTACT
      );
      setSearchResults(allResults);
      setCompanyContacts(contacts);
    } catch (err) {
      setIsFetchErrorSnackbar(true);
    }
    setComplete(true);
  }

  async function getPlans() {
    try {
      const results = await getAllRecurlyPlans();
      const plansArray = results.data.getRecurlyPlans;
      const plansArrayMap = plansArray.map((element) => {
        return {
          code: element.recurlyPlanCode,
          name: element.recurlyPlanCodeLabel,
          isActive: element.isActive,
          type: element.accountType,
          boardsCanJoinCount: element.boardsCanJoinCount,
        };
      });
      const planB2B = plansArrayMap.filter((element) => element.type === 'B2B');
      const planB2C = plansArrayMap.filter((element) => element.type === 'B2C');
      setPlanOptionsB2C(planB2C);
      setPlanOptionsB2B(planB2B);
      return plansArrayMap;
    } catch (err) {
      throw new Error(err);
    }
  }

  function getAllPlans() {
    Promise.all([getPlans()])
      .then(() => {
        setLoadingPlanOptions(false);
      })
      .catch(() => {
        setSnackMessage(t('SEARCH_GET_PLANS_ERROR'));
        setIsErrorSnackbar(true);
        setLoadingPlanOptions(false);
      });
  }

  async function resetPassword(data, index) {
    setSelectIndex(index);
    setResetPasswordLoading(true);

    try {
      const isRequestOk = await resetUserPassword(data.email);

      if (isRequestOk === ForgotPasswordResponses.SUCCESS) {
        setSnackMessage(t('SEARCH_RESET_PASSWORD_SUCCESS'));
        setIsSuccessSnackbar(true);
      } else {
        setSnackMessage(t('SEARCH_RESET_PASSWORD_ERROR'));
        setIsErrorSnackbar(true);
      }
    } catch (error) {
      setSnackMessage(t('SEARCH_RESET_PASSWORD_ERROR'));
      setIsErrorSnackbar(true);
    }
    setResetPasswordLoading(false);
  }

  async function verifyEmail(data, index) {
    setSelectIndex(index);
    setResetPasswordLoading(true);

    try {
      const isRequestOk = await resetUserPassword(data.email);

      if (isRequestOk === ForgotPasswordResponses.SUCCESS) {
        setSnackMessage(t('SEARCH_VERIFY_EMAIL_SUCCESS'));
        setIsSuccessSnackbar(true);
      } else {
        setSnackMessage(t('SEARCH_VERIFY_EMAIL_ERROR'));
        setIsErrorSnackbar(true);
      }
    } catch (error) {
      setSnackMessage(t('SEARCH_VERIFY_EMAIL_ERROR'));
      setIsErrorSnackbar(true);
    }
    setResetPasswordLoading(false);
  }

  function goToUrl(url) {
    window.open(url);
  }

  function getLeftButtons(value, userType) {
    const leftButtons = [];
    leftButtons.push({
      text: 'Recurly',
      id: 'recurly',
      disabled: !value.recurlyAccountCode,
      onClick: () =>
        goToUrl(
          `https://execrank.recurly.com/accounts/${value.recurlyAccountCode}`
        ),
    });
    leftButtons.push({
      text: 'SalesForce',
      id: 'salesForce',
      disabled: !value.salesforceContactId,
      onClick: () =>
        goToUrl(
          `https://advisorycloud.my.salesforce.com/${value.salesforceContactId}`
        ),
    });
    return leftButtons;
  }

  async function updateValues() {
    setUpdatingValues(true);
    try {
      const results = await adminSearchAllAccounts({ searchQuery: query });
      setSearchResults(results.data.adminSearchAllAccounts);
    } catch (err) {
      setIsFetchErrorSnackbar(true);
    }
    setUpdatingValues(false);
  }

  //https://app.advisorycloud.com/dashboard?impersonate=671678e2-daca-4da2-9d37-a6ca34d7dc0b&userType=company&getCredentials=true
  function impersonateUser(userId, userType) {
    const environmentUrl = process.env.REACT_APP_CUSTOMER_APPLICATION_URL;
    const impersonateUrl = `${environmentUrl}/dashboard?impersonate=${userId}&userType=${userType}&getCredentials=true`;
    const newWindow = window.open(
      impersonateUrl,
      '_blank',
      'noopener,noreferrer'
    );
    if (newWindow) newWindow.opener = null;
  }

  async function updateAdvisorStatus(userStatus, USER_ID) {
    try {
      await updateAdvisorAccountStatus({ userStatus, USER_ID });
      setStatusChangeText(t('SEARCH-CONTACT-UPDATED'));
      setStatusChangeSnackbar(true);
    } catch (error) {
      setStatusErrorChangeSnackbar(true);
    }
  }

  async function updateCompanyStatus(userStatus, USER_ID) {
    try {
      await updateCompanyContactAccountStatus({ userStatus, USER_ID });
      setStatusChangeText(t('SEARCH-CONTACT-UPDATED'));
      setStatusChangeSnackbar(true);
    } catch (error) {
      setStatusErrorChangeSnackbar(true);
    }
  }

  function handleSuccess(data, message) {
    if (data === ResponseStatus.SUCCESS) {
      setSnackMessage(t('SEARCH-CONTACT-UPDATED'));
      setIsSuccessSnackbar(true);
      updateValues();
    } else {
      if (message) {
        setSnackMessage(message);
      } else {
        setSnackMessage(t('SEARCH-CONTACT-UDPATED-ERROR'));
      }
      setIsErrorSnackbar(true);
    }
  }

  function numberOfBoards(recurlyPlan, numberBoards) {
    const filteredArray = planOptionsB2C.filter(
      (element) => element.code === recurlyPlan
    );

    if (filteredArray.length === 0) {
      return t('SEARCH_ERROR_UNEXISTING_PLAN');
    }

    const boardLimit =
      filteredArray[0].boardsCanJoinCount === -1
        ? String.fromCharCode(0x221e)
        : filteredArray[0].boardsCanJoinCount;
    return (
      <span style={classes.numberOfBoardsSpan}>
        {`${numberBoards} of `}
        <span
          style={{
            fontSize: boardLimit === String.fromCharCode(0x221e) ? 20 : null,
            marginLeft: 5,
          }}
        >
          {`${boardLimit}`}
        </span>
      </span>
    );
  }

  function renderNumberBoards(values) {
    if (loadingPlanOptions) {
      return t('SEARCH_NUMBER_OF_BOARDS_LOADING');
    }
    if (
      !loadingPlanOptions &&
      (planOptionsB2C === null || planOptionsB2C === undefined)
    ) {
      return t('SEARCH_NUMBER_OF_BOARDS_ERROR');
    }
    let filteredCount = 0;
    if (values.boards && values.boards.memberBoards) {
      filteredCount = values.boards.memberBoards.filter(
        (company) =>
          !company.isAcPlus &&
          !company.isComplimentaryBoardPosition &&
          company.boardType !== 'P2P'
      ).length;
    }
    return numberOfBoards(values.recurlyPlanCode, filteredCount);
  }

  function renderCompanyPlanCode(recurlyPlan) {
    if (loadingPlanOptions) {
      return t('SEARCH_NUMBER_OF_BOARDS_LOADING');
    }

    if (
      !loadingPlanOptions &&
      (planOptionsB2B === null || planOptionsB2B === undefined)
    ) {
      return t('SEARCH_NUMBER_OF_BOARDS_ERROR');
    }

    const filteredArray = planOptionsB2B.filter(
      (element) => element.code === recurlyPlan
    );
    if (filteredArray.length === 0) {
      return t('SEARCH_ERROR_UNEXISTING_PLAN');
    }
    return recurlyPlan;
  }

  function renderUsersPlanCode(recurlyPlan) {
    if (loadingPlanOptions) {
      return t('SEARCH_NUMBER_OF_BOARDS_LOADING');
    }
    if (
      !loadingPlanOptions &&
      (planOptionsB2C === null || planOptionsB2C === undefined)
    ) {
      return t('SEARCH_NUMBER_OF_BOARDS_ERROR');
    }
    const filteredArray = planOptionsB2C.filter(
      (element) => element.code === recurlyPlan
    );
    if (filteredArray.length === 0) {
      return t('SEARCH_ERROR_UNEXISTING_PLAN');
    }
    return recurlyPlan;
  }

  function inviteNewUsersButton(company) {
    setInviteCompanyId(company);
    setOpenConfirmInviteModal(true);
  }

  function openConfirmCXLModal(company) {
    setInviteCompanyId(company);
    setOpenCXLModal(true);
  }

  async function delay(s) {
    return new Promise((resolve) => setTimeout(resolve, s * 1000));
  }

  async function inviteNewUsers() {
    setInviteLoading(true);
    try {
      let newAdvisors = [];
      let offset = 0;
      do {
        const newAdvisorsResponse = await getNewAdvisors({
          COMAPNY_ID: inviteCompanyId.id,
          OFFSET: offset,
        });
        if (
          newAdvisorsResponse &&
          newAdvisorsResponse.data &&
          newAdvisorsResponse.data.getNewAdvisors &&
          newAdvisorsResponse.data.getNewAdvisors.advisors &&
          newAdvisorsResponse.data.getNewAdvisors.advisors.length > 0
        ) {
          newAdvisors = newAdvisors.concat(
            newAdvisorsResponse.data.getNewAdvisors.advisors
          );
          offset = newAdvisorsResponse.data.getNewAdvisors.offset;
          console.log(
            'new advisors',
            newAdvisorsResponse.data.getNewAdvisors.advisors.length,
            'offset',
            offset
          );
        } else {
          offset = null;
        }
      } while (offset !== null);

      if (newAdvisors.length === 0) {
        setInviteLoading(false);
        setInviteDisabled(true);
        return;
      }

      console.log('advisors to invite', newAdvisors.length);
      setAdvisorsToInvite(newAdvisors.length);

      let invitedCounter = 0;
      let successCounter = 0;
      let lowIndex = 0;
      let highIndex = 35;
      do {
        newAdvisors.slice(lowIndex, highIndex).forEach((advisor) => {
          inviteToJoin({
            COMAPNY_ID: inviteCompanyId.id,
            ADVISOR_ID: advisor.id,
          })
            .then((res) => {
              if (res && res.data && res.data.adminInviteToJoin) {
                successCounter += 1;
                setAdvisorsInvitedSuccess(successCounter);
              }
              invitedCounter += 1;
              setAdvisorsInvited(invitedCounter);
            })
            .catch((er) => {
              console.log(er);
              invitedCounter += 1;
              setAdvisorsInvited(invitedCounter);
            });
        });
        await delay(5);
        lowIndex += 35;
        highIndex += 35;
      } while (lowIndex < newAdvisors.length);
      setInviteDisabled(true);
      setInviteLoading(false);
      await bulkInviteFinished({
        COMPANY_ID: inviteCompanyId.id,
      });
    } catch (err) {
      console.log(err);
      setInviteLoading(false);
      setInviteDisabled(true);
    }
  }

  function closeInviteModal() {
    if (!inviteLoading && advisorsToInvite <= advisorsInvited) {
      setOpenConfirmInviteModal(false);
      if (advisorsInvited) {
        updateValues();
      }
      setAdvisorsInvited(0);
      setAdvisorsToInvite(0);
      setAdvisorsInvitedSuccess(0);
      setInviteDisabled(false);
    }
  }

  function formatDate(dateString) {
    const date = new Date(dateString);
    return [
      (date.getMonth() + 1).toString().padStart(2, '0'),
      date.getDate().toString().padStart(2, '0'),
      date.getFullYear(),
    ].join('/');
  }

  function copyTextAction(page, company) {
    function environmentUrl() {
      if (process.env.NODE_ENV === 'development') {
        return 'https://app-dev.advisorycloud.com';
      }
      if (process.env.NODE_ENV === 'production') {
        return 'https://app.advisorycloud.com';
      }
      if (process.env.NODE_ENV === 'test') {
        return 'https://app-test.advisorycloud.com';
      }
      return null;
    }

    let copyPage = `${environmentUrl()}/${company.slug}/boardroom`;
    if (page === 'join') {
      copyPage = `${environmentUrl()}/board-directory?view=${company.slug}`;
    }
    navigator.clipboard.writeText(copyPage);
    setStatusChangeText(
      `Success: ${page === 'join' ? 'Join Page' : 'Boardroom'} URL Copied `
    );
    setStatusChangeSnackbar(true);
  }

  async function sendCXLEmail() {
    setLoadingCXL(true);
    const response = await pauseCompanyNotice({
      COMAPNY_ID: inviteCompanyId.id,
    });
    setLoadingCXL(false);
    setInviteCompanyId(null);
    setOpenCXLModal(false);
    if (response && response.data && response.data.sendPauseCompanyNotice) {
      setStatusChangeText('CXL Notice Sent');
      setStatusChangeSnackbar(true);
    }
  }

  useEffect(() => {
    loadData();
  }, [query]);

  useEffect(() => {
    getAllPlans();
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('SEARCH-HELMET-TITLE')}</title>
      </Helmet>
      <Snackbar
        open={isFetchErrorSnackbar}
        text="Error obtaining your search results"
        severity="error"
        variant="filled"
        onClose={() => setIsFetchErrorSnackbar(false)}
        autoHideDuration={6000}
      />
      <Snackbar
        open={statusErrorChangeSnackbar}
        text="Error changing account status"
        severity="error"
        variant="filled"
        onClose={() => setStatusErrorChangeSnackbar(false)}
        autoHideDuration={5000}
      />
      <Snackbar
        open={statusChangeSnackbar}
        text={statusChangeText}
        severity="success"
        variant="filled"
        onClose={() => setStatusChangeSnackbar(false)}
        autoHideDuration={5000}
      />
      <Dialog
        open={openConfirmInviteModal}
        maxWidth="xs"
        onClose={() => {
          closeInviteModal();
        }}
      >
        <Typography>
          <DialogTitle sx={classes.modalTitle}>
            <Typography variant="h2" component="div">
              Are you sure you want to invite all new advisors (registered in
              the past 30 days) to this board?
            </Typography>
          </DialogTitle>
          <DialogActions sx={classes.modalActions}>
            <Button
              onClick={() => closeInviteModal()}
              size="medium"
              type="submit"
              disabled={inviteLoading || inviteDisabled}
              color="secondary"
            >
              No
            </Button>
            <Button
              loading={
                inviteLoading || advisorsToInvite > advisorsInvited ? 1 : 0
              }
              disabled={inviteDisabled}
              onClick={inviteNewUsers}
              size="medium"
              type="submit"
              color="primary"
            >
              Yes
            </Button>
          </DialogActions>
          {advisorsToInvite ? (
            <div style={classes.loadingInvite}>
              <Typography>{`${advisorsInvited}/${advisorsToInvite} Advisors`}</Typography>
              <LinearProgress
                style={{ marginTop: 5 }}
                variant="determinate"
                value={(advisorsInvited / advisorsToInvite) * 100}
              />
              {advisorsToInvite <= advisorsInvited ? (
                <Typography
                  color="primary"
                  style={{ textAlign: 'center', marginTop: 10 }}
                >
                  {`${advisorsInvitedSucess} Advisors Invited`}
                </Typography>
              ) : null}
            </div>
          ) : null}
        </Typography>
      </Dialog>
      <Dialog
        open={openCXLModal}
        maxWidth="xs"
        onClose={() => {
          setOpenCXLModal(false);
        }}
      >
        <Typography>
          <DialogTitle sx={classes.modalTitle}>
            <Typography variant="h2" component="div">
              Are you sure you want to send CXL Notice to all advisors
              registered to this board?
            </Typography>
          </DialogTitle>
          <DialogActions sx={classes.modalActions}>
            <Button
              onClick={() => setOpenCXLModal(false)}
              disabled={loadingCXL}
              size="medium"
              type="submit"
              color="secondary"
            >
              No
            </Button>
            <Button
              disabled={loadingCXL}
              loading={loadingCXL}
              onClick={sendCXLEmail}
              size="medium"
              type="submit"
              color="primary"
            >
              Yes
            </Button>
          </DialogActions>
          {loadingCXL ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: '20px',
              }}
            >
              <CircularProgress />
            </div>
          ) : null}
        </Typography>
      </Dialog>
      <div style={classes.container}>
        {!complete && isFetchErrorSnackbar === false && (
          <div style={classes.loadingContainer}>
            <LoadingIndicatorLogo iconFontSize={134} iconRight={162} />
          </div>
        )}
        {complete && searchResults.length > 0 ? (
          <>
            {searchResults.map((values, index) => {
              const userType = values.__typename;
              function imageSelection(contactId = null) {
                if (
                  values.image &&
                  values.image.imageKey &&
                  process.env.REACT_APP_IMAGES_URL
                ) {
                  return `${process.env.REACT_APP_IMAGES_URL}${values.image.imageKey}`;
                }
                if (contactId) {
                  const contact = values.contacts.find(
                    (element) => element.id === contactId
                  );
                  if (contact && contact.image && contact.image.location) {
                    return `${document.location.protocol}//${contact.image.location}`;
                  }
                }
                if (values.image && values.image.location) {
                  return `${document.location.protocol}//${values.image.location}`;
                }
                return DefaultAdvisorAvatar;
              }

              function boardOwnerInfo() {
                if (values.contacts !== null && values.contacts !== undefined) {
                  const resultArray = values.contacts.filter((contact) => {
                    return contact.isBoardOwner === true;
                  });
                  return resultArray[0];
                }
                return null;
              }
              function getBoardOwnerMail(contacts) {
                if (contacts !== null && contacts !== undefined) {
                  const boardOwner = contacts.find(
                    (contact) => contact.isBoardOwner
                  );
                  if (boardOwner) {
                    return boardOwner.email;
                  }
                  return '';
                }
                return null;
              }

              function userCardSelector() {
                if (userType === TypeName.ADVISOR) {
                  return (
                    <UserCard
                      updateStatus={updateAdvisorStatus}
                      id={values.id}
                      type={AccountType.ADVISOR}
                      headerFormat={AccountType.ADVISOR}
                      isSystemAdmin={isSystemAdmin}
                      isSuperAdmin={isSuperAdmin}
                      status={values.accountStatus}
                      firstName={values.givenName}
                      lastName={values.surName}
                      userTitle={values.title}
                      onboardingStatus={values.onboardingStatus}
                      setIsModalVisible={setIsModalVisible}
                      isModalVisible={isModalVisible}
                      companyName={values.companyName}
                      phoneNumber={
                        values.phoneNumber === null
                          ? 'No phone number'
                          : values.phoneNumber.phoneNumber
                      }
                      email={values.email}
                      accountLevel={TypeName.ADVISOR}
                      registrationDate={values.createdAt}
                      avatarImage={imageSelection()}
                      planCode={renderUsersPlanCode(values.recurlyPlanCode)}
                      advisorNumberOfBoards={renderNumberBoards(values)}
                      freeTrialEndsOn={values.freeTrialEndsOn}
                      editUser={() => {
                        setSelectIndex(index);
                        setOpenEditUser(true);
                      }}
                      editCompany={() => {
                        setSelectIndex(index);
                        setOpenEditUser(true);
                      }}
                      cardLeftButtons={getLeftButtons(values, userType)}
                      cardRightButtons={[
                        {
                          showButton: values.accountStatus === UserStatus.NEW,
                          text: 'Verify Email',
                          id: 'verifyEmail',
                          iconname: 'mail-outlined',
                          disabled: false,
                          onClick: () => verifyEmail(values, index),
                        },
                        {
                          showButton: isSystemAdmin,
                          text: 'CCPA Deletion',
                          id: 'CCPADeletion',
                          disabled: false,
                          onClick: () => setIsModalVisible(true),
                        },
                        {
                          showButton:
                            values.accountStatus === UserStatus.ACTIVE,
                          text: 'Reset Password',
                          id: 'resetPassword',
                          iconname: 'key-outlined',
                          disabled: false,
                          onClick: () => resetPassword(values, index),
                        },
                        {
                          showButton:
                            values.accountStatus === UserStatus.ACTIVE ||
                            values.accountStatus === UserStatus.NEW,
                          text: 'Impersonate',
                          id: 'impersonate',
                          iconname: 'show-outlined',
                          disabled: false,
                          onClick: () =>
                            impersonateUser(
                              values.id,
                              AccountType.ADVISOR,
                              values.accountStatus
                            ),
                        },
                      ]}
                    />
                  );
                }
                if (userType === TypeName.COMPANY) {
                  const companyContact = companyContacts.filter(
                    (element) => element.companyId === values.id
                  );
                  return (
                    <>
                      <UserCard
                        id={values.id}
                        status={values.accountStatus}
                        updateStatus={updateCompanyStatus}
                        setIsModalVisible={setIsModalVisible}
                        isModalVisible={isModalVisible}
                        type={AccountType.COMPANY}
                        headerFormat={AccountType.COMPANY}
                        userTitle={values.title}
                        companyName={values.companyName}
                        email={values.email}
                        onboardingStatus={values.onboardingStatus}
                        boardType={values.isPublic ? 'Public' : 'Private'}
                        seatsCount={
                          values.boardSeatsAvailable
                            ? values.boardSeatsAvailable
                            : 0
                        }
                        boardTotalSeats={
                          values.boardSeatsAllowed === null
                            ? 0
                            : values.boardSeatsAllowed
                        }
                        boardOwnerMail={getBoardOwnerMail(companyContact)}
                        accountLevel={TypeName.COMPANY}
                        registrationDate={values.createdAt}
                        avatarImage={imageSelection()}
                        planCode={renderCompanyPlanCode(values.recurlyPlanCode)}
                        editUser={() => {
                          setSelectIndex(index);
                          setOpenEditCompany(true);
                        }}
                        isSuperAdmin={isSuperAdmin}
                        userSegments={values.userSegment}
                        freeTrialEndsOn={values.freeTrialEndsOn}
                        editCompany={() => {
                          setSelectIndex(index);
                          setOpenEditCompany(true);
                        }}
                        cardRightButtons={[
                          {
                            showButton: isSystemAdmin,
                            text: 'CXL Notice',
                            id: 'CXLNoticeButton',
                            disabled: false,
                            onClick: () => openConfirmCXLModal(values),
                          },
                          {
                            text: 'Copy Link',
                            id: 'copyLinkButton',
                            disabled: false,
                            menu: true,
                            options: [
                              {
                                text: 'Boardroom',
                                action: () => {
                                  copyTextAction('boardroom', values);
                                },
                              },
                              {
                                text: 'Join page',
                                action: () => {
                                  copyTextAction('join', values);
                                },
                              },
                            ],
                          },
                          {
                            text: 'ITJ New Users',
                            id: 'InviteNewUsersButton',
                            iconname: 'group-add',
                            disabled: false,
                            onClick: () => inviteNewUsersButton(values),
                            appendText: values.latestNewAdvisorsITJ
                              ? `Latest ${formatDate(
                                  values.latestNewAdvisorsITJ
                                )}`
                              : null,
                          },
                          {
                            showButton: isSuperAdmin,
                            text: 'CCPA Deletion',
                            id: 'CCPADeletion',
                            disabled: false,
                            onClick: () => setIsModalVisible(true),
                          },
                        ]}
                        cardLeftButtons={getLeftButtons(values, userType)}
                      />
                      {companyContact.map((element) => {
                        return (
                          <UserCard
                            status={element.accountStatus}
                            updateStatus={updateCompanyStatus}
                            firstName={element.givenName}
                            lastName={element.surName}
                            userTitle={element.title}
                            key={element.id}
                            id={element.id}
                            companyName={
                              element.companyName === null
                                ? 'Company Missing'
                                : element.companyName
                            }
                            phoneNumber={
                              element.phoneNumber === null
                                ? 'No phone number'
                                : element.phoneNumber.phoneNumber
                            }
                            email={element.email}
                            accountLevel={
                              element.isBoardOwner
                                ? 'board owner'
                                : 'Non-Boardowner'
                            }
                            registrationDate={element.createdAt}
                            avatarImage={imageSelection(element.id)}
                            planCode={values.recurlyPlanCode}
                            editUser={() => {
                              setSelectValue(element);
                              setSelectIndex(index);
                              setOpenEditContact(true);
                            }}
                            editCompanyDisabled
                            cardLeftButtons={getLeftButtons(values, userType)}
                            cardRightButtons={[
                              {
                                showButton:
                                  element.accountStatus === UserStatus.NEW,
                                text: 'Verify Email',
                                id: 'verifyEmail',
                                iconname: 'mail-outlined',
                                disabled: false,
                                onClick: () => verifyEmail(element, index),
                              },
                              {
                                showButton:
                                  element.accountStatus === UserStatus.ACTIVE,
                                text: 'Reset Password',
                                id: 'resetPassword',
                                iconname: 'key-outlined',
                                disabled: false,
                                onClick: () => resetPassword(element, index),
                              },
                              {
                                showButton:
                                  element.accountStatus === UserStatus.ACTIVE ||
                                  element.accountStatus === UserStatus.NEW,
                                text: 'Impersonate',
                                id: 'impersonate',
                                iconname: 'show-outlined',
                                onClick: () =>
                                  impersonateUser(
                                    element.id,
                                    AccountType.COMPANY,
                                    element.accountStatus
                                  ),
                              },
                            ]}
                          />
                        );
                      })}
                    </>
                  );
                }
                return null;
              }

              return (
                <div style={classes.subContainer} key={values.id}>
                  <div style={{ marginBottom: '35px' }}>
                    {userCardSelector()}
                    <>
                      {index === selectIndex &&
                      (updatingValues || resetPasswordLoading) ? (
                        <div style={classes.updatingEffect}>
                          <LoadingIndicatorLogo
                            size={100}
                            iconFontSize={47}
                            iconRight={49}
                          />
                        </div>
                      ) : null}
                    </>

                    <Snackbar
                      open={isErrorSnackbar}
                      text={snackMessage}
                      severity="error"
                      onClose={() => setIsErrorSnackbar(false)}
                      autoHideDuration={6000}
                    />
                    <Snackbar
                      open={isSuccessSnackbar}
                      text={snackMessage}
                      severity="success"
                      variant="filled"
                      onClose={() => setIsSuccessSnackbar(false)}
                      autoHideDuration={6000}
                    />
                  </div>

                  {openEditUser && index === selectIndex && (
                    <div style={classes.modalPosition}>
                      <ClickAwayListener
                        onClickAway={(event) => {
                          if (event.target.localName === 'body') return;
                          setOpenEditUser(false);
                        }}
                      >
                        <div style={{ position: 'relative' }}>
                          <EditUsersModal
                            user={values}
                            firstName={values.givenName}
                            lastName={values.surName}
                            freeTrialEndsOn={values.freeTrialEndsOn}
                            avatarImage={imageSelection()}
                            email={values.email}
                            phoneNumber={
                              values.phoneNumber === null
                                ? null
                                : values.phoneNumber.phoneNumber
                            }
                            phoneNumberCountryCode={
                              values.phoneNumber === null
                                ? null
                                : values.phoneNumber.countryCode
                            }
                            companyName={
                              values.companyName === null
                                ? 'Company Missing'
                                : values.companyName
                            }
                            closeModal={() => setOpenEditUser(false)}
                            userId={values.id}
                            showSnackbar={(data, message) =>
                              handleSuccess(data, message)
                            }
                            recurlyPlanCode={
                              values.recurlyPlanCode
                                ? values.recurlyPlanCode
                                : null
                            }
                            advisorFormat={userType === TypeName.ADVISOR}
                            planOptionsArray={
                              !loadingPlanOptions
                                ? planOptionsB2C.filter((element) => {
                                    return (
                                      element.isActive === true ||
                                      element.code === values.recurlyPlanCode
                                    );
                                  })
                                : null
                            }
                          />
                        </div>
                      </ClickAwayListener>
                    </div>
                  )}
                  {openEditCompany && index === selectIndex && (
                    <div style={classes.modalPosition}>
                      <ClickAwayListener
                        onClickAway={() => setOpenEditCompany(false)}
                      >
                        <div style={{ position: 'relative' }}>
                          <EditCompanyModal
                            avatarImage={imageSelection()}
                            closeModal={() => setOpenEditCompany(false)}
                            companyContactId={values.id}
                            showSnackbar={(data) => handleSuccess(data)}
                            companyName={values.companyName}
                            boardType={
                              values.isPublic
                                ? BoardType.PUBLIC
                                : BoardType.PRIVATE
                            }
                            freeTrialEndsOn={searchResults[0].freeTrialEndsOn}
                            companyBoardType={values.boardType}
                            // temporal value
                            onboardingStatus={values.onboardingStatus}
                            upcomingBoardMeeting={values.upcomingBoardMeeting}
                            instantJoinEnabled={values.instantJoinEnabled}
                            complimentaryBoardPosition={
                              values.complimentaryBoardPosition
                            }
                            recurlyPlanCode={values.recurlyPlanCode}
                            opportunityStage={values.opportunityStage}
                            boardSeats={values.boardSeatsAllowed}
                            boardOwnerInfo={boardOwnerInfo()}
                            companyId={values.id}
                            planOptionsArray={
                              !loadingPlanOptions
                                ? planOptionsB2B.filter((element) => {
                                    return (
                                      element.isActive === true ||
                                      element.code === values.recurlyPlanCode
                                    );
                                  })
                                : null
                            }
                          />
                        </div>
                      </ClickAwayListener>
                    </div>
                  )}
                  {openEditContact && index === selectIndex && (
                    <div style={classes.modalPosition}>
                      <ClickAwayListener
                        onClickAway={() => setOpenEditContact(false)}
                      >
                        <div style={{ position: 'relative' }}>
                          <EditUsersModal
                            user={selectValue}
                            firstName={selectValue.givenName}
                            lastName={selectValue.surName}
                            freeTrialEndsOn={searchResults[0].freeTrialEndsOn}
                            avatarImage={imageSelection()}
                            email={selectValue.email}
                            phoneNumber={
                              selectValue.phoneNumber === null
                                ? null
                                : selectValue.phoneNumber.phoneNumber
                            }
                            phoneNumberCountryCode={
                              selectValue.phoneNumber === null
                                ? null
                                : selectValue.phoneNumber.countryCode
                            }
                            companyName={
                              selectValue.companyName === null
                                ? 'Company Missing'
                                : selectValue.companyName
                            }
                            closeModal={() => setOpenEditContact(false)}
                            userId={selectValue.id}
                            showSnackbar={(data, message) =>
                              handleSuccess(data, message)
                            }
                            recurlyPlanCode={
                              selectValue.recurlyPlanCode
                                ? selectValue.recurlyPlanCode
                                : null
                            }
                            advisorFormat={userType === TypeName.ADVISOR}
                            planOptionsArray={
                              !loadingPlanOptions
                                ? planOptionsB2B.filter((element) => {
                                    return (
                                      element.isActive === true ||
                                      element.code === element.recurlyPlanCode
                                    );
                                  })
                                : null
                            }
                          />
                        </div>
                      </ClickAwayListener>
                    </div>
                  )}
                </div>
              );
            })}
          </>
        ) : (
          <EmptyStateContent
            imageArray={emptyStateProps.imageArray}
            titleText={emptyStateProps.titleText}
            contentText={emptyStateProps.contentText}
          />
        )}
      </div>
      {openEditUser || openEditCompany || openEditContact ? (
        <div style={classes.backdrop} />
      ) : null}
    </>
  );
}

export default Dashboard;
