import React, { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';
import OopsWindow from '../Oops';
import CreatePassword from '../CreatePassword';
import { Transitions } from '../../services/Transition';
import RequestReset from '../RequestReset';

function PasswordReset(props) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryParamsState = new URLSearchParams(location.state);

  function isNil(value) {
    if (value === undefined || value === null) {
      return true;
    }
    return false;
  }

  const [displayedComponent] = useState(() => {
    let email = queryParams.get('email');
    const code = queryParams.get('code');
    const isTransition =
      queryParamsState.get('modalTransition') ===
      Transitions.Types.COMPONENT_TRANSITION;
    const isFirstTime =
      queryParamsState.get('isFirstTime') === Transitions.Types.FIRST_TIME;

    if (email) {
      email = email.replace(/\s/g, '+');
    }

    if (isFirstTime && !isTransition) {
      return Transitions.Types.FIRST_TIME;
    }
    if (isTransition && !isFirstTime) {
      return Transitions.Types.COMPONENT_TRANSITION;
    }
    if (!isTransition && !isNil(email) && !isNil(code)) {
      return 'WITH_PARAMETERS';
    }
    if ((!isTransition && !isNil(email)) || !isNil(code)) {
      return 'ERROR';
    }
    return 'NONE';
  });

  useEffect(() => {
    if (displayedComponent === 'NONE') {
      props.history.push('/');
    }
  }, []);

  const ReturnWindow = () => {
    if (displayedComponent === Transitions.Types.FIRST_TIME) {
      return (
        <RequestReset
          email={queryParamsState.get('isFirstTimeEmail')}
          flowType={Transitions.Types.FIRST_TIME}
        />
      );
    }
    if (displayedComponent === Transitions.Types.COMPONENT_TRANSITION) {
      return (
        <RequestReset
          email={queryParamsState.get('modalEmail')}
          flowType={Transitions.Types.COMPONENT_TRANSITION}
        />
      );
    }
    if (displayedComponent === 'ERROR') {
      return <OopsWindow />;
    }
    if (displayedComponent === 'WITH_PARAMETERS') {
      return <CreatePassword />;
    }
    return <></>;
  };

  return (
    <>
      <ReturnWindow />
    </>
  );
}

export default PasswordReset;
