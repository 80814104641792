import { createTheme } from '@mui/material';

export const SEOTheme = createTheme({
  palette: {
    palette: {
      primary: {
        light: '#E6EDFF',
        main: '#0F78FD',
        dark: '#1169B4',
      },
      secondary: {
        light: '#F5F7FA',
        main: '#C9C9DB',
        dark: '#767680',
      },
      info: {
        light: '#BDD2F0',
        main: '#263250',
        dark: '#162F4B',
      },
    },
    typography: {
      fontFamily: ['Open-Sans', 'Montserrat', 'Roboto'],
      fontSize: 16,
      h1: {
        fontSize: '1.875rem',
        fontWeight: 400,
      },
      h2: {
        fontSize: '1.1176rem',
        fontWeight: 500,
      },
      h3: {
        fontSize: '0.875rem',
        fontWeight: 500,
      },
      h4: {
        fontSize: '1.25rem',
        fontWeight: 600,
      },
      h5: {
        fontSize: '1rem',
        fontWeight: 400,
      },
      h6: {
        fontSize: '3.25rem',
        fontWeight: 400,
      },
      subtitle1: {
        fontSize: '1.375rem',
        fontWeight: 400,
      },
      subtitle2: {
        fontSize: '1.125rem',
        fontWeight: 400,
      },
      body1: {
        fontSize: '0.875rem',
        fontWeight: 400,
      },
      body2: {
        fontSize: '0.875rem',
        fontWeight: 600,
      },
      button: {
        fontSize: '0.875rem',
        fontWeight: 500,
      },
      caption: {
        fontSize: '0.75rem',
        fontWeight: 400,
      },
    },
  },
});
