import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { getCurrentUserInfo, signOut } from '../services/Authentication';

const AccountContext = createContext({});

const INITIAL_STATE = {
  type: null,
  isAuthenticated: false,
  currentUserInfo: null,
  accountDetails: {},
  cognitoGroups: null,
};
const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_ACCOUNT_TYPE':
      return {
        ...state,
        type: action.payload,
      };
    case 'AUTHENTICATE':
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
        currentUserInfo: action.payload.currentUserInfo,
        accountDetails: action.payload.accountDetails,
        cognitoGroups: action.payload.cognitoGroups,
      };

    case 'SIGN_OUT':
      return {
        ...state,
        isAuthenticated: false,
        currentUserInfo: null,
      };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};
export const useAccountActionsWithStore = (initialState = INITIAL_STATE) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return {
    ...state,
    updateAccountType: async (accountType) => {
      dispatch({ type: 'UPDATE_ACCOUNT_TYPE', payload: accountType });
    },
    authenticate: async (cb) => {
      try {
        const currentUserInfo = await getCurrentUserInfo();
        if (currentUserInfo) {
          const accountDetails = currentUserInfo.attributes;

          dispatch({
            type: 'UPDATE_ACCOUNT_TYPE',
            payload: currentUserInfo.accountType,
          });
          dispatch({
            type: 'AUTHENTICATE',
            payload: {
              accountDetails,
              currentUserInfo,
              isAuthenticated: true,
              cognitoGroups:
                currentUserInfo.accessToken.payload['cognito:groups'],
            },
          });
        }
      } catch (error) {
        if (cb) {
          cb(error);
        }
        dispatch({ type: 'AUTHENTICATE', payload: { isAuthenticated: false } });
      }
    },
    signOut: async () => {
      await signOut();

      dispatch({ type: 'SIGN_OUT' });
    },
  };
};

export const AccountProvider = (props) => {
  const { children } = props;

  return (
    <AccountContext.Provider value={useAccountActionsWithStore()}>
      {children}
    </AccountContext.Provider>
  );
};

AccountProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAccount = () => useContext(AccountContext);
