import React from 'react';
import {
  Typography,
  FormControlLabel,
  RadioGroup as MaterialRadioGroup,
  Radio,
  FormHelperText,
} from '@mui/material';

const useStyles = () => ({
  container: {
    marginBottom: '20px',
  },
  label: {
    color: 'info.dark',
    fontWeight: 'bold',
    marginBottom: '15px',
  },
});

function RadioGroup(props) {
  const { labelText, radioOptions, value, onChange, errorText } = props;
  const classes = useStyles();

  return (
    <div style={classes.container}>
      <Typography sx={classes.label} variant="h1">
        {labelText}
      </Typography>
      <MaterialRadioGroup
        aria-label="plan"
        name="plan"
        value={value}
        onChange={(event) => onChange(event)}
      >
        {radioOptions.map((radioOption) => {
          return (
            <FormControlLabel
              key={radioOption.value}
              sx={{ color: 'info.dark' }}
              value={radioOption.value}
              control={
                <Radio
                  sx={
                    radioOption.value === value ? { color: 'primary.main' } : {}
                  }
                />
              }
              label={radioOption.label}
            />
          );
        })}
      </MaterialRadioGroup>
      {errorText ? <FormHelperText error>{errorText}</FormHelperText> : null}
    </div>
  );
}

export default RadioGroup;
