import React from 'react';
import {
  ComponentsProvider,
  ACTheme,
} from '@advisorycloud/react-ui-components';
import { ThemeProvider } from '@mui/material';
import { ACTheme as ACThemeV5 } from '../themes';
import { Switch } from 'react-router-dom';
import Route from '../components/ProtectedRoute';
import Dashboard from '../scenes/Dashboard';
import ConsoleUpperBar from '../components/ConsoleUpperBar';
import MasterCheckout from '../scenes/masterCheckout';
import UpdateCloudsearch from '../scenes/updateCloudsearch';
import AdvisorCheckout from '../scenes/advisorCheckout';
import CompanyCheckoutLast from '../scenes/companyCheckout';
import CompanyCheckout from '../scenes/companyCheckout/index2';
import SearchScene from '../scenes/Search';
import PlanMapping from '../scenes/planMapping';
import PlanCodeForm from '../scenes/PlanCodeForm';
import Insights from '../scenes/Insights';
import InsightsForm from '../scenes/Insights/InsightsForm';
import AdminsManagment from '../scenes/AdminsManagment';
import AdminsForm from '../scenes/AdminsManagment/AdminsForm';

const useStyles = () => ({
  companyContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 'auto',
    minHeight: '100vh',
  },
  viewContainer: {
    display: 'flex',
    flex: 'auto',
    overflowX: 'hidden',
    flexDirection: 'row',
    backgroundColor: '#fafafa',
  },
});

function DashboardLayout({ theme }) {
  const classes = useStyles();

  return (
    <ThemeProvider theme={ACThemeV5}>
      <ComponentsProvider theme={theme}>
        <div style={classes.companyContainer}>
          <ConsoleUpperBar />
          <div style={classes.viewContainer}>
            <Switch>
              <Route
                exact
                path="/dashboard"
                component={() => <Dashboard />}
                theme={ACTheme}
              />
              <Route
                exact
                path="/users"
                component={() => <Dashboard />}
                theme={ACTheme}
              />
              <Route
                exact
                path="/updateCloudsearch"
                component={() => <UpdateCloudsearch />}
                theme={ACTheme}
              />
              <Route
                exact
                path="/checkout/master"
                component={() => <MasterCheckout />}
                theme={ACTheme}
              />
              <Route
                exact
                isAdvisorCheckout
                path="/checkout/advisor"
                component={() => <AdvisorCheckout />}
                theme={ACTheme}
              />
              <Route
                exact
                isCompanyCheckout
                path="/checkout/company"
                component={() => <CompanyCheckout />}
                theme={ACTheme}
              />
              <Route
                exact
                isCompanyCheckout
                path="/checkout/company/last"
                component={() => <CompanyCheckoutLast />}
                theme={ACTheme}
              />

              <Route
                path="/search"
                component={() => <SearchScene />}
                theme={ACTheme}
              />
              <Route
                onlySystemAdmin
                path="/plan-mapping-table/add"
                component={() => <PlanCodeForm />}
                theme={ACTheme}
              />
              <Route
                onlySystemAdmin
                path="/plan-mapping-table/edit/:plancodeId"
                component={() => <PlanCodeForm />}
                theme={ACTheme}
              />
              <Route
                onlySystemAdmin
                path="/plan-mapping-table"
                component={() => <PlanMapping />}
                theme={ACTheme}
              />
              <Route
                onlySystemAdmin
                path="/insights"
                component={() => <Insights />}
                theme={ACTheme}
              />
              <Route
                onlySystemAdmin
                path="/insight"
                component={() => <InsightsForm />}
                theme={ACTheme}
              />
              <Route
                onlySystemAdmin
                path="/insight/:id"
                component={() => <InsightsForm />}
                theme={ACTheme}
              />
              <Route
                onlySystemAdmin
                path="/admins-managment"
                component={() => <AdminsManagment />}
                theme={ACTheme}
              />
              <Route
                onlySystemAdmin
                path="/admin-managment"
                component={() => <AdminsForm />}
                theme={ACTheme}
              />
              <Route
                onlySystemAdmin
                path="/admin-managment/:id"
                component={() => <AdminsForm />}
                theme={ACTheme}
              />
            </Switch>
          </div>
        </div>
      </ComponentsProvider>
    </ThemeProvider>
  );
}

export default DashboardLayout;
