import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import {
  LoadingIndicatorLogo,
  MinimumDisplayTime,
  Snackbar,
} from '@advisorycloud/react-ui-components';

const useStyles = () => ({
  loadingIndicatorPosition: {
    width: '100%',
    height: '500px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  circularProgressPosition: {
    position: 'relative',
    margin: 'auto',
    width: '40px',
    height: '40px',
  },
});

export const EndpointCallerLoadingIcon = {
  LOGO: 'logo',
  CIRCULAR: 'circular',
  NONE: 'none',
};

function EndpointCaller(props) {
  const {
    endPointCallObjectArray,
    onEndpointCallsFinished,
    executeCalls,
    loadingIconType,
  } = props;

  const { t } = useTranslation();
  const classes = useStyles();
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [errorSnackbarMessage, setErrorSnackbarMessage] = useState(
    t('SOMETHING_WENT_WRONG')
  );
  const endpointResponseArray = new Array([endPointCallObjectArray.length]);
  let loading = executeCalls;
  let displayTimerActive = executeCalls;
  let completedEndpointCalls = 0;
  let selectedLoadingIconType = loadingIconType;

  if (selectedLoadingIconType !== 'circular') {
    selectedLoadingIconType = 'logo';
  }

  function MakeCall(endPointCallObject, index) {
    endPointCallObject
      .endPointCall(endPointCallObject.endPointCallParameters)
      .then((response) => {
        endpointResponseArray[index] = response;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
        endpointResponseArray[index] = undefined;
        setErrorSnackbarMessage(t('SOMETHING_WENT_WRONG'));
        setShowErrorSnackbar(true);
      })
      .finally(() => {
        completedEndpointCalls += 1;

        if (completedEndpointCalls >= endPointCallObjectArray.length) {
          loading = false;

          if (!displayTimerActive) {
            onEndpointCallsFinished(endpointResponseArray);
          }
        }
      });
  }

  useEffect(() => {
    if (executeCalls) {
      for (let i = 0; i < endPointCallObjectArray.length; i += 1) {
        MakeCall(endPointCallObjectArray[i], i);
      }
    }
  }, []);

  return (
    <>
      <Snackbar
        open={showErrorSnackbar}
        text={errorSnackbarMessage}
        severity="error"
        onClose={() => setShowErrorSnackbar(false)}
      />
      <div
        style={
          selectedLoadingIconType === 'circular'
            ? classes.circularProgressPosition
            : {}
        }
      >
        <MinimumDisplayTime
          displayTime={1000}
          visible={loading || displayTimerActive}
          onHide={() => {
            displayTimerActive = false;

            if (!loading) {
              onEndpointCallsFinished(endpointResponseArray);
            }
          }}
        >
          {selectedLoadingIconType === 'logo' ? (
            <div style={classes.loadingIndicatorPosition}>
              <LoadingIndicatorLogo
                size={200}
                iconFontSize={93}
                iconRight={105}
              />
            </div>
          ) : (
            <div>
              <CircularProgress />
            </div>
          )}
        </MinimumDisplayTime>
      </div>
    </>
  );
}

export default EndpointCaller;
