import React from 'react';
import { Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { ACTheme } from '../../themes';
import AccountValidator from './AccountValidator';
import EmptyLayout from '../../Layout/Empty';

function ProtectedRoute({
  component,
  theme,
  isPublic,
  onlySystemAdmin,
  isAdvisorCheckout,
  isCompanyCheckout,
  suppressOnboardingProgressCheck,
  layout,
  ...rest
}) {
  const Layout = layout || EmptyLayout;

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout theme={theme}>
          <ThemeProvider theme={ACTheme}>
            <AccountValidator
              {...props}
              theme={theme}
              component={component}
              isPublic={isPublic}
              onlySystemAdmin={onlySystemAdmin}
              isAdvisorCheckout={isAdvisorCheckout}
              isCompanyCheckout={isCompanyCheckout}
              suppressOnboardingProgressCheck={suppressOnboardingProgressCheck}
            />
          </ThemeProvider>
        </Layout>
      )}
    />
  );
}

export default ProtectedRoute;
