import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { Divider } from '@mui/material';
import { Button } from '@advisorycloud/react-ui-components';
import RadioGroup from '../../Components/RadioGroup';
import CompanyPlanInformation from '../../Sections/CompanyPlanInformation';
import UserInformation from '../../Sections/UserInformation';
import PaymentInformation from '../../Sections/PaymentInformation';

const defaultInitialValues = {
  firstName: '',
  lastName: '',
  emailAddress: '',
  phone: '',
  companyName: '',
  countryCode: 'US',
  billingAddress: '',
  city: '',
  state: '',
  zipCode: '',
  numberOfSeats: '',
  recurlyAccountID: '',
  boardType: '',
};

function CheckoutFormBuilder(props) {
  const {
    classes,
    recurlyPlanMap,
    planRadioOptions,
    freeTrialRadioOptions,
    handleSubmit,
    formRef,
    setFormValues,
    processingData,
    formInitialValues,
    setBoardSeatsAllowedCount,
  } = props;
  const [cardDataReady, setCardDataReady] = useState(false);
  const [cardDataNeeded, setCardDataNeeded] = useState(false);
  const [showRecurlyAccountIDTextField, setShowRecurlyAccountIDTextField] =
    useState(false);
  const [showUserSegmentField, setShowUserSegmentField] =
    useState(false);
  const [showNumberOfSeatsTextField, setShowNumberOfSeatsTextField] =
    useState(false);
  const [showPaymentInformationSection, setShowPaymentInformationSection] =
    useState(false);
  const [checkoutFormSchema, setCheckoutFormSchema] = useState();
  // Keeping commented boardTypeOptions items fi it is required its set up to the isPublic property
  // const [boardTypeOptions, setBoardTypeOptions] = useState([]);
  const [selectedPlanCode, setSelectedPlanCode] = useState('');
  const [initialRecurlyAccountID, setInitialRecurlyAccountID] = useState('');
  const location = useLocation();
  const { t } = useTranslation();

  const baseFieldsSchema = {
    firstName: Yup.string().required(t('VALIDATION_REQUIRED')),
    lastName: Yup.string().required(t('VALIDATION_REQUIRED')),
    emailAddress: Yup.string()
      .required(t('VALIDATION_REQUIRED'))
      .matches(
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
        t('VALIDATION_INVALID_EMAIL')
      ),
    phone: Yup.string()
      .required(t('VALIDATION_REQUIRED'))
      .matches(/^[0-9]+$/, t('VALIDATION_INVALID_NUMBER'))
      .test('len', t('VALIDATION_MUST_BE_AT_LEAST_7_DIGITS'), (val) => {
        if (val) return !(val.toString().length < 7);
        return false;
      }),
    companyName: Yup.string().required(t('VALIDATION_REQUIRED')),
    countryCode: Yup.string().required(t('VALIDATION_REQUIRED')),
  };

  const paymentInformationFieldsSchema = {
    billingAddress: Yup.string().required(t('VALIDATION_REQUIRED')),
    city: Yup.string().required(t('VALIDATION_REQUIRED')),
    state: Yup.string().required(t('VALIDATION_REQUIRED')),
    zipCode: Yup.string().required(t('VALIDATION_REQUIRED')),
  };

  const numberOfSeatsFieldSchema = {
    numberOfSeats: Yup.string()
      .required(t('VALIDATION_REQUIRED'))
      .matches(/^[1-9][0-9]*$/, t('VALIDATION_INVALID_AMOUNT')),
  };

  const recurlyAccountIDFieldSchema = {
    recurlyAccountID: Yup.string().required(t('VALIDATION_REQUIRED')),
  };

  // const boardTypeFieldSchema = {
  //   boardType: Yup.string().required(t('VALIDATION_REQUIRED')),
  // };

  function changeSelectedPlanCode(newRecurlyPlanCode) {
    const { recurlyAction, isPrivate, boardSeatsAllowedCount } =
      recurlyPlanMap[newRecurlyPlanCode];
    setSelectedPlanCode(newRecurlyPlanCode);

    let newCheckoutFormSchema = {
      ...baseFieldsSchema,
      // ...boardTypeFieldSchema,
    };
    let recurlyFieldsSchema;

    if (recurlyAction === 'DO_NOTHING' || recurlyAction === 'NOTHING') {
      recurlyFieldsSchema = recurlyAccountIDFieldSchema;
      setShowRecurlyAccountIDTextField(true);
      setShowUserSegmentField(true);
      setShowPaymentInformationSection(false);
      setCardDataNeeded(false);
      setInitialRecurlyAccountID(formInitialValues.recurlyAccountID || '');
    } else {
      recurlyFieldsSchema = paymentInformationFieldsSchema;
      setShowRecurlyAccountIDTextField(false);
      setShowUserSegmentField(false);
      setShowPaymentInformationSection(true);
      setCardDataNeeded(true);
      setInitialRecurlyAccountID('');
    }

    newCheckoutFormSchema = {
      ...newCheckoutFormSchema,
      ...recurlyFieldsSchema,
    };

    // if (!salesforceValues || !salesforceValues.tier) {
    //   newCheckoutFormSchema = {
    //     ...newCheckoutFormSchema,
    //     ...tierFieldSchema,
    //   };
    //   setShowTierDropdown(true);
    // } else {
    //   setShowTierDropdown(false);
    // }

    if (Number(boardSeatsAllowedCount)) {
      setBoardSeatsAllowedCount(boardSeatsAllowedCount);
    }

    const newBoardTypeOptions = [{ label: 'Public', value: 'public' }];

    if (isPrivate) {
      newBoardTypeOptions.push({ label: 'Private', value: 'private' });
    }

    setCheckoutFormSchema(Yup.object().shape(newCheckoutFormSchema));
  }

  function handleCardDataChange(e) {
    setCardDataReady(e.valid);
  }

  const initialFormValues = {
    ...defaultInitialValues,
    ...formInitialValues,
    recurlyAccountID: initialRecurlyAccountID,
  };

  return (
    <>
      <RadioGroup
        className={classes.addMargin}
        id="planCode1"
        labelText={t('FIELD_PLAN')}
        radioOptions={planRadioOptions}
        value={selectedPlanCode}
        onChange={(event) => {
          changeSelectedPlanCode(event.target.value);
          location.state = { formSchemaChange: true };
          setFormValues((prevValue) => ({
            ...prevValue,
            planCode: event.target.value,
          }));
        }}
        errorText={selectedPlanCode ? '' : t('VALIDATION_SELECT_PLAN')}
      />
      {freeTrialRadioOptions.length > 0 && (
        <RadioGroup
          className={classes.addMargin}
          id="planCode2"
          labelText={t('FIELD_FREE_TRIAL')}
          radioOptions={freeTrialRadioOptions}
          value={selectedPlanCode}
          onChange={(event) => {
            changeSelectedPlanCode(event.target.value);
            location.state = { formSchemaChange: true };
            setFormValues((prevValue) => ({
              ...prevValue,
              planCode: event.target.value,
            }));
          }}
          errorText={selectedPlanCode ? '' : t('VALIDATION_SELECT_PLAN')}
        />
      )}
      {selectedPlanCode ? (
        <Formik
          initialValues={initialFormValues}
          validationSchema={checkoutFormSchema}
          validateOnMount
          validateOnChange
          validateOnBlur
        >
          {(formik) => {
            const {
              handleChange,
              handleBlur,
              values,
              isValid,
              setFieldValue,
              errors,
              validateForm,
            } = formik;

            if (location.state && location.state.formSchemaChange) {
              location.state.formSchemaChange = false;
              validateForm();
            }

            return (
              <Form
                ref={formRef}
                onChange={() => {
                  setFormValues({
                    ...values,
                    ...{
                      planCode: selectedPlanCode,
                      skipRecurlyCall: !showPaymentInformationSection,
                    },
                  });
                }}
              >
                <div style={values.planCode ? {} : classes.hide}>
                  <Divider sx={classes.divider} />
                  <CompanyPlanInformation
                    values={values}
                    errors={errors}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    // boardTypeOptions={boardTypeOptions}
                    showRecurlyAccountIDTextField={
                      showRecurlyAccountIDTextField
                    }
                    showUserSegmentField={showUserSegmentField}
                    showNumberOfSeatsTextField={showNumberOfSeatsTextField}
                    validateForm={validateForm}
                    setFieldValue={setFieldValue}
                  />
                  <UserInformation
                    values={values}
                    errors={errors}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    validateForm={validateForm}
                  />
                  {showPaymentInformationSection ? (
                    <PaymentInformation
                      values={values}
                      errors={errors}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      handleSubmit={handleSubmit}
                      handleCardDataChange={handleCardDataChange}
                      validateForm={validateForm}
                      setFieldValue={setFieldValue}
                    />
                  ) : null}
                  <Divider sx={classes.divider} />
                  <Button
                    color="primary"
                    disabled={
                      !isValid ||
                      (!cardDataReady && cardDataNeeded) ||
                      processingData
                    }
                    onClick={(event) => {
                      handleSubmit(event);
                    }}
                    loading={processingData ? 1 : 0}
                  >
                    {t('CHECKOUT_BUTTON_TEXT')}
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      ) : null}
    </>
  );
}

export default CheckoutFormBuilder;
