import React from 'react';
import { IconUnderlay } from '@advisorycloud/react-ui-components';
import { Paper, Typography } from '@mui/material';
import PriorityHighIcon from '@mui/icons-material/PriorityHighOutlined';
import { useTranslation } from 'react-i18next';

const useStyles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '250px',
    width: '580px',
    alignItems: 'center',
    paddingTop: '30px',
  },
  titleContainer: {
    maxWidth: '360px',
    marginTop: '20px',
    marginBottom: '40px',
    textAlign: 'center',
  },
  retryButton: {
    backgroundColor: 'transparent',
    border: 'none',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px',
    color: '#0F78FD',
  },
});

function UnhandledError({ isSM, onRetry }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Paper sx={classes.container} elevation={isSM ? 0 : 2}>
      <IconUnderlay size={80}>
        <PriorityHighIcon color="error" style={{ fontSize: '60px' }} />
      </IconUnderlay>

      <div style={classes.titleContainer}>
        <Typography variant="h2">{t('LOGIN-ATTEMPT-ERROR-TITLE')}</Typography>
      </div>

      <button onClick={onRetry} style={classes.retryButton} type="submit">
        {t('LOGIN-ATTEMPT-ERROR-BUTTON-TEXT')}
      </button>
    </Paper>
  );
}

export default UnhandledError;
