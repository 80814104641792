import React, { useEffect, useState } from 'react';
import { useMediaQuery, Paper } from '@mui/material';
import { AdvisoryCloudLogo } from '@advisorycloud/react-ui-components';

const useStyles = (isSM) => ({
  card: {
    backgroundColor: 'white',
    borderRadius: '5px',
    marginTop: '50px',
    width: !isSM ? '600px' : '100%',
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingTop: '40px',
    marginBottom: '50px',
    width: 'auto',
    backgroundColor: isSM ? 'white' : null,
  },
  header: {
    display: 'flex',
    marginTop: '30px',
    marginBottom: '-10px',
  },
});

const CardScene = (props) => {
  const { children, paperId, header, footer } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const [isHeader, setIsHeader] = useState(false);

  useEffect(() => {
    if (header === undefined || header === null) {
      setIsHeader(false);
    } else {
      setIsHeader(true);
    }
  }, []);

  const Header = () => {
    return (
      <>
        <div style={classes.header}>{header}</div>
      </>
    );
  };

  return (
    <>
      <div style={classes.container}>
        <AdvisoryCloudLogo />
        {isHeader && <Header />}
        <Paper id={paperId} elevation={!isSM ? 3 : 0} sx={classes.card}>
          {children}
        </Paper>
        {footer}
      </div>
    </>
  );
};

export default CardScene;
