export const AccountType = {
  COMPANY: 'company',
  ADVISOR: 'advisor',
  CONTACT: 'companyContact',
};

export const PostType = {
  QUESTION: 'question',
  UPDATE: 'update',
};

export const NullCountry = {
  NULL_COUNTRY: '00',
};

export const ResponseStatus = {
  SUCCESS: 'success',
  FAIL: 'fail',
};

export const BoardType = {
  PUBLIC: 'public',
  PRIVATE: 'private',
};

export const UserStatus = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  PAUSED: 'paused',
  NEW: 'new',
};

export const RecurlyPlanTypes = {
  B2B: 'b2b',
  B2C: 'b2c',
};

export const TypeName = {
  ADVISOR: 'Advisor',
  COMPANY_CONTACT: 'CompanyContact',
  COMPANY: 'Company',
};

export const opportunityStageTypes = {
  DISCOVERY: 'discovery',
  SEEKING_ADVISORS: 'seeking_advisors',
  POSITION_FILLED: 'position_filled',
  DORMANT: 'dormant',
  PENDING_CANCELLATION: 'pending_cancellation',
};
