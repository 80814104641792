export const isFreeTrialTypes = {
  YES: 'Yes',
  NO: 'No',
};

export function isNil(value) {
  if (value === undefined || value === null) {
    return true;
  }
  return false;
}
