import React, { useState, useRef } from 'react';
import { withRouter } from 'react-router';
import { Typography, Link } from '@mui/material';
import { withStyles } from '@mui/styles';
import { TextField } from 'formik-mui';
import { Formik, Form, Field } from 'formik';
import { useLocation } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { Button, Snackbar } from '@advisorycloud/react-ui-components';
import * as Yup from 'yup';
import {
  LoginResponses,
  logIn,
  LoginResponseTypes,
} from '../../services/Authentication';
import { useAccount } from '../../contexts/Account';

const useStyles = () => ({
  field: {
    width: '100%',
    color: 'error.main',
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginTop: '1vh',
  },
  inputLabel: {
    fontWeight: 100,
    fontSize: '2vh',
  },
  button: {
    marginTop: '3vh',
  },
  signInLink: {
    color: 'primary.main',
    textDecoration: 'underline',
  },
});

const FieldWrapper = withStyles((theme) => ({
  root: {
    '& .MuiInputBase-input': {
      color: theme.palette.common.black,
    },

    '& .MuiInputBase-input:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 100px #fafafa inset',
    },

    '& .MuiInput-underline:hover:before': {
      borderBottomColor: theme.palette.primary.main,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.primary.main,
    },

    '& .Mui-error:after': {
      borderBottomColor: theme.palette.error.main,
    },
  },
}))(Field);
Yup.addMethod(
  Yup.string,
  'invalidCredentials',
  function method(errorMessage, message) {
    return this.test('error-code', errorMessage, function tested() {
      const { path, createError } = this;

      if (
        errorMessage === LoginResponses.INCORRECT_USERNAME_PASSWORD_CODE ||
        errorMessage === LoginResponses.USER_NOT_FOUND_CODE ||
        errorMessage === LoginResponses.PASSWORD_RESET_REQUIRED_EXCEPTION
      ) {
        return createError({
          path,
          message,
        });
      }

      return true;
    });
  }
);
Yup.addMethod(
  Yup.string,
  'maxAttempts',
  function method(errorMessage, message) {
    return this.test('error-code', errorMessage, function tested() {
      const { path, createError } = this;

      if (errorMessage === LoginResponses.PASSWORD_ATTEMPTS_EXCEEDED) {
        return createError({
          path,
          message,
        });
      }

      return true;
    });
  }
);
Yup.addMethod(
  Yup.string,
  'notAuthorized',
  function method(errorMessage, message) {
    return this.test('error-code', errorMessage, function tested() {
      const { path, createError } = this;
      if (errorMessage === LoginResponses.NOT_AUTHORIZED_EXCEPTION) {
        return createError({
          path,
          message,
        });
      }

      return true;
    });
  }
);

function FormLogin(props) {
  const location = useLocation();
  const { authenticate } = useAccount();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
  const { usernameProps, passwordProps } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const formikRef = useRef();
  const [loading, setLoading] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email');

  function environmentUrl() {
    if (process.env.NODE_ENV === 'development') {
      return 'https://app-dev.advisorycloud.com';
    }
    if (process.env.NODE_ENV === 'production') {
      return 'https://app.advisorycloud.com';
    }
    if (process.env.NODE_ENV === 'test') {
      return 'https://app-test.advisorycloud.com';
    }
    return null;
  }

  function renderSignUpLink() {
    return (
      <Trans
        t={t}
        i18nKey="SIGN_UP_LINK_TEXT"
        components={{
          ln: (
            <Link
              sx={classes.signInLink}
              href="https://www.advisorycloud.com"
            />
          ),
        }}
      />
    );
  }

  const SignupSchema = Yup.object().shape({
    username: Yup.string()
      .email(`${t('USERNAME_REQUIRED')}`)
      .required(`${t('USERNAME_REQUIRED')}`),
    password: Yup.string()
      .required(`${t('PASSWORD_REQUIRED')}`)
      .invalidCredentials(errorMessage, t('INVALID_CREDENTIALS'))
      .maxAttempts(errorMessage, t('MAX_PASSWORD_ATTEMPTS'))
      .notAuthorized(errorMessage, renderSignUpLink()),
  });

  async function submitForm(data) {
    try {
      setLoading(true);

      const loginResponse = await logIn(
        data.username,
        data.password,
        authenticate
      );

      if (loginResponse.response === LoginResponseTypes.NO_ADMIN) {
        window.location.replace(environmentUrl());
      }
      if (
        loginResponse.response === LoginResponseTypes.ERROR_IN_COGNITO_RESPONSE
      ) {
        setErrorMessage(loginResponse.message);
      } else if (
        loginResponse.response === LoginResponseTypes.COGNITO_UNKNOW_ERROR
      ) {
        setIsSnackbarVisible(true);
        window.scrollTo(0, 0);
      }

      if (formikRef.current) {
        formikRef.current.validateForm();
      }

      if (loginResponse.response === LoginResponseTypes.LOGIN_SUCCESS) {
        if (props.history.location.state && props.history.location.state.from) {
          props.history.push(
            props.history.location.state.from.pathname +
              props.history.location.state.from.search
          );
        } else {
          props.history.push('/dashboard');
        }
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setIsSnackbarVisible(true);
      window.scrollTo(0, 0);
    }
  }

  function handleFormChange() {
    setErrorMessage(null);

    if (formikRef.current) {
      formikRef.current.validateForm();
    }
  }

  return (
    <>
      <Snackbar
        text={t('COGNITO_FAILED_CONNECTION_SNACKBAR')}
        onClose={() => setIsSnackbarVisible(false)}
        open={isSnackbarVisible}
        severity="error"
      />

      <Formik
        initialValues={{
          username: email || '',
          password: '',
        }}
        innerRef={formikRef}
        validationSchema={SignupSchema}
        onSubmit={submitForm}
      >
        {(formik) => {
          const { isValid, touched, handleSubmit } = formik;
          return (
            <Form
              data-testid="formLogin"
              style={classes.form}
              onChange={handleFormChange}
              onSubmit={handleSubmit}
            >
              <FieldWrapper
                data-testid="txtUsername"
                sx={classes.field}
                component={TextField}
                variant="standard"
                type="email"
                label={t('USERNAME')}
                name="username"
                autoComplete="username"
                inputProps={{
                  'aria-label': `${t('ARIA_LABEL_LOGIN_USERNAME')}`,
                  'aria-required': 'true',
                }}
                {...usernameProps}
              />
              <FieldWrapper
                data-testid="txtPassword"
                sx={classes.field}
                component={TextField}
                variant="standard"
                type="password"
                label={t('PASSWORD')}
                name="password"
                style={{ marginTop: '2vh' }}
                autoComplete="current-password"
                inputProps={{
                  'aria-label': `${t('ARIA_LABEL_LOGIN_PASSWORD')}`,
                  'aria-required': 'true',
                }}
                {...passwordProps}
              />
              <div style={classes.button}>
                <Button
                  data-testid="btnSubmit"
                  type="submit"
                  color="primary"
                  style={{ borderRadius: 10 }}
                  loading={loading ? 1 : 0}
                  disabled={!(isValid && Object.keys(touched).length >= 1)}
                >
                  <Typography variant="button">{t('LOGIN_TITLE')}</Typography>
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default withRouter(FormLogin);
