import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { RecurlyProvider, Elements } from '@recurly/react-recurly';
import { useTranslation } from 'react-i18next';
import CheckoutForm from '../../components/CheckoutForm';
import CompanyCheckoutForm from '../../components/CheckoutForm/Forms/CompanyCheckoutForm';

function CompanyCheckout() {
  const { t } = useTranslation();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const formInitialValues = {
    companyName: queryParams.get(`company`) || ``,
    emailAddress: queryParams.get(`email`) || ``,
    firstName: queryParams.get(`first_name`) || ``,
    lastName: queryParams.get(`last_name`) || ``,
    recurlyAccountID: queryParams.get(`recurly_account_id`) || ``,
    phone: queryParams.get(`phone`) || ``,
  };

  return (
    <>
      <Helmet>
        <title>{t('COMPANY_CHECKOUT_HELMET_TITLE')}</title>
      </Helmet>
      <RecurlyProvider publicKey={process.env.REACT_APP_RECURLY_PUBLIC_KEY}>
        <Elements>
          <div style={{ width: '100%' }}>
            <CheckoutForm
              FormTag={CompanyCheckoutForm}
              formInitialValues={formInitialValues}
              accountType="B2B"
              purchasingChannel="COC"
            />
          </div>
        </Elements>
      </RecurlyProvider>
    </>
  );
}

export default CompanyCheckout;
