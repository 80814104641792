import { API, graphqlOperation } from 'aws-amplify';

const updateAdvisorContactMutation = `
  mutation updateAdvisor(
    $id: String!
    $givenName: String!
    $surName: String!
    $phoneNumber: String!
    $phoneCountryCode: String!
    $publicProfileUrl: String!
    $freeB2BAccountStatus: String
    $freeTrialEndsOn: AWSDateTime
    $removeFreeTrial: Boolean
    $featuredAdvisor: Boolean
    $privateProfile: Boolean
  ) {
    updateAdvisor(
      id: $id,
      givenName: $givenName,
      surName: $surName,
      phoneNumber: $phoneNumber,
      phoneCountryCode: $phoneCountryCode,
      publicProfileUrl: $publicProfileUrl,
      freeB2BAccountStatus: $freeB2BAccountStatus,
      freeTrialEndsOn: $freeTrialEndsOn,
      removeFreeTrial: $removeFreeTrial,
      featuredAdvisor: $featuredAdvisor,
      privateProfile: $privateProfile
    ) {
      id
    }
  }
`;
const updateAdvisorOnboardingStatusMutation = `
  mutation updateAdvisor(
    $id: String!,
    $onboardingStatus: String,
    $onboardingProcess: String
  ) {
    updateAdvisor(
      id: $id,
      onboardingStatus: $onboardingStatus,
      onboardingProcess: $onboardingProcess
    ) {
      id
      onboardingStatus
      onboardingProcess
    }
  }
`;

const updateAdvisorContactEmail = `
  mutation($accessToken: String!, $advisorId: String!, $email: String!) {
    updateAdvisorEmail(
      accessToken: $accessToken,
      advisorId: $advisorId,
      email: $email,
    )
  }
`;

const updateAdvisorPlan = `
mutation ($advisorId: String!, $recurlyPlanCode: String!) {
    updateAdvisorPlanCode (
    advisorId: $advisorId,
    recurlyPlanCode: $recurlyPlanCode
  ){
    recurlyPlanCode
  }
}
`;

export function updateAdvisorPlanCall({ ADVISOR_ID, PLAN_CODE }) {
  return API.graphql(
    graphqlOperation(updateAdvisorPlan, {
      advisorId: ADVISOR_ID,
      recurlyPlanCode: PLAN_CODE,
    })
  );
}

export function updateAdvisorProfileData(advisor) {
  return API.graphql(graphqlOperation(updateAdvisorContactMutation, advisor));
}

export function updateAdvisorOnboardingStatus({
  ADVISOR_ID,
  ONBOARDING_STATUS,
  ONBOARDING_PROCESS,
}) {
  return API.graphql(
    graphqlOperation(updateAdvisorOnboardingStatusMutation, {
      id: ADVISOR_ID,
      onboardingStatus: ONBOARDING_STATUS,
      onboardingProcess: ONBOARDING_PROCESS,
    })
  );
}

export function updateAdvisorContactEmailCall({
  ACCESS_TOKEN,
  ADVISOR_ID,
  NEW_EMAIL,
}) {
  return API.graphql(
    graphqlOperation(updateAdvisorContactEmail, {
      accessToken: ACCESS_TOKEN,
      advisorId: ADVISOR_ID,
      email: NEW_EMAIL,
    })
  );
}

