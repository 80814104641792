import { Storage } from 'aws-amplify';

export async function uploadToS3({
  BLOB_LOAD,
  CONTENT_TYPE,
  FILE_ID,
  S3_BUCKET,
}) {
  await Storage.put(FILE_ID, BLOB_LOAD, {
    bucket: S3_BUCKET,
    region: process.env.REACT_APP_APPSYNC_REGION,
    contentType: CONTENT_TYPE,
    cacheControl: 'no-cache',
  });
}
