import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Grid, useMediaQuery } from '@mui/material';
import { Snackbar } from '@advisorycloud/react-ui-components';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Form from './Form';
import { useAccount } from '../../contexts/Account';
import { Transitions } from '../../services/Transition';

const useStyles = (isSM) => ({
  container: {
    marginTop: '10%',
    paddingLeft: isSM ? '25px' : '0px',
    paddingRight: isSM ? '25px' : '0px',
  },
});

function Login(props) {
  const location = useLocation();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const { t } = useTranslation();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const queryParamsState = new URLSearchParams(location.state);
  const { isAuthenticated, currentUserInfo } = useAccount();
  const [environment] = useState(() => {
    if (process.env.NODE_ENV === 'development') {
      return 'https://app-dev.advisorycloud.com';
    }
    if (process.env.NODE_ENV === 'production') {
      return 'https://app.advisorycloud.com';
    }
    if (process.env.NODE_ENV === 'test') {
      return 'https://app-test.advisorycloud.com';
    }
    return null;
  });

  function handleClose() {
    setShowSnackbar(false);
  }

  const [updateSuccess] = useState(() => {
    const isPasswordUpdated =
      queryParamsState.get('passwordUpdated') ===
      Transitions.Types.PASSWORD_UPDATED;

    if (isPasswordUpdated) {
      return Transitions.Types.PASSWORD_UPDATED;
    }
    return null;
  });

  useEffect(() => {
    if (updateSuccess === Transitions.Types.PASSWORD_UPDATED) {
      setShowSnackbar(true);
      window.scrollTo(0, 0);
      props.history.replace(location.pathname, null);
    }
    if (isAuthenticated) {
      const cognitoGroups =
        currentUserInfo.accessToken.payload['cognito:groups'];
      if (!cognitoGroups.includes('admin')) {
        window.location.href = environment;
      } else {
        props.history.push('/dashboard');
      }
    }
  }, []);

  return (
    <>
      <Snackbar
        open={showSnackbar}
        text={t('UPDATE_SUCCESSFUL')}
        severity="success"
        onClose={handleClose}
      />

      <Helmet>
        <title>{t('LOGIN_TITLE')}</title>
        <meta name="description" content={t('LOGIN_HELMET_META')} />
      </Helmet>

      <Grid container data-testid="login-main-container">
        <Grid item xs={12} sm={12} md={12} lg={12} sx={classes.container}>
          <Form />
        </Grid>
      </Grid>
    </>
  );
}

export default Login;
