import React from 'react';
import { IconUnderlay } from '@advisorycloud/react-ui-components';
import { Paper, Typography } from '@mui/material';
import PriorityHighIcon from '@mui/icons-material/PriorityHighOutlined';
import { useTranslation, Trans } from 'react-i18next';

const useStyles = (isSM) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '330px',
    width: '580px',
    alignItems: 'center',
    paddingTop: '30px',
  },
  titleContainer: {
    marginBottom: '10px',
    marginTop: '20px',
    textAlign: 'center',
  },
  subtitleContainer: {
    maxWidth: '400px',
    marginTop: '20px',
    marginBottom: '30px',
    textAlign: 'center',
  },
  supportLabelContainer: {
    display: 'flex',
    flexDirection: isSM ? 'column' : 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  supportButton: {
    padding: '0px',
    border: 'none',
    backgroundColor: 'transparent',
    height: '13px',
    marginBottom: '4px',
    minWidth: '22px',
    color: '#0F78FD',
    display: 'inline-flex',
    cursor: 'pointer',
  },
  supportLabel: {
    display: 'inline-flex',
    marginLeft: '2px',
    marginTop: '4px',
    color: 'secondary.dark',
  },
  priorityIcon: {
    fontSize: '60px',
  },
});

function ExpiredError({ isSM, onResend, email }) {
  const classes = useStyles(isSM);
  const { t } = useTranslation();

  return (
    <Paper sx={classes.container} elevation={isSM ? 0 : 2}>
      <IconUnderlay size={80}>
        <PriorityHighIcon color="error" style={classes.priorityIcon} />
      </IconUnderlay>

      <div style={classes.titleContainer}>
        <Typography color="textSecondary" variant="h2">
          {t('EXPIRED-LINK-TOP-TITLE')}
        </Typography>
        <Typography color="textSecondary" variant="h2">
          {t('EXPIRED-LINK-TITLE', { email })}
        </Typography>
      </div>
      <div style={classes.subtitleContainer}>
        <Typography color="textSecondary" variant="h2">
          {t('EXPIRED-LINK-SUBTITLE')}
        </Typography>
      </div>
      <Trans
        t={t}
        i18nKey="ERROR-SUPPORT-LABEL"
        components={{
          d: <div style={classes.supportLabelContainer} />,
          btn: (
            <button
              id="resend-confirmation-email-button"
              type="submit"
              onClick={onResend}
              style={classes.supportButton}
            >
              <fragment />
            </button>
          ),
          nth: <Typography variant="body1" />,
          th1: <Typography sx={classes.supportLabel} variant="body1" />,
        }}
      />
    </Paper>
  );
}

export default ExpiredError;
