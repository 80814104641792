import React, { useState, useEffect } from 'react';
import { BrowserRouter, Redirect, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  ACTheme,
  SEOTheme,
  ComponentsProvider,
  LoadingIndicatorLogo,
} from '@advisorycloud/react-ui-components';
import Route from './components/ProtectedRoute';
import ConsoleLayout from './Layout/Console';
import Login from './scenes/Login';
import initi18n from './services/Translation';
import ScrollToTop from './services/ScrollTop';
import PasswordSet from './scenes/PasswordSet';
import { useAccount } from './contexts/Account';
import PasswordReset from './scenes/PasswordReset';
import SharedCredentials from './scenes/SharedCredentials';

const useStyles = () => ({
  loadingContainer: {
    alignItems: 'center',
    backgroundColor: '#FFF',
    bottom: '0px',
    display: 'flex',
    justifyContent: 'center',
    left: '0px',
    position: 'fixed',
    right: '0px',
    top: '0px',
  },
});

function Router({ theme }) {
  const classes = useStyles();
  const { authenticate } = useAccount();
  const [isAppDataReady, setIsAppDataReady] = useState(false);
  const [complete, setComplete] = useState(false);

  async function initializeAppData() {
    await initi18n();
    await authenticate();
    setIsAppDataReady(true);
  }

  useEffect(() => {
    initializeAppData();
  }, []);

  useEffect(() => {
    if (isAppDataReady) {
      setComplete(true);
    }
  }, [isAppDataReady]);

  if (!complete) {
    return (
      <div style={classes.loadingContainer}>
        <LoadingIndicatorLogo iconFontSize={134} iconRight={162} />
      </div>
    );
  }

  return (
    <ComponentsProvider theme={theme}>
      <BrowserRouter>
        <Helmet
          defaultTitle={process.env.REACT_APP_APPLICATION_NAME}
          titleTemplate={process.env.REACT_APP_APPLICATION_TITLE_TEMPLATE}
        />
        <ScrollToTop>
          <Switch>
            <Route
              isPublic
              exact
              path="/login"
              component={Login}
              theme={SEOTheme}
            />
            <Route
              isPublic
              path="/password/set"
              component={PasswordSet}
              theme={ACTheme}
            />
            <Route
              isPublic
              path="/password/reset"
              component={PasswordReset}
              theme={ACTheme}
            />
            <Route
              exact
              path="/dashboard"
              layout={ConsoleLayout}
              theme={ACTheme}
            />
            <Route
              exact
              path="/checkout/master"
              layout={ConsoleLayout}
              theme={ACTheme}
            />
            <Route
              isAdvisorCheckout
              exact
              path="/checkout/advisor"
              layout={ConsoleLayout}
              theme={ACTheme}
            />
            <Route
              isCompanyCheckout
              exact
              path="/checkout/company"
              layout={ConsoleLayout}
              theme={ACTheme}
            />
            <Route
              isCompanyCheckout
              exact
              path="/checkout/company/last"
              layout={ConsoleLayout}
              theme={ACTheme}
            />
            <Route
              exact
              path="/updateCloudsearch"
              layout={ConsoleLayout}
              theme={ACTheme}
            />
            <Route
              exact
              path="/sharedCredentials"
              component={SharedCredentials}
              theme={ACTheme}
            />
            <Route
              exact
              path="/search"
              layout={ConsoleLayout}
              theme={ACTheme}
            />
            <Route
              onlySystemAdmin
              exact
              path="/plan-mapping-table"
              layout={ConsoleLayout}
              theme={ACTheme}
            />
            <Route
              onlySystemAdmin
              exact
              path="/plan-mapping-table/add"
              layout={ConsoleLayout}
              theme={ACTheme}
            />
            <Route
              onlySystemAdmin
              exact
              path="/plan-mapping-table/edit/:plancodeId"
              layout={ConsoleLayout}
              theme={ACTheme}
            />
            <Route
              onlySystemAdmin
              exact
              path="/insights"
              layout={ConsoleLayout}
              theme={ACTheme}
            />
            <Route
              onlySystemAdmin
              exact
              path="/insight"
              layout={ConsoleLayout}
              theme={ACTheme}
            />
            <Route
              onlySystemAdmin
              exact
              path="/insight/:id"
              layout={ConsoleLayout}
              theme={ACTheme}
            />
            <Route
              onlySystemAdmin
              exact
              path="/admins-managment"
              layout={ConsoleLayout}
              theme={ACTheme}
            />
            <Route
              onlySystemAdmin
              exact
              path="/admin-managment"
              layout={ConsoleLayout}
              theme={ACTheme}
            />
            <Route
              onlySystemAdmin
              exact
              path="/admin-managment/:id"
              layout={ConsoleLayout}
              theme={ACTheme}
            />
            <Redirect from="/" to="/login" />
          </Switch>
        </ScrollToTop>
      </BrowserRouter>
    </ComponentsProvider>
  );
}

export default Router;
