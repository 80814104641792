import React from 'react';
import { FormControlLabel, Checkbox as MaterialCheckbox } from '@mui/material';

function Checkbox(props) {
  const { labelText, onChange } = props;

  return (
    <FormControlLabel
      sx={{
        label: {
          fontWeight: 'bold',
        },
      }}
      control={<MaterialCheckbox />}
      label={labelText}
      onChange={(event) => onChange(event)}
    />
  );
}

export default Checkbox;
