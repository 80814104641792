import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { ArrowDropUp, ArrowDropDown, Sort } from '@mui/icons-material';
import { LoadingIndicatorLogo } from '@advisorycloud/react-ui-components';
import { getAdminUsers } from '../../services/admin_services';

const styles = {
  tableCellHeader: {
    fontWeight: '800',
    fontSize: '18px',
  },
  tableCellHeaderSort: {
    fontWeight: '800',
    fontSize: '18px',
    cursor: 'pointer',
  },
};

const AdminsManagment = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortAccountStatus, setSortAccountStatus] = useState(null); // [1, -1, null] = [asc, desc, none]
  const [sortAccountType, setSortAccountType] = useState(null); // [1, -1, null] = [asc, desc, none]
  const history = useHistory();

  function setSort(setFunction = () => {}) {
    setFunction((prev) => {
      if (prev === null) return 1;
      if (prev === 1) return -1;
      if (prev === -1) return null;
    });
  }

  function getIcon(sortValue) {
    if (sortValue === 1) return <ArrowDropUp />;
    if (sortValue === -1) return <ArrowDropDown />;
    return <Sort />;
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getAdminUsers();
        const result = data.getAdminUsers;
        setData(result);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <Box width="100%" p="12px">
      <Box width="100%" display="flex" justifyContent="space-between" mb="8px">
        <Typography variant="h5" gutterBottom>
          Admins Managment
        </Typography>
        <Button
          variant="contained"
          sx={{ borderRadius: '10px' }}
          color="primary"
          onClick={() => {
            history.push('/admin-managment');
          }}
        >
          Add Admin
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={styles.tableCellHeader}>
                <TextField
                  value={searchTerm}
                  onChange={(event) => setSearchTerm(event.target.value)}
                  placeholder="Search by name"
                />
              </TableCell>
              <TableCell
                sx={styles.tableCellHeaderSort}
                onClick={() => setSort(setSortAccountType)}
              >
                <Box display="flex" alignItems="center">
                  Account Type
                  {getIcon(sortAccountType)}
                </Box>
              </TableCell>
              <TableCell
                sx={styles.tableCellHeaderSort}
                onClick={() => setSort(setSortAccountStatus)}
              >
                <Box display="flex" alignItems="center">
                  Account Status
                  {getIcon(sortAccountStatus)}
                </Box>
              </TableCell>
              <TableCell sx={styles.tableCellHeader}>Email</TableCell>
              <TableCell sx={styles.tableCellHeader}>Company Name</TableCell>
              <TableCell sx={styles.tableCellHeader}>Last Login</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .filter(
                (row) =>
                  row.givenName
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()) ||
                  row.surName.toLowerCase().includes(searchTerm.toLowerCase())
              )
              .sort((a, b) => {
                // Sort by account status
                if (sortAccountStatus) {
                  if (a.accountStatus < b.accountStatus)
                    return -1 * sortAccountStatus;
                  if (a.accountStatus > b.accountStatus)
                    return 1 * sortAccountStatus;
                }

                // Sort by account type
                if (sortAccountType) {
                  if (a.accountType < b.accountType)
                    return -1 * sortAccountType;
                  if (a.accountType > b.accountType) return 1 * sortAccountType;
                }
                return 0; // equal values
              })
              .map((row) => (
                <TableRow
                  hover
                  sx={{ cursor: 'pointer' }}
                  key={row.id}
                  onClick={() => {
                    history.push('/admin-managment/' + row.id);
                  }}
                >
                  <TableCell>
                    {row.givenName} {row.surName}
                  </TableCell>
                  <TableCell>{row.accountType}</TableCell>
                  <TableCell
                    sx={{
                      color:
                        row.accountStatus === 'inactive'
                          ? 'red'
                          : row.accountStatus === 'paused'
                          ? 'orange'
                          : 'blue',
                    }}
                  >
                    {row.accountStatus}
                  </TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.companyName}</TableCell>
                  <TableCell>{row.previousLastLoginTimestamp}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {loading && (
        <Box width="100%" display="flex" justifyContent="center" p="16px">
          <LoadingIndicatorLogo size={100} iconFontSize={47} iconRight={49} />
        </Box>
      )}
    </Box>
  );
};

export default AdminsManagment;
