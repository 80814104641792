import React, { useState } from 'react';
import {
  Typography,
  useMediaQuery,
  Select,
  MenuItem,
  FormControl,
  Box,
} from '@mui/material';
import { Avatar, LetterAvatar } from '@advisorycloud/react-ui-components';
import { useAccount } from '../../../contexts/Account';

const useStyles = () => ({
  headerContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  avatarContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: 'fit-content',
  },
  avatarContainerCompany: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'fit-content',
  },
  avatar: {
    display: 'flex',
    justifyContent: 'flex-start',
    height: 'fit-content',
    width: 'fit-content',
  },
  textColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: 'fit-content',
  },
  textColumnEmployee: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginRight: '20%',
    textAlign: 'center',
  },
  textHeader: { display: 'flex' },
  textColumnUser: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: 'fit-content',
    marginLeft: '5px',
    flexShrink: '0px',
    minWidth: '170px',
  },
  leftContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '43%',
    marginTop: '8px',
  },
  leftContainerCompany: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: '8px',
  },
  centerContainerEmployee: {
    display: 'flex',
    justifyContent: 'space-between',
    direction: 'rtl',
    width: '20%',
  },
  centerContainerCompany: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '48%',
  },
  centerContainerAdvisor: {
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '30%',
  },
  rightContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '27%',
  },
  rightContainerEmployee: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '25%',
  },
  nameHeader: {
    fontSize: '1rem',
    fontWeight: 600,
  },
  value: {
    fontSize: '0.75rem',
    color: 'secondary.dark',
  },
  valueActive: {
    fontSize: '0.75rem',
    color: 'rgb(13, 173, 0)',
  },
  valueInactive: {
    fontSize: '0.75rem',
    color: 'secondary.error',
  },
  valuePaused: {
    fontSize: '0.75rem',
    color: 'gold',
  },
  valueNew: {
    fontSize: '0.75rem',
    color: 'primary.main',
  },
  valueDark: {
    fontWeight: 400,
  },
  valueDarkPlanCode: {
    fontWeight: 400,
    textTransform: 'uppercase',
  },
  separator: {
    marginTop: '7px',
  },
  buttonContainer: {
    outline: 'none',
    border: 'none',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    '&:disabled': {
      cursor: 'default',
      color: 'black',
    },
    margin: '0px',
    padding: '0px',
  },
  buttonContainerPlanCode: {
    outline: 'none',
    border: 'none',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    '&:disabled': {
      cursor: 'default',
      color: 'black',
    },
    padding: '0px',
    marginLeft: '-10px',
  },
  activeItem: {
    fontSize: '0.75rem',
    color: 'rgb(13, 173, 0)',
  },
  inactiveItem: {
    fontSize: '0.75rem',
    color: 'secondary.error',
    textAlign: 'center',
  },
  pausedItem: {
    fontSize: '0.75rem',
    color: 'gold',
    textAlign: 'center',
  },
  newItem: {
    fontSize: '0.75rem',
    color: 'primary.main',
    textAlign: 'center',
  },
  formControl: {
    textAlign: 'center',
  },
});

function AnswerCardHeader(props) {
  const {
    status,
    updateStatus,
    firstName,
    lastName,
    userTitle,
    companyName,
    phoneNumber,
    email,
    id,
    accountLevel,
    lastLoginDate,
    registrationDate,
    headerFormat,
    avatarImage,
    boardOwnerMail,
    boardType,
    seatsCount,
    boardTotalSeats,
    planCode,
    advisorNumberOfBoards,
    freeTrialEndsOn,
    editUserDisabled,
    editUser,
    editCompanyDisabled,
    editCompany,
    // temporal solution
    onboardingStatus,
  } = props;
  const { cognitoGroups } = useAccount();
  const [userStatus, setUserStatus] = useState(status);
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);

  function statusStyleSelector() {
    if (userStatus === 'active') {
      return classes.valueActive;
    }
    if (userStatus === 'new') {
      return classes.valueNew;
    }
    if (userStatus === 'inactive') {
      return classes.valueInactive;
    }
    if (userStatus === 'paused') {
      return classes.valuePaused;
    }
    return classes.valueActive;
  }

  function handleStatusChange(event) {
    updateStatus(event.target.value, id);
    // statusStyleSelector();
    setUserStatus(event.target.value);
  }

  const reformatDate = (originalDate) => {
    const setDate = new Date(originalDate);
    const month = setDate.getMonth() + 1;
    const day = setDate.getDate();
    const year = setDate.getFullYear();
    let formatMonth = '';
    let formatDay = '';

    if (String(day).length < 2) {
      formatDay = `0${day}`;
    } else {
      formatDay = day;
    }

    if (String(month).length < 2) {
      formatMonth = `0${month}`;
    } else {
      formatMonth = month;
    }

    return `${formatMonth}/${formatDay}/${year}`;
  };

  function renderDate(date) {
    if (date === null || date === undefined) {
      return null;
    }
    return reformatDate(date);
  }

  function leftContainerFormatSelector() {
    if (headerFormat === 'company') {
      return (
        <div style={classes.leftContainerCompany}>
          <div style={classes.avatarContainerCompany}>
            <div style={classes.avatar}>
              <Avatar image={avatarImage} size="adminUserCard" />
            </div>
            <div style={classes.textColumnUser}>
              <div style={classes.textHeaderCompany}>
                <Typography sx={classes.nameHeader} color="textPrimary">
                  {companyName}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div style={classes.leftContainer}>
        <div style={classes.avatarContainer}>
          <div style={classes.avatar}>
            {headerFormat === 'employee' ? (
              <LetterAvatar
                firstName={firstName}
                lastName={lastName}
                size="large"
              />
            ) : (
              <Avatar image={avatarImage} size="md" />
            )}
          </div>
          <div style={classes.textColumnUser}>
            <div style={classes.textHeader}>
              <Typography
                sx={classes.nameHeader}
                color="textPrimary"
                display="inline"
              >
                {firstName}
              </Typography>
              <Box ml={0.5}>
                <Typography
                  sx={classes.nameHeader}
                  color="textPrimary"
                  display="inline"
                >
                  {lastName}
                </Typography>
              </Box>
            </div>
            <div>
              <Typography sx={classes.value}>{userTitle}</Typography>
            </div>
            <div>
              <Typography sx={classes.value}>{companyName}</Typography>
            </div>
          </div>
        </div>
        <div style={{ ...classes.textColumn, marginLeft: '5%' }}>
          <div>
            <Typography
              variant="caption"
              sx={classes.valueDark}
              color="textPrimary"
            >
              {phoneNumber}
            </Typography>
          </div>
          <div>
            <Typography
              variant="caption"
              sx={classes.valueDark}
              color="textPrimary"
            >
              {email}
            </Typography>
          </div>
        </div>
      </div>
    );
  }

  function centerFormatSelector() {
    if (headerFormat === 'employee') {
      return (
        <div style={classes.centerContainerEmployee}>
          <div style={classes.textColumnEmployee}>
            <Typography variant="caption" sx={classes.valueDark}>
              Account Level
            </Typography>
            <div style={classes.separator}>
              <Typography variant="caption">{accountLevel}</Typography>
            </div>
          </div>
        </div>
      );
    }
    if (headerFormat === 'company') {
      return (
        <div style={classes.centerContainerCompany}>
          <div style={classes.textColumn}>
            <button
              type="button"
              style={classes.buttonContainer}
              onClick={() => {
                editCompany(true);
              }}
              disabled={editCompanyDisabled}
            >
              <Typography variant="caption" sx={classes.valueDark}>
                Board Owner
              </Typography>
              <div style={classes.separator}>
                <Typography variant="caption" sx={classes.valueDark}>
                  {boardOwnerMail}
                </Typography>
              </div>
            </button>
          </div>
          <div style={classes.textColumn}>
            <button
              type="button"
              style={classes.buttonContainer}
              onClick={() => {
                editCompany(true);
              }}
              disabled={editCompanyDisabled}
            >
              <Typography variant="caption" sx={classes.valueDark}>
                Board
              </Typography>
              <div style={classes.separator}>
                <Typography variant="caption">{boardType}</Typography>
              </div>
            </button>
          </div>

          {/* temporal from here */}
          {cognitoGroups.includes('super_admin') ? (
            <div style={classes.textColumn}>
              <button
                type="button"
                style={classes.buttonContainer}
                onClick={() => {
                  editCompany(true);
                }}
                disabled={editCompanyDisabled}
              >
                <Typography variant="caption" sx={classes.valueDark}>
                  Onboarding Status
                </Typography>
                <div style={classes.separator}>
                  <Typography variant="caption">{onboardingStatus}</Typography>
                </div>
              </button>
            </div>
          ) : null}

          {/* to here */}

          <div style={classes.textColumn}>
            <Typography variant="caption" sx={classes.valueDark}>
              Free Trial
            </Typography>
            <div style={classes.separator}>
              <Typography
                variant="caption"
                sx={freeTrialEndsOn ? classes.valueActive : classes.valueDark}
              >
                {new Date(freeTrialEndsOn) > new Date() ? `YES` : `NO`}
              </Typography>
            </div>
          </div>

          <div style={classes.textColumn}>
            <button
              type="button"
              style={classes.buttonContainer}
              onClick={() => {
                editCompany(true);
              }}
              disabled={editCompanyDisabled}
            >
              <Typography variant="caption" sx={classes.valueDark}>
                Seats
              </Typography>
              <div style={classes.separator}>
                <div>
                  <Typography variant="caption" sx={classes.valueDark}>
                    {seatsCount}
                  </Typography>
                  <Typography variant="caption">
                    {`${' '}of${' '}${boardTotalSeats}`}
                  </Typography>
                </div>
              </div>
            </button>
          </div>
        </div>
      );
    }
    if (headerFormat === 'advisor') {
      return (
        <div style={classes.centerContainerAdvisor}>
          <div style={classes.textColumn}>
            <Typography variant="caption" sx={classes.value}>
              Number of Boards
            </Typography>
            <div style={classes.separator}>
              <Typography variant="caption" sx={classes.value}>
                {advisorNumberOfBoards}
              </Typography>
            </div>
          </div>
          <div style={classes.textColumn}>
            <Typography variant="caption" sx={classes.valueDark}>
              Free Trial
            </Typography>
            <div style={classes.separator}>
              <Typography
                variant="caption"
                sx={freeTrialEndsOn ? classes.valueActive : classes.valueDark}
              >
                {new Date(freeTrialEndsOn) > new Date() ? `YES` : `NO`}
              </Typography>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }

  function rightFormatSelector() {
    return (
      <div
        style={
          headerFormat === 'employee'
            ? classes.rightContainerEmployee
            : classes.rightContainer
        }
      >
        <>
          {headerFormat === 'employee' ? (
            <div style={classes.textColumn}>
              <Typography sx={classes.value}>Last Login Date</Typography>
              <div style={classes.separator}>
                <Typography sx={classes.value}>
                  {renderDate(lastLoginDate)}
                </Typography>
              </div>
            </div>
          ) : (
            <div style={classes.textColumn}>
              <button
                type="button"
                style={classes.buttonContainerPlanCode}
                onClick={() => {
                  editCompany(true);
                }}
                disabled={editCompanyDisabled}
              >
                <Typography variant="caption" sx={classes.valueDark}>
                  Plancode
                </Typography>
                <div style={classes.separator}>
                  <Typography variant="caption" sx={classes.valueDarkPlanCode}>
                    {planCode}
                  </Typography>
                </div>
              </button>
            </div>
          )}

          <div style={classes.textColumn}>
            <Typography sx={classes.value}>Registration</Typography>
            <div style={classes.separator}>
              <Typography sx={classes.value}>
                {renderDate(registrationDate)}
              </Typography>
            </div>
          </div>
          <div style={classes.textColumn}>
            <Typography variant="caption" sx={classes.valueDark}>
              Status
            </Typography>
            <div style={classes.separator}>
              <FormControl sx={classes.formControl}>
                <Select
                  sx={{
                    ...statusStyleSelector(),
                    padding: '2.5px 14px',
                    input: {
                      padding: '2.5px 14px',
                    },
                    fieldset: { border: 'none' },
                  }}
                  style={{ padding: '2.5px 14px' }}
                  inputProps={{ style: { padding: '2.5px 14px' } }}
                  labelId="status-label"
                  id="status"
                  value={userStatus}
                  onChange={handleStatusChange}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    // getContentAnchorEl: null,
                  }}
                  disableUnderline
                >
                  <MenuItem sx={classes.newItem} value="new">
                    New
                  </MenuItem>
                  <MenuItem sx={classes.activeItem} value="active">
                    Active
                  </MenuItem>
                  <MenuItem sx={classes.pausedItem} value="paused">
                    Paused
                  </MenuItem>
                  <MenuItem sx={classes.inactiveItem} value="inactive">
                    Inactive
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        </>
      </div>
    );
  }

  return (
    <div style={classes.headerContainer}>
      <button
        type="button"
        style={classes.buttonContainer}
        onClick={() => editUser(true)}
        disabled={editUserDisabled}
      >
        {leftContainerFormatSelector()}
      </button>

      <>{centerFormatSelector()}</>

      <>{rightFormatSelector()}</>
    </div>
  );
}

export default AnswerCardHeader;
